import Immutable from 'immutable';
import React from 'react';
import {getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';
import { EditorState, AtomicBlockUtils } from 'draft-js';

import {Media, SepBlock} from './CustomBlocks';

const {hasCommandModifier} = KeyBindingUtil;

export const myKeyBindingFn = (e: SyntheticKeyboardEvent): string | null => {

    if ((e.keyCode === 83) && hasCommandModifier(e)) {
      console.log('keyBinding: saving now')
      return 'myeditor-save';
    } else if ((e.keyCode === 187) && hasCommandModifier(e)) {
      console.log('keyBinding: splitting')
      return 'split-card';
    } else
    if ((e.keyCode === 77) && hasCommandModifier(e)) {
      console.log('keyBinding: merge')
      return 'merge-card'
    } else if (hasCommandModifier(e) && e.key === 'h') {
        return 'highlight';
      }
      else if (hasCommandModifier(e) && e.key === 'g') {
        return 'add-image';
      }
      else if(e.keyCode === 8) {
        console.log('backspace')
        return 'backspace'
      }
    return getDefaultKeyBinding(e);
  }



//
// export const addImage =  (editorState, url, extraData) => {
//   const urlType = 'IMAGE';
//   const contentState = editorState.getCurrentContent();
//   const contentStateWithEntity = contentState.createEntity(
//     urlType,
//     'IMMUTABLE',
//     { ...extraData, src: url }
//   );
//   const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
//   const newEditorState = AtomicBlockUtils.insertAtomicBlock(
//     editorState,
//     entityKey,
//     ' '
//   );
//   return EditorState.forceSelection(
//     newEditorState,
//     newEditorState.getCurrentContent().getSelectionAfter()
//   );
// };

export const myBlockStyleFn = (contentBlock) => {
  const type = contentBlock.getType();
  if (type === "unstyled") {
    return "unstyled"
  }
  else if (type==="list-order"){
    return "list"
  }
  else if (type === "sepBlock") {
    return "lastBlock"
  }
  else if (type === "focus") {
    return "focusedBlock"
  }
  else if (type === "header-one") {
    return "headerOne"
  }
};


export const blockRendererFn = (block, rest) => {
  if (block.getType() === "atomic") {
   return {
    component: Media,
    editable: false
   };
  }
  if (block.getType() === "sepBlock"){
    return {
      component: SepBlock,
      editable: false
    };
  }
  return undefined;
}


let fontSizeStyles = [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48,].map(item => ({["FONT_SIZE_"+item]:{ fontSize: item+'px'} }))

fontSizeStyles = Object.assign(...fontSizeStyles)

 let fontFamily  = [
        "Arial",
        "Georgia",
        "Impact",
        "Tahoma",
        "TimesNewRoman",
        "Verdana"
      ]
    
export const customStyleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
HIGHLIGHT: {
  background: 'yellow',
  padding: '0 .3em',
  color: '#aaa',
},
STRIKETHROUGH: {
    textDecoration: 'line-through',
  },  
  ...fontSizeStyles,
FONT_ARIAL: {
  fontFamily: 'Arial'
},
FONT_GEORGIA: {
  fontFamily: 'Georgia',
},
FONT_VERDANA: {
  fontFamily: 'Verdana'
},
FONT_TIMES: {
  fontFamily: 'Times',
}
,FONT_TAHOMA: {
  fontFamily: 'Tahoma'
},
FONT_IMPACT: {
  fontFamily: 'Impact',
}


};
