import React , {useState, useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {appStateActions} from 'redux/actions'
import {Button, TextField, Menu, Popper, ClickAwayListener, Tooltip, IconButton, 
  FormControl, InputLabel, Select, MenuItem, FormHelperText, Chip, ButtonGroup,
FormLabel, RadioGroup, Radio, FormControlLabel, Divider } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import CheckBoxesMenu from 'components/UI/CheckBoxMenu.js'
import {useMenuHook} from 'hooks';
import {PopperForm} from 'components/UI';
import {EventEmitter} from 'components/Edit/EventEmitter.js'

import { usePopper } from 'react-popper';

const useStyles = makeStyles(()=> ({
  showMenu: {
    padding: '20px',
    zIndex: 3,
    border: '1px solid white',
    borderRadius: '2px',
    boxShadow: '2.5px 5px 15px rgba(0, 0, 0, 0.25)',
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: 'white',
    '& > *' :{
      marginBottom: '10px',
    }
  }, 
  radioLabels: {
    fontSize: '0.8em',
  }
}) )

const initSplitState =
      [ {name: 'newLine', label: 'New Line',  value: false},
       {name: 'hashStart', label: '# Starting Char', value: false},
       {name: 'delim', label:'Delimited Character', value: false},
       {name: 'maxLen', label: 'Max Length', }
     ]

  
export const SplitMenu = (props) => {
  const classes = useStyles()
    const dispatch = useDispatch()
    
    const [maxLen, setMaxLen] = useState(200)
    const [split, setSplitState] = useState(initSplitState)
    const [menu, handleMenuOpen, handleMenuClose] = useMenuHook()
    const [delim, setDelim] = useState('\n')
    const [customDelim, setCustomDelim] = useState(null)
    


  const handleChange = (event) => {
    switch (event.target.name) {
      case 'delim':
         setDelim(event.target.value)
        break;
      case 'customDelim':
        setCustomDelim(event.target.value)
        break;
      case 'maxLen':
        setMaxLen(Number(event.target.value))
        break;
      default:
    }
  }
  
  const handleSplitClick = (event) => {
    props.tbCallback('splitCard')
    event.preventDefault()
  }

  const handleSubmit = (event) => {
    debugger
    let delimiter = delim === "other" ? customDelim : delim

    // dispatch(appStateActions.splitOptions({delimiter, maxLen}))
    handleMenuClose();
    props.tbCallback('runSplit',{delim, customDelim,  maxLen})
    event.preventDefault();
  }
  const delimItems = [{text: 'NewLine', value: '\n'}, {text: 'semicolon', value:';'}]
 
  return(
    <div>
 <ButtonGroup variant="outlined" color='black'> 
   <Button id="splitmenu" size="small" color='black' 
      onClick={handleSplitClick}> Split </Button>
      <Button size="small" color="black" onClick={handleMenuOpen}> <SettingsIcon/> </Button>
      </ButtonGroup>
    { menu.isOpen && <PopperForm anchorEl={menu.anchorEl}
     open={menu.isOpen}
     onClose={handleMenuClose}>
       <DelimControl classes={classes} name="delim" textName="customDelim" 
       controlName="Split Separator" handleChange={handleChange}
       delim={delim} customDelim={customDelim} />
        <Divider/>
        <TextField label="Max Words"  
          name="maxLen" value={maxLen} onChange={handleChange}/>
          <Button onClick={handleSubmit} variant="contained">             
          Split Document</Button>
        </PopperForm>
}
        </div>
 
  )
}
const DelimControl = (props) => (

  <FormControl component="fieldset">
<FormLabel component="legend" classes={{label: props.classes.radioLabels}}>{props.controlName}</FormLabel>
  <RadioGroup aria-label="separator" name={props.name} size="small"  value={props.delim} onChange={props.handleChange}>
    <FormControlLabel value='newLine' control={<Radio />} label="New Line" classes={{label: props.classes.radioLabels}} />
    <FormControlLabel value='doubleNewLine' control={<Radio />} label="Two or more new lines" classes={{label: props.classes.radioLabels}}/>
    <FormControlLabel value='semiCol' control={<Radio />} label="Semicolon" classes={{label: props.classes.radioLabels}}/>
    <FormControlLabel value='other'  control={<Radio />} label="other" classes={{label: props.classes.radioLabels}}/>
  </RadioGroup>
{props.delim==='other' &&
<TextField label="" variant="outlined" value={props.customDelim} name={props.textName}
onChange={props.handleChange}   
color="secondary" defaultValue=""/>
}
</FormControl>
)


export const FlashMenu = (props) => {
  const classes = useStyles()
    const dispatch = useDispatch()
    const [state, setState] = useState({})
    const [split, setSplitState] = useState(initSplitState)
    const [menu, handleMenuOpen, handleMenuClose] = useMenuHook()

    const [delim, setDelim] = useState('\n')
    const [customDelim, setCustomDelim] = useState(null)

    const [delimAns, setDelimAns] = useState('\n')
    const [customDelimAns, setCustomDelimAns] = useState(null)
    


  const handleChange = (event) => {
    switch (event.target.name) {
      case 'delimAns':
         setDelimAns(event.target.value)
        break;
      case 'customDelimAns':
        setCustomDelimAns(event.target.value)
        break;
      case 'delim':
         setDelim(event.target.value)
        break;
      case 'customDelim':
        setCustomDelim(event.target.value)
        break;
      default:
    }
  }


  // const handleChange = (event) => {
  //   setState({...state, [event.target.name]: event.target.value })
  //   }

  const generateQuestions = () => {
      // let cardBlocks = JSON.parse(props.card.text)['blocks']
      // let cardText = cardBlocks.reduce((text, block) => text + block.text, '')
      // debugger;
      alert('dispatching')
  props.tbCallback('autoGen')
  }

  const handleNewFlash = (event) => {
    props.tbCallback('addFlash')
  }

  const handleSubmit = (event) => {
    // dispatch(appStateActions.splitOptions({delim, maxLen}))
    handleMenuClose();
    props.tbCallback('runFlash',{delim, customDelim, delimAns, customDelimAns})
  }

  return(
<React.Fragment>
     <ButtonGroup variant="outlined" color='blue'> 
      <Button id="splitmenu"  size="small"
      onClick={handleNewFlash}> Flash </Button>
      <Button size="small" color="blue" onClick={handleMenuOpen}> <SettingsIcon/> </Button>
      </ButtonGroup>
    { menu.isOpen && <PopperForm anchorEl={menu.anchorEl}
     open={menu.isOpen}
      onClose={handleMenuClose}>
    <DelimControl name="delim" classes={classes} textName="customDelim"
     controlName="Flash Card Separator" handleChange={handleChange}
       delim={delim} customDelim={customDelim} />
      <Divider/>
      <DelimControl name="delimAns" classes={classes} textName="customDelimAns"
       controlName="Flash Question-Answer Separator" handleChange={handleChange}
       delim={delimAns} customDelim={customDelimAns} />
    <Divider/>
      <Button onClick={handleSubmit} variant="contained"> Create Flash Cards</Button>
    </PopperForm>
    }
    <Divider/>
    <Button color="primary" size="small" onClick={generateQuestions} variant="outlined"> AutoQ</Button>
    </React.Fragment>
  )
}

// {/* <TextField label="Question Splits Delimiter" variant="outlined"
// name="maxLen" value={state.newDelim} onChange={handleChange}/>
// <TextField label="Question: Answer Delimiter"
// value={state.answerDelim} name="delim"
// onChange={handleChange} variant="outlined"
// color="secondary" defaultValue=""/> */}
