import React from 'react';

export const SepBlock = () => (<div></div>)

export const Image = props => {
 if (!!props.src) {
  return <img alt='not found' src={props.src} height='200px' width='100%'/>;
 }
 return null;
};
export const Media = props => {
let media, src;
 if (props.block.getEntityAt(0)) {
   const entity = props.contentState.getEntity(props.block.getEntityAt(0));
   const { src } = entity.getData();
   const type = entity.getType();
   if (type === "image") {
    media = <Image src={src} />;
   }
 }
 else
 {
//   debugger
   media = <Image src='' />
 }

 return media;
};
