import React, {useState, useReducer} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import{MenuItem, Select, Input,IconButton, Button, FormControl, Divider } from '@material-ui/core';
import clsx from 'clsx';
import classes from './richButton.module.css'


export const StyleButton = props => {
  
  const onToggle = (event) => {
    event.preventDefault();
    props.onToggle(props.style)
  }
//  const className = props.active? classes.ButtonActive: classes.Button
  const className = clsx({
      [classes.Button]: true,
      [classes.ButtonActive] :props.active})
  return (
    <button className={className}
      onMouseDown={onToggle}
      active={props.active}
      children={props.children}
    />
  )
}
