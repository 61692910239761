import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {makeStyles} from '@material-ui/core/styles'
import { AppBar, Box, Tabs, Tab, CheckBox, Modal, FormControlLabel } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Preferences } from './Preferences';
import {authActions} from 'redux/actions';

export const profileStyle = () => ({
    paper: {
        position: 'fixed',
        top: '50%',
        left: '51%',
        transform: 'translate(-51%, -51%)',
        
        // marginTop: theme.spacing(8),
        width: '800px',
        height: '600px',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '25px',
        border: '1px solid black'

      },
    container: {
        backgroundColor: 'blue',
        height: '400px',
        width: '600px',
    }
})

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
              {children}
          </Box>
        )}
      </div>
    );
  }

const useStyles = makeStyles(profileStyle)

export const Profile = (props) => {

  const profile = useSelector(state => state.auth.profile)
  const dispatch = useDispatch()

    const classes = useStyles()
    const {anchorEl, open} = props
    // const [anchorEl, setAnchorEl] = React.useState(null);
    
    const [tabValue, setTab] = useState(1)
  
  const updateProfile = (profile) => {
    dispatch(authActions.updateProfie(profile))
  }

    const handleClose = () => {
    //   setAnchorEl(null);
    };
    // const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  

    const handleTabChange = (event, newValue) => {
        setTab(newValue)
    }
    
        //   <AppBar position="static">

    return(
        <Modal open={open} onClose={props.onClose}>
            <div className={classes.paper}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="My Account"  />
          <Tab label="Preferences"  />
          <Tab label="Favorites"  />
        </Tabs>
      <TabPanel value={tabValue} index={1}>
          <Preferences profile={profile} updateProfile={updateProfile}/>
        </TabPanel>
        </div>

        </Modal>
    )
}

      {/* </AppBar> */}


{/* <Popover
id={id}
open={open}
anchorEl={anchorEl}
onClose={props.handleClose}
anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'center',
}}
transformOrigin={{
    vertical: 'top',
    horizontal: 'center',
}}
>
<Typography className={classes.typography}>The content of the Popover.</Typography>
</Popover> */}
