import React, {useState, useReducer} from 'react';
import clsx from 'clsx';
import {DropDown} from 'components/UI/DropDown';
import {INLINE_STYLES, BLOCK_TYPES, DROPDOWN_TYPES} from './Controls'

import{MenuItem, Select, Input, FormControl, Divider } from '@material-ui/core';
import {StyleButton} from './ToolbarButton';
import {editorStyleChanges} from './editorStyleChanges';

export const RichToolbar = (props) => {
const {editorState, setEditorState} = props
      //  const {editorState} = props;
       const selection = editorState.getSelection();
       const blockType = editorState
         .getCurrentContent()
         .getBlockForKey(selection.getStartKey())
         .getType();

  const currentStyle = editorState?.getCurrentInlineStyle();


const onToggle = (action) =>{
  editorStyleChanges({editorState, setEditorState, action})
}
  return(
<React.Fragment>
    <FontControls {...props} onToggle={onToggle} 
    currentBlockType={blockType} currentStyle={currentStyle}/>
    <Divider orientation="vertical" flexItem />
    <InlineStyleControls {...props} onToggle={onToggle}
    currentBlockType={blockType} currentStyle={currentStyle}/>
    <Divider orientation="vertical" flexItem />
    <BlockStyleControls {...props} onToggle={onToggle} 
    currentBlockType={blockType} currentStyle={currentStyle}/>
    <Divider orientation="vertical" flexItem />
    </React.Fragment>
  )

}
const getRichButton = (type, active, onToggle) => (
  <StyleButton
    key={type.label}
    active={active}
    label={type.label}
    icon={type.icon}
    onToggle={onToggle}
    style={type.style}
  >  <img alt={type.style} height="80%" width="80%"
       src={type.icon}/>
</StyleButton>

)

export const InlineStyleControls = (props) => {

    const onToggle = (style) =>{
      props.onToggle({type: 'TOGGLE_INLINE_STYLE', payload: {inlineStyle: style}})
    }
     return (
         INLINE_STYLES.map((type) => {
            const active=props.currentStyle?.has(type.style)
           return getRichButton(type, active, onToggle) }
         )
     );
}

export const BlockStyleControls = (props) => {

      const onToggle = style => {
        if (style === 'indent') {
          //props.adjustDepth(1);
          props.onToggle({type: 'INDENT', payload: {adjustment: 1}})
        } else if (style==='outdent'){
          //props.adjustDepth(-1);
          props.onToggle({type: 'OUTDENT', payload: {adjustment: -1}})
        }
        else {
          props.onToggle({type:'TOGGLE_BLOCK_TYPE', payload: {blockType: style}})
          //props.toggleBlockType(style)
        }
      }
       return (
         <React.Fragment>
             {BLOCK_TYPES.list.map((type) => {
            const active=type.style===props.currentBlockType
            return getRichButton(type, active, onToggle) }
           )}
    <Divider orientation="vertical"/>
           {BLOCK_TYPES.align.map((type) =>{
            const active=type.style===props.currentBlockType
            return getRichButton(type, active, onToggle) }
           )}
         </React.Fragment>
     );
     };

export const FontControls = props => {
  const fontSizes = DROPDOWN_TYPES.fontSize.options
   const fontSizeOptions = fontSizes.map(option => ({label: option, style: "FONT_SIZE_"+option}))
   const fontFamily =  DROPDOWN_TYPES.fontFamily.options.map(option => ({label: option, style: "FONT_"+option.toUpperCase()}))

const toggleType = (style) => {
  props.onToggle({type:'TOGGLE_BLOCK_TYPE', payload: {blockType: style}})
}
const toggleFontSize = (style) => {
  props.onToggle({type:'TOGGLE_INLINE_STYLE', payload: {inlineStyle: style}})
}
const toggleFontFamily = (style) => {
  props.onToggle({type:'TOGGLE_INLINE_STYLE', payload: {inlineStyle: style}})
}

// debugger

let defaultHeading = DROPDOWN_TYPES.heading.find(heading => heading.style===props.currentBlockType)
let defaultFont = fontSizeOptions.find(font => props.currentStyle.has(font.style))?.style
if (!defaultFont) defaultFont = 'FONT_SIZE_12'

let defaultFamily = fontFamily.find(font => props.currentStyle.has(font.style))?.style

return (
  <React.Fragment>
    <Divider orientation="vertical"/>
    <DropDown selectionHandler={toggleType} options={DROPDOWN_TYPES.heading} width={80}
    defaultValue={defaultHeading?.style}/>
    <Divider orientation="vertical"/>
    <DropDown options={fontSizeOptions} selectionHandler={toggleFontSize} width={40}
    defaultValue={defaultFont}></DropDown>
    <Divider orientation="vertical"/>
    <DropDown options={fontFamily} selectionHandler={toggleFontFamily} 
    defaultValue={defaultFamily} width={100}></DropDown>
    <Divider orientation="vertical"/>
  </React.Fragment>
)

}
