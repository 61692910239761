import React from 'react';
import {makeStyles} from '@material-ui/core/styles'
import  {IconButton} from 'assets/jss/material.js'

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '1.2 rem',
        border: '1px blue solid',
        margin: '1em',
        backgroundColor: theme.palette.secondary.light,
        color: 'black'
      },
      iconLabel: {
        fontSize: '1.2rem',
  
      }
}))

const IButton = (props) => {
    const classes = useStyles()
    return(
    <IconButton onClick={props.onClick} classes={{root: classes.root, label: classes.iconLabel }}>
        {props.children}
    </IconButton>
    )
    }

export default IButton;