
import * as actionType from '../actionTypes'

let deck={}
let cards = {}
export const deckEditor = (state=[], action) => {
  switch(action.type){
    case actionType.UPDATE_EDITOR:
      console.log('update Editor', action)
      const activeCard = action.meta.blockDict[action.meta.activeBlockKey]
      deck = {cardDict: action.meta.cardDict, cardSeq: action.meta.cardSeq,
        blockDict: action.meta.blockDict,
         activeBlockKey: action.meta.activeBlockKey,
         activeCard: action.meta.activeCard, editorState: action.editorState,}
      return {...state, [action.deckId]: deck}
    default:
      return state;
  }
}

export const editorCards = (state=[], action) => {
  switch(action.type){
    case actionType.UPDATE_EDITOR_CARD:
       cards = state[action.deckId]
      return {...state,
        [action.deckId]: {...deck, [action.cardId]: action.cardsBlock}}
    case actionType.ADD_EDITOR_CARD:
        cards = state[action.deckId]
      return {...state, [action.deckId]: {...cards, [action.cardId]: action.cardBlocks}}
    default:
      return state
  }

}
