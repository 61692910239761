
 import React from 'react';
 import { makeStyles } from '@material-ui/core/styles';
 import Typography from '@material-ui/core/Typography';
 import {Button} from '@material-ui/core';
 import StarIcon from '@material-ui/icons/Star';
 import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

 const useStyles = makeStyles({

    navRoot: {
        height: '50px',
        display: 'flex',
        backgroundColor: 'snow',
        boxShadow: '0px 1px 1px 1px lightgray',
        width: '100%',
        '& > *' : {
          height: '100%',
          margin: '0px 1px 0px 1px',
        },
        marginBottom: '2px',
        justifyContent: 'flex-end'
      },
      button: {
          minWidth: '100px',
          display: 'flex',
          justifyContent: 'space-around',
      }
     });

 
 export default function HubNav(props) {
   const classes = useStyles();


   return (
       <div className={classes.navRoot}>
        {props.detail && <React.Fragment>
        <Button variant="outlined" className={classes.button} startIcon={<StarIcon/>}> {props.deck?.stars} </Button>  
        <Button variant="outlined" onClick={()=> props.navCallback('download')} startIcon={<CloudDownloadIcon/>}> Save to My LIbrary </Button>      }
    </React.Fragment>
        }
       </div>
   )
 }
