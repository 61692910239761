import React from 'react';
import {EditorState, AtomicBlockUtils} from 'draft-js';

export const addImage = (props) =>{
  const {editorState, urlValue} = props;
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
   "image",
   "IMMUTABLE",
   { src: urlValue }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  let  newEditorState = EditorState.set(
   editorState,
   { currentContent: contentStateWithEntity },
   "create-entity"
  );

  newEditorState = AtomicBlockUtils.insertAtomicBlock(
   newEditorState,
   entityKey,
   " "
  )
  return EditorState.forceSelection(
    newEditorState,
    newEditorState.getCurrentContent().getSelectionAfter()
  );
}
