import React, {useState, useEffect, useRef, Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

import {Input} from '@material-ui/core'

import {appStateActions} from 'redux/actions'
const useStyles = makeStyles( (theme) => ({
  alignContent: {
    alignSelf: 'center',
    backgroundColor: 'teal'
  },
  selected: {
  backgroundColor: 'yellow',
  color: 'blue',
},
list: {
  overflowY: 'auto',
  height: '100%',
  backgroundColor: 'lightgray',
},
cardTitle: {
  height: '25px',
  borderBottom: '1px solid lightblue',
  background: 'white'
},
titleText: {
  fontSize: '10px',
}
}
));

const ListItems = React.memo(({ refs, items, onClick, classes, activeCard }) =>
  items
    .filter(({ hidden }) => !hidden)
    .map(({ key, id, title }, i) => {
      let className = key===activeCard? 'selected' : 'cardTitle'
      console.log('rendering list items again')
      return (
      <ListItem ref={refs[key]}
        button
        key={key}  className={classes[className]}
        onClick={onClick(key)}
      >
        <div className={classes.titleText}>
          {title.substring(0,40)+'...'+key.substring(0,3)}</div>
      </ListItem>
    )
  }));
export default function SideDrawer(props) {
  const {deck, items} = props

  //console.log('sidedrawer', props)
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch()
  // const [content, setContent] = useState(props.activeCard)
  // const [items] = useState(props.items)
  let activeCard = useSelector(state => state.appState.activeCard[deck?.id])
//useEffect(() => setInput({...state, deckName: props.deck.name}))
    let refs = {}
 //  refs = items?.reduce((acc, item) => {
 //   acc[item.key] = React.createRef();
 //   return acc
 // }, {})

  useEffect(()=> {
    // if (refs[activeCard])  refs[activeCard].current.scrollIntoView({
    //   block:'nearest', behavior:'smooth'})
        }, [activeCard])






  const onClick = cardKey => () => {
    setOpen(false);
    //setContent(cardId);
    // props.selectCard(cardKey)

     dispatch(appStateActions.updateActiveCard(deck.id, cardKey))
  }
      return (
          <div className={classes.list}>
            {props.items && <ListItems refs={refs}
              items={props.items} classes={classes}
            onClick={onClick} activeCard={activeCard}/>}
          </div>

  );
};
