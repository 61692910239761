import React, { useState, useEffect, Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {NavLink, history } from 'react-router-dom';
import { connect } from 'react-redux';
import {Grid, IconButton, Button, AppBar,  Menu} from '@material-ui/core'

import {onRenderCallback} from '../../utils/performance.js'

import { cardActions, deckActions, editorActions, appStateActions} from '../../redux/actions';
import {deleteFromReviews, addToReviews} from 'components/Review/reviewUtil';
import {DeckContentUI} from './DeckContentUI'
import notecard from 'components/UI/NoteCard.js';

export const sortCards = (cards, seq) => {
  let sorted=[], dictKeys = {},  newSeq=[];
  let card, id, key;
  if (cards?.length>0) {
    cards.forEach(card=> {
      id = card.id
      key = card.key
      dictKeys[key] = card
      if (!seq.includes(key)) {
        seq.push(key)
      }
    })
    seq.forEach(key => {
      card = dictKeys[key]
      if (card){
        sorted.push(card)
        newSeq.push(card.key)
      }
    })
  }
  return [sorted, newSeq] //// TODO: report discrepancy
}

// const getDateString = (date) => date.getFullYear()+date.getMonth()+date.getDate()


export default function DeckContent(props) {
    const dispatch = useDispatch()
    const deckId = Number(props.deckId)
    let  deck = useSelector(state => state.myDecks.filter(deck => deck.id === deckId)[0])
    let viewMode = useSelector(state => state.appState.viewMode)[deckId]
    viewMode = props.viewMode || viewMode
    // console.log('viewMode is ', viewMode)
    let noteCards = useSelector(state => state.noteCards[deckId])
    const [deckCards, setCards] = useState(noteCards)
    
    const [cardSeq, setSeq] = useState([])
    // const cardSeq = deck?.cardSeq || []
    const [newContent, setNewContent] = useState(null)
    // useEffect( 
    //   ()=> {
    //     let cardOrder = []
    //     try{  cardOrder =  JSON.parse(deck.card_seq) }
    //     catch(e) { cardOrder = []  }
    //     const [newDeckCards, newSeq] = sortCards(noteCards, cardOrder)
    //     setCards(newDeckCards);
    //     setSeq(newSeq);
    //   }, [noteCards]
    // )

  useEffect(
    () => {
      console.log(props.location)
      // debugger;
      setNewContent(props.location.state?.newContent)
    },[props.location.state]
  )

    useEffect(()=>{
      dispatch(appStateActions.updateActiveDeck(deck?.name))
      // console.log('location state', props.location.initialData)
      return (()=> dispatch(appStateActions.updateActiveDeck(null)))
    }, 
      [deckId])

    useEffect(()=> {dispatch(cardActions.fetchNoteCards(deckId))} , [deckId])
    if (!deck){
      props.history.push('/')
      return null;
    }
  const updateDeck = (deck) => {
   dispatch(deckActions.updateDeck(deck))
  }

  const updateDeckCards = (dict, newSeq, outline) => {
    console.log('updateDeckCards - before updating', dict)
    let seqModified = false
    let next_review = deck.next_review
    if (dict.addCards.length > 0) {
      dispatch(cardActions.addNoteCard(deckId, dict.addCards))
      seqModified = true
      next_review = addToReviews(dict.addCards, next_review)
    }
    if (dict.updateCards.length > 0 ) {
        dispatch(cardActions.updateNoteCard(deckId, dict.updateCards))
    }
    if (dict.deleteCards.length > 0 ) {
      debugger;
      dispatch(cardActions.deleteNoteCard(deckId, dict.deleteCards))
      seqModified = true
      next_review = deleteFromReviews(dict.deleteKeys, next_review)
    }
    if (seqModified) {
      // JSON.stringify(newSeq)
      dispatch(deckActions.updateDeck({id: deckId, card_seq: newSeq, 
        next_review: next_review, outline: outline}))
    }
  }

const  updateNote = (card) => {
  if (deckCards.includes(card.id)) {
        dispatch(cardActions.updateNoteCard(deckId, [card]))
        } else {
      dispatch(cardActions.addNoteCard(deckId, [card]))
    }
 }
 console.log('reddering from deckContent', deckCards)
      return  (
                    <DeckContentUI
                      viewMode={viewMode}
                      deck={deck}
                      newContent={newContent}
                      cards={noteCards}
                      updateDeckCards = {updateDeckCards}
                      updateNote={updateNote}
                      />
                  );
}
