import React from 'react';
import {EditorState, ContentState, SelectionState,
  ContentBlock, Modifier, RichUtils, AtomicBlockUtils,
  convertToRaw, convertFromRaw} from 'draft-js';


export const blockEventsOnly = (oldKeys, newKeys, contentState,  ) => {
  const dispatchArr = []
  debugger;
  // let rawContent  = convertToRaw(contentState)
  // console.log('on changing block raw contebt', rawContent)
  
   oldKeys.forEach(key => {
     if (!newKeys.includes(key)){
       dispatchArr.push({type:'remove-block', payload:{blockKey: key}})
     }
   })
   newKeys.forEach(key => {
     if (!oldKeys.includes(key)){
       let prevKey = contentState.getKeyBefore(key)
       dispatchArr.push({type:'add-block-after', payload:{blockKey: prevKey, newKey: key}})
     }
   })
   return dispatchArr
 }

 export const getHeaderBlocks = (contentState) => {
  let headerBlocks = contentState.getBlockMap().map(block => ({key: block.getKey(), type: block.getType()}))
  .filter(block => block.type.startsWith('heading'))
 }
 export const handleChange = (editorState, prevEditorState, cardState, ) => {
   const oldContentState = prevEditorState.getCurrentContent()
   let newContent = editorState.getCurrentContent()
   let newSelection = editorState.getSelection()
   const selKey = newSelection.getStartKey()

   let dispatchArr = []
   
   if (editorState.getLastChangeType()==='change-block-type' &&
           prevEditorState.getLastChangeType() !== 'change-block-type') {
          let headerBlocks = getHeaderBlocks(newContent)
          dispatchArr.push({type: 'head-block', payload:{headerBlocks}})
          console.log('headerBlocks')
   }

   if (editorState.getLastChangeType()==='insert-fragment'){
    //  alert('block-changed')
     let headerBlocks = getHeaderBlocks(newContent)
     console.log('headerBlocks', headerBlocks)
   }
   const [...oldKeys] = oldContentState.getBlockMap().keys()
   const [...newKeys] = newContent.getBlockMap().keys()

   if (oldKeys.join(',') !== newKeys.join(',') ) {
     dispatchArr = blockEventsOnly(oldKeys, newKeys,  newContent)
   }

   if (!cardState.blockUpdated.has(selKey)) 
   {
     dispatchArr.push({type:'block-updated', payload:{blockKey: selKey}})   
   }
   console.log('from change', selKey)



   const beforeChangeKey = prevEditorState.getSelection().getStartKey()

   let befCard = cardState.blockDict[beforeChangeKey]
   let currCard = cardState.blockDict[selKey]
   if (!currCard) currCard = befCard
   let activeCard
   if (befCard !== currCard) {
     console.log('card changed', {befCard, currCard})
     activeCard = currCard
   }

   if ((newContent !== oldContentState) && !cardState.updated.has(currCard)) {
     dispatchArr.push({type:'card-updated', payload:{cardKey: currCard, blockKey: selKey}})
   }

return {activeCard, dispatchArr}

 }

 // const handleEditorChanges = (type, editorState, cardState) => {
 //
 //   let newState;
 //   if (type=='split-card') {
 //      contentState = cardState.splitCard(contentState, blockKey , selection.getStartOffset())
 //      newState = EditorState.push(editorState, contentState, 'split-block');
 //   } else if (type=='merge-card') {
 //        contentState = cardState.mergeCard(contentState, blockKey)
 //        newState = EditorState.createWithContent(contentState);
 //   }
 //   contentState = newState.getCurrentContent()
 //   newState = EditorState.createWithContent(contentState)
 //   const newSelection = contentState.getSelectionAfter();
 //   newState = EditorState.forceSelection(newState, newSelection)
 //   return newState
 // }
