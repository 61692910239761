import React, {useEffect, useState} from 'react';

import { Card, CardActionArea,  CardHeader, CardContent, Divider} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
      backgroundColor: '#eee',
      display: 'flex',
      flexFlow: 'column',
      margin: 'auto',
      height: '100%',
      minHeight: '300px',
      minWidth: '400px',
      justifyContent: 'flex-start',
      '& > *': {
        width: '100%',
      }
    },
    header: {
      textAlign: 'center',
      backgroundColor: theme.palette.primary.dark,
      
    },
    backHeader: {
      backgroundColor: theme.palette.secondary.dark,
    },
    content: {
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '24px'
    }
       


}))


export default function FlipCard(props) {
    const classes = useStyles()
    const [front, setSide] = useState(true);

    const toggleSide = () => {
      setSide(!front);
    }
    useEffect(
      ()=> setSide(true), [props.front]
    )

    const Front = () => (<React.Fragment>
        <CardHeader className={classes.header} subheader="Front" />
        <Divider/>
        <CardContent className={classes.content} > {props.front} </CardContent> 
        </React.Fragment>
        ) 

    
    const Back = () => ( <React.Fragment>
        <CardHeader className={classes.backHeader} subheader="Back"/>
        <Divider/>
        <CardContent className={classes.content}> {props.back} </CardContent> 
        </React.Fragment>
        )

    return (
      <Card  >
      <CardActionArea className={classes.container} 
      style={{}} onClick={toggleSide}>
      {front &&  <Front/>   }
      {!front && <Back/>}
      </CardActionArea>
      </Card>
 
    )

}
