
const groupByCount  = (list, key) => list.reduce((rv, x) => ({
    ...rv,  [Math.ceil(x[key])]: (rv[Math.ceil(x[key])] || 0) + 1  }),{} );

const processPage = (text) => {
        debugger;
        let items = text.items
        items = items.map( s => ({
                ...s, length: s.str.length, 
                ty: s.transform[5], 
                tx: Math.ceil(s.transform[4]),
                transform: s.transform.map(it => Math.ceil(it))}))

    for (let i=1; i<items.length; i++) {
                    items[i]['spacing'] = items[i-1].ty - items[i].ty
                }
              
        const groupLength = key => items.reduce((rv, x) => ({
            ...rv, [Math.ceil(x[key])]: (rv[Math.ceil(x[key])] || 0) + x.length  }),{} );

    let widths = groupLength('width')
    let heights = groupLength('height')
    let tx = groupLength('tx')
    let spacing = groupLength('spacing')



    const getMode = obj => Object.keys(obj).reduce((a,b) => obj[a] > obj[b] ? a: b) 

    let modeHeight = getMode(heights)
    let modeSpacing = getMode(spacing)
    let modeWidth = getMode(widths)
    let modeTx = getMode(tx)


    console.log('widths', widths, heights, tx, spacing)
    console.log('modes', modeHeight, modeSpacing, modeWidth, modeTx)


    let blocks = []
    let blockType
    let prevBlock = items[0]
    let currentBlock = ''
    items.forEach((item, index) => {
        if (item.height > modeHeight) blockType='heading-one'
        if ((prevBlock.height===item.height) || (prevBlock.ty === item.ty)) {
            currentBlock = currentBlock+' '+item.str
        } else if (currentBlock.length < 50) {
            currentBlock = currentBlock+' '+item.str
        }
        else {
            blocks.push(currentBlock+'\n')
            currentBlock = item.str
        }
        prevBlock = item
    })



    let pageText = items.map( s =>  s.str+'-'+Math.ceil(s.height)+'-'+s.transform.toString()+'-'+'\n')
    pageText.unshift(Array.from(widths))
    return blocks.join(' ')


}

export const pdfProcess = (pdfPages) => {

    // const text = getPdfText(pdfUrl)
    debugger
    const pages = []
    const strText = pdfPages.map(text => processPage(text) )

    // const get 



    return strText


}