import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {MyEditor, EditToolbar, populateEditor} from 'components/Edit/Editor/';
import {Layout} from 'components/Edit/DeckLayout'
import { TopNav } from 'components/Nav/TopNav';
import { deckActions } from 'redux/actions';
import { Divider } from 'assets/jss/material';
import {QuestionList} from 'components/Deck/Question';
import FlipCard from 'components/UI/FlipCard';
import HubNav from './HubNav';
const ReviewCard = props =>  {
    const {card} = props
    let content = card?.text ? JSON.parse(card.text) : null
    debugger
    return ( 
        <div>
            <MyEditor readOnly editorState={populateEditor(content)} />
            <Divider/>
        </div>
      
    )
}

export default function DeckView(props) {
    const dispatch = useDispatch()
    const deckId = Number(props.deckId)
    let  deck = useSelector(state => state.hubDecks.filter(deck => deck.id === deckId)[0])

    let deckCards = useSelector(state => state.hubDeckDetails[deckId])

    let allQuestions = deckCards?.map(card => card.flashes).flat()

    debugger
    useEffect(()=> {dispatch(deckActions.fetchHubDeckDetails(deckId))} , [deckId])
    if (!deck){
      props.history.push('/')
      return null;
    }
    debugger;

    const navCallback = (type) => {
        switch (type) {
            case 'download':
                dispatch(deckActions.downloadHubDeck(deckId))
                props.history.push('/decks')
                break;
        
            default:
                break;
        }

    }

    const FlashList = (
        <React.Fragment>
            {allQuestions?.map(ques => <FlipCard front={ques.qtext} back={ques.answer}/>)}
            <Divider/>
        </React.Fragment>)

      
    return (
        <React.Fragment>
            <TopNav/>
            <HubNav detail={true} deck={deck} navCallback={navCallback} />
            <Layout
              review={true}
              toolbar={()=> {}}
              toc={() => {}}
              editorAux={FlashList}
              >
                    <React.Fragment>
            {deckCards?.map(item => <ReviewCard card={item}/>)}
        </React.Fragment>
                  </Layout>
        </React.Fragment>
              )
    

}