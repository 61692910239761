import React, { useEffect, useState } from 'react';
import {makeStyles} from '@material-ui/core/styles'
import {  Box,  Checkbox, Modal, FormControlLabel, Divider,
     Typography, Button } from '@material-ui/core';
 export const prefStyle = () => ({
        paper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'spacebetween',
            backgroundColor: 'white',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid lightgray'            
          },
          section: {
              marginBottom: '10px',
          }
    })

const PrefOptions = {
    image: ['Nature', 'Archi', 'Trees', 'Green', 'Beach', 'Sun'],
    tour: ['home', 'deckEditor'],
    interests: ['programming', 'Computer Science', 'math', 'datascience'],
    topics: ['python', 'javascript', 'django'],    
}

const UserPreferences = {
    image: ['Nature', 'Archi', 'Trees', 'Green'],
    tour: ['home', 'deckEditor'],
    interests: ['programming', 'math', 'datascience'],
    topics: ['python', 'javascript'],
}

const useStyles = makeStyles(prefStyle)

export const Preferences = (props) => {
    const classes = useStyles()
    const userProfile = props.profile
    const initialOpt = userProfile?.preferences;
    const allOpt = PrefOptions;
    const[ userOpt, setOpt] = useState(initialOpt)
    useEffect(()=> {
    }, []  )
            debugger;
    console.log('preferences', userOpt)
    const handleChange  = (event) => {
            const [part1, name] = event.target.name.split('-')
            const checked = event.target.checked
            setOpt(prevOpt => {
                let newOpt = prevOpt[part1] ? prevOpt[part1]: []
                if (checked && newOpt) {
                    if (!newOpt.includes(name)) newOpt.push(name)     
                } 
                else {
                    newOpt = newOpt.filter(item => item!==name)
                }
                return{...prevOpt, [part1]:newOpt}}
                )

    }

    const submitPreferences = (event) => {

        props.updateProfile({...userProfile, preferences: userOpt})
        event.preventDefault()

    }
    const getCheckBoxPref = (prefix, allItems, userItems) => {
        return  (allItems.map( item => (  
            <FormControlLabel key={item}
            control={<Checkbox key={item} 
            checked={userItems?.includes(item)} 
            onChange={handleChange} 
            name={prefix+'-'+item} />}
            label={item}
            />
            )))
        }
  
    const interests = getCheckBoxPref('interests',allOpt.interests, userOpt.interests)
    const topics  = getCheckBoxPref('topics', allOpt.topics, userOpt.topics)
    const imagePref = getCheckBoxPref('image', allOpt.image, userOpt.image)
    const welcomeOptions = getCheckBoxPref('tour', allOpt.tour, userOpt.tour)


       return(
           <>
           <div className={classes.paper}>
           <div className={classes.section}>
           <Typography variant="h5"> Interests: </Typography>
           {interests}
           </div>
           <Divider/>
           <div className={classes.section}>
           <Typography variant="h5"> Topics: </Typography>
           {topics}
           </div>
           <Divider/>
           <div  className={classes.section}>
           <Typography variant="h5"> Images Preferred: </Typography>
           {imagePref}
           </div>
           <Divider/>
           <div  className={classes.section}>
           <Typography variant="h5"> Product Tour: </Typography>
           {welcomeOptions}
           </div>
           <Divider/>

           </div>
           <Button variant="contained" color="primary" 
           onClick={submitPreferences}> Apply Changes</Button>
           </>
       )

}