import * as actionType from '../actionTypes'
const initialState =  [] 
let deck = {}
let newState
export const myDecks = (state=initialState, action) => {
  switch (action.type) {
    case actionType.FETCH_DECKS:
        return [...action.decks];

    case actionType.ADD_DECK:
      newState = state.concat(action.deck)
        return [...newState];
    case actionType.UPDATE_DECK:
        return state.map(deck => {
          if (deck.id === action.deck.id) {
            deck = {...deck, ...action.deck}
          }
          return deck
        })
    case actionType.DELETE_DECK:
          newState = state.filter( deck => deck.id !== action.deckId)
        return [...newState]
    case actionType.LOGOUT_SUCCESSFUL:
      return state = initialState;

    case actionType.ADD_NOTECARD:
    let cardIds = []
    action.cards.forEach(card => cardIds.push(card.id))
    return state.map(deck => {
      if (deck.id == action.deckId) {
      deck = {...deck, cards: deck.cards.concat(cardIds) }
      }
      return deck
    })


    case actionType.DELETE_NOTECARD:
    console.log(action)
    return state.map(deck => {
      if (deck.id === Number(action.deckId)) {
        deck = {...deck, cards: deck.cards.filter( cardId=> !action.cards.includes(cardId))}
      }
      return deck
    })

    default:
      return state

  }

}
