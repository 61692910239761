import React, {useState, useEffect, useCallback, Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {Editor, EditorState, convertToRaw} from 'draft-js';
// import SideDrawer from 'components/Deck/CardTitleBar';
import {QuestionList} from 'components/Deck/Question';
import {appStateActions} from 'redux/actions'
import {Layout} from 'components/Edit/DeckLayout'
import {MyEditor, EditToolbar, populateEditor, scrollToBlock, selectBlockForKey,
editorBasics, removeContentBlock,  getSelectedText, splitBlock, getPlainText} from 'components/Edit/Editor'
import RenderOutline from 'components/Deck/RenderOutline'
// import {useCardState} from './CardState'
// import {cardActions} from 'redux/actions';
import {deckActions, cardActions} from 'redux/actions';
import {addToReviews} from 'components/Review/reviewUtil'

import {getMergeCardKeys, getCardLastBlock, getContent,
   sortCards, getCardsToUpdate,
 handleChange, getCardsToSplit, getSplits, splitBlockCard,
  getCardText, getTree,
  getFlashes} from './utils';
import {reducer} from './utils/reducer';

import { CircularProgress, Typography, Divider
 } from '@material-ui/core';
import { getContentStateFromCardBlocks, getCursorCardPosition } from '../Editor/editUtils';
import { editorStyleChanges } from '../Editor/Toolbar/editorStyleChanges';

const initialState = {cardDict: {}, blockDict: {}, order:[], keyId:{},
                      updated: new Set(), added: new Set()}

const handleCardSelection = (cardKey, editorState, cardState) => {
  // let currentBlock = editorState.getSelection().getStartKey()
  let blockKeys =  cardState.cardDict[cardKey]
  // if (blockKeys?.includes(currentBlock)){
  //   return null
  // }

  const blockKey = blockKeys ? blockKeys[0]: null
  if (blockKey) {
        scrollToBlock(blockKey)
        return selectBlockForKey(editorState, blockKey)
    }
  return editorState

}
const handleCardEvents = (type, editorState, cardState, direction='next') => {
  // let {contentState, selection, blockKey} = editorBasics(editorState)
  let selection = editorState.getSelection();
  let contentState = editorState.getCurrentContent()
  let blockKey = selection.getStartKey()
  let newBlockKey
  let newSepKey
  let cState
  if (type==='merge-card') {
    debugger;
    const [fromKey, toKey] = getMergeCardKeys(cardState, blockKey, direction)
    if ((toKey) && (fromKey)) {
      cState = reducer(cardState, {type:'merge-card', payload: {toKey, fromKey}})
    }
    
    const sepKeyRemove =   getCardLastBlock(cardState, toKey)
    contentState = removeContentBlock(contentState, sepKeyRemove)
  }
  if (type==='split-card') {
    const offset = selection.getStartOffset();
    [contentState, newBlockKey, newSepKey] = splitBlockCard({contentState, blockKey, offset})
    cState = reducer(cardState, {type:'split-card', payload:{blockKey, newBlockKey, newSepKey}})

  }
  return [EditorState.createWithContent(contentState), cState, contentState]
}
const loadData = ( cardKeyBlocks, cardSeq, keyId) => {
  let cState = {...initialState}

  cState = reducer(cState, {type: 'reset'})
  cState = reducer(cState, {type: 'add-order', payload:{cardSeq}})

  Object.keys(cardKeyBlocks).forEach( cardKey=> {
    cState = reducer(cState, {type: 'add-card',
        payload:{newCardKey: cardKey, id: keyId[cardKey],
         blocks: cardKeyBlocks[cardKey]}}
       )
  } );
  return cState
}



export const  DeckEditor = (props) => {

  const dispatchR = useDispatch()
  const {cards, updateDeckCards, deck} = props
  const cardSeq = deck.card_seq || []

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [dbState, setdbState] = useState(null)
  const [cardState, setCardState] = useState(initialState)
  // const [currCard, setCard] = useState(null)
  let currCard = cards ? cards[0] : null
  let activeCard = useSelector(state => state.appState.activeCard[deck.id])
  if (activeCard) {
    currCard = cards.filter(card => card.key===activeCard)[0]
    } 
  // let splitOptions = useSelector(state => state.appState.splitOptions)
  const [isEditing, setEditing] = useState(false);
  
  const editorRef = React.useRef(null);
  const qRef = React.useRef(null);
  const [autoProgress, setAutoProgress] = useState(false)
  
  useEffect(()=>{
    let newEditorState, loadedState
    const {blocks, entityMap, cardKeyBlocks, keyId, newSeq} = getContent(cards, cardSeq, deck.outline, props.newContent)
    console.log('useeffect', {cards, blocks,cardKeyBlocks, newContent: props.newContent})

     newEditorState = populateEditor({blocks, entityMap})
    debugger;
     loadedState = loadData(cardKeyBlocks, newSeq, keyId)
    

    const newContent = newEditorState.getCurrentContent()
    const rawNewContent = convertToRaw(newContent)
    console.log('DeckEditor useEffect load data new CardState', loadedState, rawNewContent )
      // setdbState(loadedState)
      setCardState(loadedState)
      setEditorState(newEditorState)
  }, [props.cards, props.deck, props.newContent])

  useEffect( ()=>{

  })

//   useEffect(()=>{
//     console.log('DeckEditor useEffect active card', activeCard, cards)

//   //TODO watchout for state closure elements
//     if ((activeCard) && cards) {
//       // const newEd = handleCardSelection(activeCard, editorState, cardState)
//       // if (newEd) setEditorState(ed => newEd)
//       setCard(cards.filter(card => card.key===activeCard)[0])
//   }

  
//   // let card = cards.filter(card => card.key===activeCard)[0]
//   // setCard(card)
// }, [cards, activeCard])



const rawContent = convertToRaw(editorState?.getCurrentContent()).blocks.map(block => block.key+'='+block.type+'-'+block.text.substring(0,5)+'\n\n')
console.table('editorBlockKeys, current card & activeCard', rawContent ,currCard, activeCard)



function handleKeyCommand(command){

    
  if ((command === 'split-card') || (command === "merge-card"))  {
    const [newEditorState, cState, contentState] = handleCardEvents(command, editorState, cardState)
    setEditorState(newEditorState)
    setCardState(cState)
  }
   else if (command === 'myeditor-save') {
    handleSave()
  }
  else if (command === 'backspace') {
    const curPos = getCursorCardPosition(editorState, cardState)
    if (curPos === 0) {
      const [newEditorState, cState, contentState] = handleCardEvents('merge-card', editorState, cardState, 'prev')
      setEditorState(newEditorState)
      setCardState(cState)
      return 'handled' 
    }
    return 'not-handled'
  }
    else if (command === 'highlight'){
      
    const selText = getSelectedText(editorState)
    addFlash(selText)
      editorStyleChanges({setEditorState, editorState, 
        action: {type: 'HIGHLIGHT', payload: {}}})
    }
  else {
    return 'not-handled'
  }
  return 'handled'

  }


  
  const onChange = (newEditorState) => {
    const {activeCard, dispatchArr} = handleChange(newEditorState, editorState, cardState)
    setEditorState(newEditorState)
    if (activeCard)  dispatchR(appStateActions.updateActiveCard(deck.id, activeCard))

    if (dispatchArr.length > 0) {
      let cState = {...cardState}
      dispatchArr.forEach(disp => cState = reducer(cState, disp))
      setCardState(cState)
    }
    
  }
  
  const onBlur = (event) => {
    // handleSave()
    // alert('on blur now')
  }
  
  const onFocus = () => {
    // if (editorRef){
    //   console.log(editorRef)
    // }
  }

function toolbarCallback(type, data) {
  let newEditorState = editorState
  let cState = cardState
  let contentState;
  debugger;
  switch (type) {
    case 'runSplit':
       [cState, contentState] = runSplit(data)
      setCardState(cState);
       newEditorState = EditorState.createWithContent(contentState)
      setEditorState(newEditorState);
      break;
    case 'splitCard':
       [newEditorState, cState, contentState] = handleCardEvents('split-card', editorState, cardState)
      setEditorState(newEditorState)
      setCardState(cState)
      break;
    case 'runFlash':
      runFlash(data);
      break
    case 'addFlash':
      addFlash();
      break;
    case 'autoGen':
      autoGenerateQuestions()
      break;
    default:
  }
}

function autoGenerateQuestions () {
  let cardId = currCard.id
  alert('running auto gen')
  if (cardId) dispatchR(cardActions.generateCardQuestions(cardId, deck.id))
  setAutoProgress(true)

}

function addFlash(text='') {
  if (qRef.current) {
    qRef.current.addFlash(text)
  }
}
function runFlash(options) {
  const flashOptions = {...options}
  // run loop on current card to split and generate flash cards and call insert method
  const rawContent = convertToRaw(editorState.getCurrentContent())
  debugger;
  const cardText = getCardText(cardState, currCard.key, rawContent)
  // const plainText = getPlainText(cardText)
  const plainText = cardText.blocks.reduce((text, block) => text + block.text+ '\n', '')
  console.log('cardText to gist', cardText)
  const flashes  = getFlashes(plainText, flashOptions);
  flashes.forEach(flash => { flash = {...flash, card: currCard.id}
    dispatchR(cardActions.createQuestion(props.deck.id, flash))
  })
    let next_review = addToReviews(flashes, props.deck.next_review)
    dispatchR(deckActions.updateDeck({...props.deck, next_review: next_review}))

}

function runSplit(splitOpt) {
  //alert('running split now')
  debugger;
  let contentState = editorState.getCurrentContent();
  let rawContent = convertToRaw(contentState)
  const cardsToSplit = getCardsToSplit(rawContent, cardState, splitOpt)
  let cState = {...cardState}
  let splits;
  if (Object.keys(cardsToSplit).length> 0) {
    Object.keys(cardsToSplit).forEach(key => {
      splits = getSplits(contentState, cState, key, splitOpt)
       if (splits.length > 0  ) splits.forEach(split => {
         let [blockKey, offset, cum,  newCardKey] = split
         // contentState =  handleSplit(contentState, split[0], split[1], split[3])})
         // Get new contentstate with splitting block at offset provided. No split i
         const [newContentState, newBlockKey, newSepKey] = splitBlockCard({contentState, blockKey, offset})
         contentState = newContentState

         console.log('After splits, new contentstate', contentState.getBlocksAsArray())
         cState = reducer(cState, {type:'split-card', payload:{blockKey, newBlockKey, newSepKey, newCardKey}})
       })
    })
  }
  return [cState, contentState]
}

const saveOutline = (blocks, cardSeq) => {
  
  let headerBlocks = Array.from(blocks.filter(block => block.type.includes('header')))
  
  console.log('headerblocks -saving', headerBlocks)
  const outline = getTree(headerBlocks)
  console.log('outline -saving', outline)

}

const handleSave = () => {
  console.log('saving now...')
  let maxCardOptions = {maxLen:1000, delimiter: '\n'}
  let contentState = editorState.getCurrentContent();
  let rawContent = convertToRaw(contentState)

  const cardsToSplit = getCardsToSplit(rawContent, cardState, maxCardOptions)
  let cState = {...cardState};
  let newEditorState =  editorState;
  
  if (Object.keys(cardsToSplit).length > 0){
    const retSplit = runSplit(maxCardOptions) //auto split
    newEditorState = EditorState.createWithContent(retSplit[1])
    cState = retSplit[0]
  }

  
  
  const {addCards, updateCards, deleteCards, deleteKeys, cardSeq, outline} = getCardsToUpdate(newEditorState, cState);
  // let outline, careSeq = saveOutline(rawContent.blocks, cardSeq)

  if (addCards.length+updateCards.length+deleteCards.length === 0) {
    console.log('nothing to save')
    return 
  }
  updateDeckCards({addCards, updateCards, deleteCards, deleteKeys}, cardSeq, outline)
  setCardState(cState, {type: 'after-save'})
  setEditorState(newEditorState);
}

const ShowToolbar =  (
<EditToolbar
deck={deck}
updateDeck={props.updateDeck}
tbCallback={toolbarCallback}
editorState={editorState}
setEditorState={onChange}/>
)
// props.toc
debugger;
const toc = (<RenderOutline data={deck.outline}/>)

// {/* <Spinner/> */}
const QList = (
  <React.Fragment>
  {autoProgress && <CircularProgress/>}
 {!autoProgress &&  <QuestionList deck={deck} ref={qRef}
  card={currCard} questions={currCard?.flashes} />}
  </React.Fragment>

)
{/* <RenderTreeView data={cardState}/> */}
const debug = (
  <React.Fragment>
  <Typography> {rawContent} </Typography>
  <Divider/>
</React.Fragment>
)

const EditorComponent = (
  <MyEditor editorState={editorState}
  ref={editorRef}
  onFocus={onFocus}
  onChange={onChange}
  handleKeyCommand={handleKeyCommand}
  />
)

return (
  <Layout
    deck={deck}
    editorAux={debug}
    toolbar={ShowToolbar}
    toc={toc}
    onBlur={onBlur}
    >
    <MyEditor editorState={editorState}
    ref={editorRef}
    onFocus={onFocus}
    onChange={onChange}
    handleKeyCommand={handleKeyCommand}
    />
      </Layout>
)
}

