import React, {useState} from 'react';

export function useMenuHook() {
  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
    setIsOpen(true);
    event.preventDefault()
  
  }
  const handleMenuClose = (event) => {
    setAnchorEl(null);
    setIsOpen(false)
  }
  return [{isOpen, anchorEl}, handleMenuOpen, handleMenuClose]
}
