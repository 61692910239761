import React from 'react';
import {useSelector} from 'react-redux';
import BottomNav from './BottomNav';
import LandingPage from './LandingPage';
// import classes from './HomePage.module.css'
// import landingImage from '../../assets/img/one.jpg'
// import landingImage from 'assets/img/ai-geralt-landing.jpg'
import landingImage from 'assets/img/ai-split-books.png'
import homeImage from '../../assets/img/two.jpg'

import TopNav from 'components/Nav/TopNav';
import { makeStyles } from "@material-ui/core/styles";
import PDFViewer from 'components/UI/PDFViewer2';

import Tour from './Tour'
const homeStyle = (image) => ({
root:  {
  marginTop: 0,
  backgroundImage: 'url('+image+')',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  height: '100%',
  top: '0px',
  position: 'fixed',
  opacity: 0.8,
  // zIndex: -1,
  overflowY: 'auto',
}
})

//const useStyles = makeStyles(homeStyle)
const HomePage = (props) =>{
  const pageRef = React.useRef()

  const imageList = useSelector(state => state.appState.imageList)
  const user = useSelector(state => state.auth.user)
  const isAuth = useSelector(state => state.auth.isAuthenticated)
  const [navColor, setNavColor] = React.useState('transparent')

  console.log('image list for home page', imageList)
  
  
  
  // let homeImage
  // homeImage = imageList[0]?.url
  // debugger
  // let homeImage = 'https://images.unsplash.com/photo-1547036967-23d11aacaee0'
  let image = landingImage
  
  if (isAuth) {
    
    image = 'https://nuronote.com/images/'+imageList[0]?.url
    console.log('image', image)
  }
  
  // image = 'https://nuronote.com/images/1Z2niiBPg5A.jpg'

  // image = 'https://nuronote.com/images/bcW3NDWHMwc.jpg'

  
  const classes = makeStyles(homeStyle(image))()
  console.log('home paage', props)

  const handleScroll = e => {
    const windowsScrollTop = pageRef.current.scrollTop;
    // debugger;
    if (windowsScrollTop > 300)  {setNavColor('primary')}
      else {setNavColor('transparent')}
  }

  // <PDFViewer url='https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/examples/learning/helloworld.pdf'/>


return   (
    <React.Fragment>
      
      <div className={classes.root} styles={{ backgroundImage:`url(${image})` }}
      onScroll={handleScroll} ref={pageRef} >
        {!isAuth && <LandingPage/> }
     { isAuth &&  <React.Fragment>
     <TopNav isAuthenticated={isAuth} user={user} color={navColor}/>
     <div style={{position: 'absolute', left: '300px'}}>

     </div>
       <BottomNav decks={props.decks}/>
     </React.Fragment>}
     </div>
</React.Fragment>
  )
}
export default HomePage;
