import React, {useState, useRef, useEffect} from 'react';

import {Button, TextField, Modal, Tooltip} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom'
import {deckActions} from 'redux/actions'
import DocView from 'components/UI/DocView';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { getPdfText } from '../../utils/pdfExtract';
import {pdfProcess, processPdf} from 'utils/pdfProcess'
import IButton from 'components/UI/IButton'

export const FileUpload = () => {
    const dispatch = useDispatch()
    const history = useHistory()

  const [url, setURL] = useState(null)
  const [modalOpen, setModal] = useState(false)
  const [name, setName] = useState(null)

    const inputFile = useRef(null);
    const onUploadClick = () => {
      inputFile.current.click();
    };
    
  const handleFileUpload = e => {
    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;

      let parts = filename.split(".");

      const fileType = parts.pop()
      const name = parts.toString()
      console.log("fileType", name, fileType); //ex: zip, rar, jpg, svg etc.

    let file = files[0]
    let url = file && URL.createObjectURL(file)
    console.log('url', url)
    setURL(url)
    // setModal(true)

    if (url && fileType==='pdf') {

            getPdfText(url).then(function (pdfText) {
                console.log('pdf text', pdfText)
                const text = pdfProcess(pdfText)
                console.log('processed pdf text', text)
                submitHandler(name, text)
            }, 
            function (reason) {
                console.error(reason);
            });
        }
    }
  };
    const submitHandler =  (name, newContent) => {
       deckActions.createDeck({name: name}).then(newDeck => {
        dispatch(deckActions.addDeck(newDeck));
         console.log('response deck', newDeck)
         history.push({
           pathname: `/deck/${newDeck.id}`, 
           state: {newDeck: true, newContent: newContent}
          })
       })
    }



 const handleModalClose = (event) => {
    setModal(false)
  }

    return(
        <React.Fragment>
<Modal
  open={modalOpen}
  onClose={handleModalClose}
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
>
<DocView url={url}/>
</Modal>
      <input
        style={{ display: "none" }}
        // accept=".zip,.rar"
        ref={inputFile}
        onChange={handleFileUpload}
        type="file"
      />
      <IButton 
          onClick={onUploadClick}>
          <Tooltip title="Upload your document and convert to Deck of cards">
      <CloudUploadIcon />
      </Tooltip>
      </IButton>
        </React.Fragment>
    )
}


