import React from 'react';
import {RichUtils} from 'draft-js';
import {changeDepth, toggleCustomInlineStyle} from 'draftjs-utils';
import {addImage} from 'components/Edit/Editor/Images'

export const editorStyleChanges = props => {
  const {editorState, setEditorState, action} = props
  const {inlineStyle, blockType, adjustment,  imageUrl} = action.payload

  const toggleInlineStyle = (inlineStyle) => {
        setEditorState(
          RichUtils.toggleInlineStyle(editorState, inlineStyle)
              );
  }
  const adjustDepth = (adjustment) => {
    setEditorState(changeDepth(editorState, adjustment, 4))
  }
  const toggleBlockType = (blockType) => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, blockType)
    )
  }

  const toggleFontSize = () => {}
  const toggleFontFamily = () => {}

  function handleHighlight(){
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'HIGHLIGHT'));
  }

  function handleAddImage(imageUrl){
    //const urlValue = window.prompt("Paste Image Link");
    setEditorState(addImage({editorState, imageUrl}))
   };
   
   switch (action.type) {
     case 'TOGGLE_INLINE_STYLE':
        toggleInlineStyle(inlineStyle)
       break;
      case 'TOGGLE_BLOCK_TYPE':
        toggleBlockType(blockType);
        break;
      case 'INDENT':
        adjustDepth(adjustment)
        break;
      case 'OUTDENT':
        adjustDepth(adjustment)
        break;
      case 'HIGHLIGHT':
        handleHighlight();
        break;
      case 'TOGGLE_FONT_FAMILY':
        toggleFontFamily();
      case 'TOGGLE_FONT_SIZE':
        toggleFontSize();
      case 'ADD_IMAGE':
        handleAddImage(imageUrl)
     default:
   }

}
