import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Select, ListItemText, Input, InputLabel, MenuItem, FormControl}
from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 280,
    // borderRadius:'5px',
    // paddingBottom: '5px',
  },
  listItemText: {
    fontSize: '0.9em',
    height: '8px',
  }
}));

export default function SelectingMultipleItems({options,  value, selectionHandler, label}) {
  const classes = useStyles();
  const [selected, setSelected] = useState([value]);

  const onChange = e => {
    setSelected(e.target.value);
    selectionHandler(e.target.value)
    console.log('selected deck', e.target.value)
  };
// input={<Input id="multi" />
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="simple-select-label">{label}</InputLabel>
      <Select
        value={selected}
        onChange={onChange}

      >
        {options.map(option => (
          <MenuItem
            key={option.id} dense="true" value={option.id}>
            <ListItemText classes={{primary: classes.listItemText}}>
            {option.label}
          </ListItemText>

          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
