
import {
  container,
  card,
  title,
  main,
  whiteColor,
  mainRaised
} from "assets/jss/material-kit-pro-react.js";

const landingPageStyle = {
  root: {
    overflowY: 'scroll',
    // backgroundColor: 'black',
  },
  card: {
    backgroundColor: 'ivory',
  },

  container: {
    ...container,
    margin: '100px',
    // border: '1px solid black',
    borderRadius: '5px',
    backgroundColor: 'gray',
    opacity:0.9 ,
    color: whiteColor,
    zIndex: "2",
    padding: '20px',
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: whiteColor,
    textAlign: 'left',
    fontSize: "2.2rem",
  },
  subtitle: {
    fontSize: "1.313rem",
    // maxWidth: "500px",
    margin: "10px auto 0",
    textAlign: 'left',
  },
  main: {
    ...main
  },
  signupButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  section: {
    height: '250px',
    margin: '30px',
    padding: '10px 10px',
    display:'flex',
    backgroundColor:'ghostwhite',
    // border: '1px solid gray',
    alignItems: 'center',
    // opacity: '.9'
  },

  sectionText: {

    ...card,
    margin: '50px 0px',
    height: '75%',
    // width: "60%",
    color: 'black',
    fontSize: '1.3rem',
    padding: '50px 20px',
    backgroundColor: 'lightgray',

  },

  sectionImage: {
    ...card,
    margin: '50px -10px 50px 0px',
    height: '75%',
    // width: '40%',
    zIndex: 3,
    backgroundColor: 'lightcyan',
    borderRadius: '5px'

  },
  mainRaised: {
    ...mainRaised,
  border: '1px solid black',
  opacity: '0.8'
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  }
};

export default landingPageStyle;
