
import React, {useState, useReducer} from 'react';
import {useSelector} from 'react-redux';
import clsx from 'clsx';
import {INLINE_STYLES, BLOCK_TYPES, DROPDOWN_TYPES} from './Controls'
// import classes from './ControlStyles.module.css'

import { withStyles, makeStyles } from '@material-ui/core/styles';
import{MenuItem, Select, Input, FormControl, Divider, Grid, TextField, Typography } from '@material-ui/core';

// import {toolbarChanges} from './toolbarChanges';
import {ViewMode, ReviewToolbar} from './MoreToolbar'
import {RichToolbar} from './RichToolbar';
import {FlashMenu} from './SplitMenu';
import {SplitMenu} from './SplitMenu';
import {toolbarStyle} from './toolbarStyle'; 
import { DeckNameTags } from 'components/Deck/DeckNameTags';
const useStyles = makeStyles( toolbarStyle)

// const DeckName = (props) => {
  
// }

export const EditToolbar = (props) => {
  const classes = useStyles()
  const activeDeck = useSelector(state => state.appState.activeDeck)

    
  // <TextField id="deck-name"  size="small" variant="filled" color="secondary" className={classes.deckName} defaultValue={activeDeck}/>
    
    const viewMode = props.viewMode || 'doc'
    return(
      <Grid container className={classes.toolRoot}>
        <Grid item md={2}>
          <DeckNameTags deck={props.deck}/>
        </Grid>
{viewMode==='doc' && <React.Fragment>
          <Grid item className={classes.toolbar}>
          <RichToolbar {...props} />
          <Divider orientation="vertical" flexItem />
          <Divider orientation="vertical" flexItem />
        </Grid>

          <div className={classes.splitToolbar}>
          <SplitMenu tbCallback={props.tbCallback}/>
          <Divider orientation="vertical" flexItem />
          <FlashMenu tbCallback={props.tbCallback}/>
          <Divider orientation="vertical" flexItem />
        <Divider orientation="vertical" flexItem />
        </div>
</React.Fragment>
      
    }

<div className={classes.viewMode}>
<ViewMode deck={props.deck}
      className={classes.viewMode}/>
    </div>
    </Grid>
)

}

// {   viewMode==='read' &&
// <Grid item md={6}className={classes.reviewToolbar}>
//   <ReviewToolbar tbCallback={props.tbCallback}/>
// </Grid>
// }