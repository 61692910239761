import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Select, ListItemText, Input, InputLabel, MenuItem, FormControl}
from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  dropDown: {
    height: '100%',
    backgroundColor: 'white',
    width: props => props.width,
    marginRight:'2px',
  },
  listItemText: {
    fontSize: '0.8em',
    height: '100%',
  }
}));

export const DropDown = ({options, defaultValue, selectionHandler, width}) => {

      const classes = useStyles({width})
      let drop_default = defaultValue ? defaultValue : options[0].style
      // debugger
      const [selected, setSelected] = useState(drop_default)

      useEffect(()=> {
      let drop_default = defaultValue ? defaultValue : options[0].style

        setSelected(drop_default)
      },[defaultValue]
      )
       const onChange = (e) =>{
         setSelected(e.target.value)
         console.log('selected', e.target.value)
         selectionHandler(e.target.value)
       }
       return(
        <Select className={classes.dropDown}
        value={selected}
        onChange={onChange}
        input={<Input />}
      >
        {options.map(option => (
          <MenuItem  key={option.style} value={option.style}>
          <ListItemText classes={{primary: classes.listItemText}}>
            {option.label}
          </ListItemText>
          </MenuItem>
        ))}
      </Select>
        )
     }
