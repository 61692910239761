import React from 'react';

export function getNextCard(seq, cardKey, direction) {
  let index = seq.indexOf(cardKey)
  index = direction==='prev' ? index-1: index+1
  if ((index > -1) && (index < seq.length)) {
    return seq[index]
  }
  return cardKey
}

export const getCardLastBlock = (cardState, cardKey ) => {
  const cardBlocks = cardState.cardDict[cardKey]
  return cardBlocks[cardBlocks.length-1]
}

export function getMergeCardKeys(cardState, blockKey, direction) {
  const cardKey = cardState.blockDict[blockKey]
  const nextKey = getNextCard(cardState.order, cardKey, direction)

  let fromKey = direction==='prev'? cardKey: nextKey // from card
  let toKey = direction==='prev'? nextKey: cardKey // to card
  return [fromKey, toKey]
}
