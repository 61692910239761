import React, {useState} from "react";
import {useSelector, dispatch} from 'react-redux';
import JoyRide from "react-joyride";
import {Button, Paper} from '@material-ui/core'


// Tour steps
const TOUR_STEPS = [
  {
    target: ".quickNote",
    title: "Quick Note",
    content: "This is where you can add a quick note to deck.",
  },
  {
      target: ".deckOptions",
      title: "Pick your Deck",
      disableBeacon: "true",
      content:"Choose where you want to save your quick note"
  },
  {
      target: ".newDeck",
      title: "Adding Deck? ",
      content: "Create a new deck by clicking here"

  },
  {
      target: ".deckList",
      title: "My decks",
      content: "View all your decks here"
  },
  {
    target: ".deckItem",
    title: "View Deck",
    content: "Click here to edit or review your deck"
  }
];
// Tour component
const PageGuide = (props) => {
  const profile = useSelector(state => state.auth.profile)

  const preferences = profile?.preferences

  const run  = preferences && preferences.tour?.includes('home')
  // const [run, setRun] = useState(false)
  debugger;
  const StartComponent = () => (
  <Paper> 
<Button> Start Tour </Button>
</Paper>
)
      // beaconComponent={StartComponent}

  return (
    <>
      <JoyRide run={run} 
      spotlightClicks
      showProgress
      showSkipButton
      steps={TOUR_STEPS} continuous />

    </>
    
  );
};

    // <StartComponent />
export default PageGuide;