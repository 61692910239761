export const bottomNavStyle = theme => ({

bottomNav: {
  width: '100%',
  marginLeft: '10px',
[theme.breakpoints.down('sm')]: {
    width: '80vw',
    marginLeft: '10vw'
  },
  alignSelf: 'flex-end',
  backgroundColor: 'orange',
  display: 'flex',
},


})
