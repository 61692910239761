
import bold from 'assets/img/editorToolbar/bold.svg';
import italic from 'assets/img/editorToolbar/italic.svg';
import underline from 'assets/img/editorToolbar/underline.svg';
import strikethrough from 'assets/img/editorToolbar/strikethrough.svg';
import monospace from 'assets/img/editorToolbar/monospace.svg';
import fontSize from 'assets/img/editorToolbar/font-size.svg';
import indent from 'assets/img/editorToolbar/indent.svg';
import outdent from 'assets/img/editorToolbar/outdent.svg';
import ordered from 'assets/img/editorToolbar/list-ordered.svg';
import unordered from 'assets/img/editorToolbar/list-unordered.svg';
import left from 'assets/img/editorToolbar/align-left.svg';
import center from 'assets/img/editorToolbar/align-center.svg';
import right from 'assets/img/editorToolbar/align-right.svg';
import justify from 'assets/img/editorToolbar/align-justify.svg';
import color from 'assets/img/editorToolbar/color.svg';
import eraser from 'assets/img/editorToolbar/eraser.svg';
import link from 'assets/img/editorToolbar/link.svg';
import unlink from 'assets/img/editorToolbar/unlink.svg';
import emoji from 'assets/img/editorToolbar/emoji.svg';
import embedded from 'assets/img/editorToolbar/embedded.svg';
import image from 'assets/img/editorToolbar/image.svg';
import undo from 'assets/img/editorToolbar/undo.svg';
import redo from 'assets/img/editorToolbar/redo.svg';
import subscript from 'assets/img/editorToolbar/subscript.svg';
import superscript from 'assets/img/editorToolbar/superscript.svg';
// import { Highlight } from '@material-ui/icons';




export const INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD', icon: bold},
  {label: 'Italic', style: 'ITALIC', icon: italic},
  {label: 'Underline', style: 'UNDERLINE', icon: underline},
  {label: 'Monospace', style: 'CODE', icon: monospace},
  {label: 'Subscript', style: 'SUBSCRIPT', icon: subscript},
  {label: 'Superscript', style:'SUPERSCRIPT', icon: superscript},
  {label: 'Strikethrough', style:'strikethrough', icon: strikethrough},
  {lable: 'HighLight', style:'HIGHLIGHT', icon: eraser},
];

export const BLOCK_TYPES = {
  list: [
    {label: 'UL', style: 'unordered-list-item', icon: unordered},
    {label: 'OL', style: 'ordered-list-item', icon: ordered},
    {label: 'Indent', style: 'indent', icon: indent},
    {label: 'outdent', style: 'outdent', icon: outdent},
  ],
  align: [
    {label: 'Left', style: 'left', icon: left},
    {label: 'Center', style: 'center', icon: center},
    {label: 'Right', style: 'right', icon: right},
    {label: 'Justify', style: 'justify', icon: justify},
  ],
  code: [
    {label: 'Blockquote', style: 'blockquote'},
    {label: 'Code Block', style: 'code-block'},
  ]
}


export const DROPDOWN_TYPES = {
  heading: [    {label: 'Normal text', style: 'unstyled', dropdown: true},
    {label: 'Heading', style: 'header-one', dropdown: true},
      {label: 'Heading2', style: 'header-two', dropdown: true},
      {label: 'Heading3', style: 'header-three', dropdown: true},
      {label: 'Heading4', style: 'header-four', dropdown: true},
      {label: 'Heading5', style: 'header-five', dropdown: true},
      {label: 'Heading6', style: 'header-six', dropdown: true},
    ],
    fontSize: {
      icon: fontSize,
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48,],
    },
    fontFamily: {
      options: [
        "Arial",
        "Georgia",
        "Impact",
        "Tahoma",
        "Times",
        "Verdana"
      ],
    },


}
