export const FETCH_DECKS = 'FETCH_DECKS';
export const ADD_DECK = 'ADD_DECK';
export const DELETE_DECK = 'DELETE_DECK';
export const UPDATE_DECK = 'UPDATE_DECK';

export const FETCH_CARDS = 'FETCH_CARDS';
export const ADD_NOTECARD = 'ADD_NOTECARD';
export const UPDATE_NOTECARD = 'UPDATE_NOTECARD';
export const DELETE_NOTECARD = 'DELETE_NOTECARD';

export const FETCH_DECKSHUB = 'FETCH_DECKSHUB'
export const FETCH_HUBDECK_DETAILS = 'FETCH_HUBDECK_DETAILS'
export const HUBDECK_DOWNLOAD = 'HUBDECK_DOWNLOAD';
export const PUBLISH_DONE = 'PUBLISH_DONE';


export const UPDATE_EDITOR = 'UPDATE_EDITOR'

export const UPDATE_EDITOR_CARD = 'UPDATE_EDITOR_CARD'
export const ADD_EDITOR_CARD = 'ADD_EDITOR_CARD'
export const REMOVE_EDITOR_CARD = 'REMOVE_EDITOR_CARD'

export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';

export const UPDATE_CARDVIEW = 'UPDATE_CARDVIEW'
export const UPDATE_SPLIT_OPTIONS = 'UPDATE_SPLIT_OPTIONS'
export const ADD_EDIT_PLUGINS = 'ADD_EDIT_PLUGINS'

export const UPDATE_ACTIVE_DECK = 'UPDATE_ACTIVE_DECK'
export const UPDATE_ACTIVE_CARD = 'UPDATE_ACTIVE_CARD'
export const UPDATE_QUESTION = 'UPDATE_QUESTION'
export const DELETE_QUESTION = 'DELETE_QUESTION'

export const FETCH_IMAGE_LIST = 'FETCH_IMAGE_LIST'
export const UPDATE_SNACKBAR = 'UPDATE_SNACKBAR'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'