import React, {useState, useEffect} from 'react';
import {nanoid} from 'nanoid';
import {genKey} from 'draft-js';


export const getContent = (cards, cardSeq, outline, newContent) => {
    let contentBlocks = [], addedCards = {}, outlineMap = [], keyId = {}, newSeq=[], newBlockText=''
    let sepBlock, entityMap={}, outlineBlock
    if (cards && (cards.length > 0 )) {
      cardSeq.forEach((cardKey) => {
        debugger;
      let card = cards.filter(card => card.key === cardKey)[0]
      if (card) {
        const {cardBlocks, cardEntities} = getCardData(card)
        entityMap = {...entityMap, ...cardEntities}
        // sepBlock = getNewBlock('sepBlock')
        sepBlock = {key: card.key+'-sep', type: 'sepBlock', text:""}
        cardBlocks.push(sepBlock)
        addedCards[card.key] = cardBlocks
        contentBlocks = contentBlocks.concat(cardBlocks)
        keyId[card.key] = card.id
        newSeq.push(card.key)
        }
        // else if (cardKey.includes('header')) {
        //   let key = cardKey.replace('header-', '')
        //   outlineBlock = outline.headerBlocks.find(block => block.key === key)
        //   // outlineBlock = outline.headerBlocks[key] //getOutlineBlock(cardKey, outline)
        //   outlineMap.push(key)
        //   contentBlocks = contentBlocks.concat(outlineBlock)
        // }
      })
    } else {
    if (newContent) {
      newContent.forEach(
        block => {
          const newBlock = getNewBlock('unstyled', block)
          let cardKey = nanoid(5)
        
          // addedCards[cardKey] = [newBlock]
          newSeq.push(cardKey)
          sepBlock = {key: cardKey+'-sep', type: 'sepBlock', text:""}
          const cardBlocks = [newBlock]
          cardBlocks.push(sepBlock)
          addedCards[cardKey] = cardBlocks
          contentBlocks = contentBlocks.concat(cardBlocks)  
          // contentBlocks = contentBlocks.concat(newBlock)
        }
      )
      // newBlockText = newContent.toString()
    } 
    }
// add a new block in the end
    const newBlock = getNewBlock('unstyled', newBlockText)
    let cardKey = nanoid(5)
    addedCards[cardKey] = [newBlock]
    newSeq.push(cardKey)
    contentBlocks = contentBlocks.concat(newBlock)
  console.log('getContent', newSeq, contentBlocks)
  return {blocks:contentBlocks, entityMap, cardKeyBlocks: addedCards, keyId, newSeq, outlineMap}
}

export const getCardData = (card) => {
  let cardText = card.text ? JSON.parse(card.text) : {}
  let data = {}
    if (cardText.hasOwnProperty('blocks')) {
      // cardText.blocks = cardText.blocks.map(block => ( {...block, ['parent']: 'test',['text']: block.key+'--'+block.type+'--'+block.text}))
      data['cardBlocks'] = cardText.blocks
    }
    if (cardText.hasOwnProperty('entityMap')){
      data['cardEntities'] = cardText.entityMap
    }
  console.log('card data', card, data)
  return data
  }

const getNewBlock = (type, text='') => ({
         key: genKey(),
         type: type,
         text:text,
       })
