
import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Link, useHistory, Redirect } from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { appStateActions, deckActions} from 'redux/actions'
import FilterListIcon from '@material-ui/icons/FilterList';
import {InputAdornment, Tooltip} from '@material-ui/core';
import {Modal} from '@material-ui/core'

import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import {Chip , TextField, IconButton, AddIcon} from 'assets/jss/material.js'
import {deckMenuStyle} from './deckMenuStyle';

const useStyles = makeStyles(deckMenuStyle)

const DeckFilter = (props) => (
      <TextField variant="filled"
      onChange={props.handleFilter}
      value={props.value}
      variant="filled"
      fullWidth
      placeholder="filter decks..."
      InputProps={{
        startAdornment: <InputAdornment postion="end">
        <FilterListIcon/> </InputAdornment>,
      }}
      />
)

const HubDecks = (props) => {
  // console.log('Mydecks', decks)
  const classes = useStyles()
  const dispatch = useDispatch();
  const history = useHistory();
  const allDecks = useSelector(state => state.hubDecks)

  const chipClasses = {root: classes.chipRoot, label: classes.chipLabel}
  const [filterValue, setFilter] = useState(null)
  const [decks, setDecks] = useState(allDecks)


  const handleFilter = event => {
    const newFilter = event.target.value
    setFilter(newFilter);

    const newDecks = allDecks.filter(deck => deck.name.toLowerCase().includes(newFilter.toLowerCase()))
    setDecks(newDecks)

  }
  // const reviewHandler = (deckId) => {
  //   dispatch(appStateActions.updateViewMode(deckId, 'read'))
  //   history.push(`/deck/${deckId}`)
  // }
  if (decks) {
      let maxLen = Math.min(decks.length, 100);
    return (
      <div className={classes.containerRight}>
        <div className={classes.deckListHeader}>
      <Chip label="Recommendations"
       classes={chipClasses}
       color="secondary" />
          </div>
          <div className={classes.deckList}>
            <List>
          {decks.slice(0, maxLen).map( (item) =>
          (<Fragment key={item.id} >
              <ListItem button dense
                onClick={()=> {history.push(`/deck-hub/${item.id}`)}} 
                selected={item.selected}
                >
                  <ListItemText primary={item.name}
                    secondary={item.cards.length}
                    primaryTypographyProps={{
                      color: item.selected? 'primary': undefined }}
                    />
                    </ListItem>
            <Divider/>
            </Fragment>
          )
        )}
      </List>
      </div>
      <div className={classes.deckFilter}>
      <DeckFilter handleFilter={handleFilter} value={filterValue}/>
      </div>
    </div>
    )
  }
  else {
    return null;
  }
}

export default HubDecks;
{/* <IconButton onClick={()=>reviewHandler(item.id)}>
<PlayCircleOutlineIcon/>
  </IconButton> */}
