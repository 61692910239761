import axios from 'axios';
import * as actionType from '../actionTypes';

export const fetchNoteCards = (deckId) => {
  return (dispatch, getState) => {
    const data = {};
    return axios.get('deck/'+deckId+'/',data)
    .then(response => {
      return dispatch({
        type:  actionType.FETCH_CARDS,
        cards: response.data.cards,
        deckId: deckId,
      })
    }, error => {console.log('error from  fetch cards', error)}
    )
  }
}

export const updateNoteCard = (deckId, cards) => {
    console.log('updating cards', cards)
  return (dispatch) => {
    let axiosArray = [];
    cards.forEach(card => {
      let data = card
      axiosArray.push(axios.put('card/'+card.id+'/', data))
    })
    let retData = []
    return axios.all(axiosArray)
    .then(axios.spread((...responses) => {
      responses.forEach(res => {
        retData.push(res.data)
        console.log(res)}
      )
       console.log('all submitted')
       return dispatch({
         type: actionType.UPDATE_NOTECARD,
         cards: retData,
         deckId: deckId
       })
      })
      )
  }
}

export const updateNoteCards = (deckId, cards) => {
  return (dispatch) => {
    const data = cards
    // return axios.put('card/'+card.id+'/', data)
    // .then(response => {console.log('udpated card response', response)
    //     return dispatch({
    //       type: actionType.UPDATE_NOTECARD,
    //       card: response.data,
    //       deckId: deckId,
    //     })
    //   },
    //   error => {console.log(error)}
    // )
  }
}

export const deleteNoteCard = (deckId, cards) => {
    return (dispatch) => {
          let axiosArray = [];
          let retData = []
          cards.forEach(cardId => {
            retData.push(cardId)
            axiosArray.push(axios.delete('card/'+cardId+'/'))
          })
          return axios.all(axiosArray)
          .then(axios.spread((...responses) => {
            responses.forEach(res => {
              console.log(res)}
            )
             console.log('all card deleted', retData)
             return dispatch({
               type: actionType.DELETE_NOTECARD,
               cards: retData,
               deckId: deckId
             })
            })
          )
        }
      }

export const addNoteCard = (deckId, cards, cardSeq) => {
    console.log('adding cards', cards)
    return (dispatch) => {
          let axiosArray = [];
          cards.forEach(card => {
            let data = {card, deckId}
            axiosArray.push(axios.post('card-create/', data))
          })
          let retData = []
          return axios.all(axiosArray)
          .then(axios.spread((...responses) => {
              responses.forEach(res => {
                retData.push(res.data)
                console.log(res)}
              )

               console.log('all new cards submitted')
             return dispatch({
               type: actionType.ADD_NOTECARD,
               cards: retData,
               deckId: deckId
             })
            })
          )
        }
      }

// export const addNoteCard = (deckId, cards) => {
//     console.log('adding cards', cards)
//
//     if (cards.length > 1) {
//       return
//     }
//     const card = cards[0]
//     return (dispatch) => {
//     const data = {card: card, deckId: deckId}
//     return axios.post('card-create/', data)
//     .then (response => {
//       console.log('added new card', response);
//       dispatch({
//         type: actionType.ADD_NOTECARD,
//         deckId,
//         card: response.data,
//       })
//     } )
//   }
// }

export const updateQuestion = (deckId, question) => {
    console.log('updating question', question)
  return (dispatch) => {
      return axios.put('flash/'+question.id+'/', question)
    .then(response => {console.log('udpated queestion response', response)
        return dispatch({
          type: actionType.UPDATE_QUESTION,
          question: response.data,
          deckId,
        })
      },
      error => {console.log(error)}
    )
  }
}
export const deleteQuestion = (deckId, question) => {
  console.log('deleting question', question);
  return(dispatch) => {
    return axios.delete('flash/'+question.id+'/')
    .then(response => {
      return dispatch({
        type: actionType.DELETE_QUESTION,
        question,
        deckId
      })
    },
    error => {console.log(error)}
    )
  }
}
export const createQuestion = (deckId, question) => {
    console.log('updating question', question)
  return (dispatch) => {
      return axios.post('flash-create/', question)
    .then(response => {console.log('udpated queestion response', response)
        return dispatch({
          type: actionType.UPDATE_QUESTION,
          question: response.data,
          deckId,
        })
      },
      error => {console.log(error)}
    )
  }
}

export const generateCardQuestions = (cardId, deckId) => { 
  console.log('generating questions for ', cardId)
  return (dispatch) => {
    return axios.post('card-qa-generate/'+cardId+'/')
    .then(response => {
      console.log('update card with new questions', response)
      return dispatch({
        type: actionType.UPDATE_NOTECARD,
        cards:[response.data],
        deckId: deckId
      })
    })
  }
}