import React, {useState} from 'react';
import {Link,withRouter, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {
fade, makeStyles, withStyles,
Hidden,
AppBar, Toolbar,Badge, MenuItem, Menu, Chip,
InputBase, Button, Modal, Typography, IconButton,
MenuIcon, AddIcon, SearchIcon, AccountCircle, MailIcon,
ViewStreamIcon, NotificationsIcon, MoreIcon, 
} from 'assets/jss/material.js'

import HomeIcon from '@material-ui/icons/Home';
import NuroIcon from 'components/UI/NuroIcon';
import {useMenuHook} from 'hooks'
import {appStateActions, authActions, deckActions} from 'redux/actions'

import {navStyle} from './topNavStyle'
import {ProfileMenu, MainMenuList, NavMenu, SearchComponent} from './Components'

const useStyles =  makeStyles(navStyle);



export const TopNav = (props) => {
  // const changeColorOnScroll = {height: 200, color: 'primary', }
  const color = props.color? props.color : 'white'
  
  const classes = useStyles();

  const history = useHistory();
   const [menu, handleMainMenuOpen, handleMenuClose] = useMenuHook()
   const [profile, handleProfileMenuOpen, handleProfileMenuClose] = useMenuHook()

  const [loginModal, setLoginModal] = useState(false)
  // const [signupModal, setSignupModal] = useState(false)

  const dispatch = useDispatch()
  const isAuth = useSelector(state => state.auth.isAuthenticated)
  const activeDeck = useSelector(state => state.appState.activeDeck)
  const logout = () => {
    dispatch(authActions.logout())
    history.push('/')
  }

  // const signin = (<Signin/>)

    const toggleLoginModal = () => {
        setLoginModal(!loginModal)
    }

    const handleLogout = (event) => {
      logout()
      handleProfileMenuClose(event);
    }

    // <NuroIcon/>  
      const appBarClasses = classNames({
        [classes.appbar]: true,
        [classes[color]]: color,
      })
            // <SearchComponent classes={classes} />
      return (
        <React.Fragment>
        <div  className={appBarClasses} position="fixed">
          <div className={classes.container}  >
            {isAuth &&
              <React.Fragment>


      <Chip icon={<NuroIcon/>} label={"NuroNote"} classes={{root: classes.chipRoot}}
      onClick={()=> history.push('/')} color="secondary" />

    <Chip label={"My Decks"} classes={{root: classes.chipRoot}}
      onClick={()=> history.push('/decks')} color="secondary" />


    <Chip label={"Explore"} classes={{root: classes.chipRoot}}
      onClick={()=> history.push('/hub-explore')} color="secondary" />

            <div className={classes.grow} />
            <div className={classes.signin}>
            
            <IconButton size='small'
              onClick={handleProfileMenuOpen} >
                <AccountCircle color="primary" fontSize="large"/>
                </IconButton>
            </div>
          </React.Fragment>
          }
                  </div>
        </div>


        <ProfileMenu anchorEl={profile.anchorEl}
          open={profile.isOpen}
          onClose={handleProfileMenuClose}
          logout={handleLogout}
          />

         <div className={classes.toolbarMargin} />

      </React.Fragment>
    );

}
export default withRouter(TopNav)
// { !isAuth &&
//             <div className={classes.signin}>
//             <Chip color="secondary"
//               classes={{root: classes.chipRoot}}
//               label="Signin" onClick={toggleLoginModal} />
//             </div>
//           }


        //  <IconButton
        //       className={classes.menuButton}
        //       edge="start"
        //       color="inherit"
        //       onClick={handleMainMenuOpen}
        //       aria-label="open drawer"
        //     >
        //       <MenuIcon />
        //     </IconButton>
        //     <MainMenuList anchorEl={menu.anchorEl}
        //       open={menu.isOpen}
        //       onClose={handleMenuClose}
        //       />

        //     <Hidden smDown>
        //     <NavMenu history={history} classes={classes} />
        //     </Hidden>
   