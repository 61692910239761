import * as actionType from '../actionTypes'
const initialState = []

export const hubDecks = (state=initialState, action) => {
    switch (action.type) {
      case actionType.FETCH_DECKSHUB:
          return action.decks;
    default: 
        return state;
    }
}

export const hubDeckDetails = (state=[], action) =>{
    switch (action.type) {
        case actionType.FETCH_HUBDECK_DETAILS:
            return {...state, [action.deckId]: action.cards};
        default:
            return state;
    }
}