import React, {useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles'
import {nanoid} from 'nanoid';
import AddIcon from '@material-ui/icons/Add';

import {Button, IconButton, Card, CardActionArea,
  CardActions, CardContent, Chip, Typography, Avatar, Divider, Modal, Tooltip} from '@material-ui/core'
import ButtonBase from '@material-ui/core/ButtonBase';
import EditIcon from '@material-ui/icons/Edit';
import {cardActions} from '../../redux/actions'
import IButton from 'components/UI/IButton'
import classes from './NewNote.module.css'
import {EditorState, convertToRaw} from 'draft-js';
import {MyEditor} from 'components/Edit/Editor/'
import MultiSelect from '../UI/MultiSelect'
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';

import {FileUpload} from 'components/UI/FileUpload'
import Tour from './Tour'
import {newNoteStyle} from './newNoteStyle'

const getMenuOptions = (decks) => {
  return
}
const useStyles = makeStyles(newNoteStyle)
const NewNote = ({decks}) => {

  const classes = useStyles()
  const [editing, setEditing] = useState(false)  

    let default_deck = useSelector(state => state.auth.default)
    if (!default_deck) default_deck=decks[0]?.id
    const card = {key: nanoid(5), title:'quickadd'}
    const deckOptions = decks.map(deck => ({id: deck.id, label: deck.name}))
    const [deck, setDeck] = useState(default_deck)
    const cardRef = useRef(null)
  const selectionHandler = (deckId) => {
    setDeck(deckId)
  }
  const toggleNote = () => {
    setEditing(!editing)
  }
  
  const SaveDeck = () => (
    <div className={classes.SaveBar}>
         <Typography variant="h5" color="secondary"> Capture note in </Typography>
          <div className={`${classes.ChooseDeck} deckOptions`}>
          <MultiSelect options={deckOptions} label=""
            value={deck}  selectionHandler={selectionHandler}/>
          </div>
      </div>
    )
    
    // <Chip classes={{root: classes.captureDiv}} avatar={<Avatar> <EditIcon/></Avatar>} label="Capture Thought" />
    const Compose = () => (
      <div className={classes.captureDiv}> 
      <Tooltip title="Add note to deck">
      <Button className={classes.buttonCapture} onClick={toggleNote}>
      <EditIcon classes={{root: classes.captureIcon}} /> 
      <span> Capture Thought</span>
         </Button></Tooltip>
         </div>
    )
    
    // { !editing && <Compose />
    // }     


 



  const saveClick = ()=> cardRef.current.handleSave()
    return(
      <React.Fragment>
      <Tour/>
{
  <div className={classes.containerOuter}>
      <div className={classes.leftSemi}>

      <FileUpload/>
      </div>
      <div className={`${classes.NewNote} quickNote`}>

        <CardEditor ref={cardRef} classes={classes.CardEditor} 
         deckId={deck} newCard />
        <Divider/>
        <SaveDeck/>

    </div>
    <div className={`${classes.rightSemi} rightSemi`}>
      <IButton
       onClick={saveClick}>
      <Tooltip title="Add Note to Deck">
      <PlaylistAdd style={{fontSize: 30}}/> 
      </Tooltip>
      </IButton>
    </div>
    </div>}


  </React.Fragment>
  );

}



      //<CardEditor card={card} updateNote={addNote} newCard/>
export default NewNote;


export const CardEditor = React.forwardRef(({deckId, newCard, classes, card}, ref) => {
    const dispatch = useDispatch()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [modified, setModified] = useState(false)

    function onChange(newState){
      const oldContent = editorState.getCurrentContent()
      const newContent = newState.getCurrentContent()
      if (oldContent !== newContent ) setModified(true)
      setEditorState(newState)
    }

    function handleKeyCommand(command){
      if (command =='myeditor-save') {

      handleSave()
      return 'handled'
      }
      return 'not-handled'
    }

    function handleSave(){
      if (modified){
        const currentContent = editorState.getCurrentContent()
        const rawContent = convertToRaw(currentContent)
        const plainText = currentContent.getPlainText()
        if (newCard) {
          const key = nanoid()
          const cardData = { key, plainText, text: JSON.stringify(rawContent)}
          dispatch(cardActions.addNoteCard(deckId, [cardData]))
        }
      }
    }

    return (
      <div className={classes} onBlur={handleSave}>
      <MyEditor
      handleKeyCommand={handleKeyCommand}
      editorState={editorState}
      onChange={onChange}
        />
    </div>
    )
}
)