import React, {Fragment, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {appStateActions} from 'redux/actions'

import {Button, Divider, ButtonGroup, FormGroup, FormControl, MenuItem, 
  InputLabel, FormControlLabel, Select, Checkbox, ListItemSecondaryAction, 
  Box, Typography, LinearProgress} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EditIcon from '@material-ui/icons/Edit';
import MultiSelect from 'components/UI/MultiSelect'

import Pagination from '@material-ui/lab/Pagination';
import RightTimeline from 'components/UI/TimeLine'

const useStyles = makeStyles((theme) => ({
      container: {
      '& > *' : {marginTop: '20px',
      display: 'block',
      backgroundColor: 'whitesmoke',
      boxShadow: '1px 1px 1px lightgray',
      border: '1px solid lightgray',
      },
      },
      outerBox: {
        backgroundColor: 'gray',
        border: '1px solid lightgray'
      },
      SelectControl: {
        // margin: theme.spacing(1),
        minWidth: 200,
        backgroundColor: 'lightblue'
      },
      selectEmpty: {
        // marginTop: theme.spacing(2),
      },
      paginationRoot: {
        '& > *': {
          marginTop: theme.spacing(2),
        },
      },

}));


export const ReviewControlPanel = (props) => {
  
  const dispatch = useDispatch()
  const classes = useStyles()
  const [typeState, setType] = useState({content: true, flash: true, reviewId: props.reviewId}) 
  debugger;
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value)
    props.tbCallback('pageClick', parseInt(value)-1)
  }
  useEffect(()=>{
    setType({...typeState, reviewId: props.reviewId})
  }, [props.reviewId])


  const contentTypeChange = (event) => {
    setType({ ...typeState, [event.target.name]: event.target.checked });
    props.tbCallback('contentType', typeState)
  };

const reviewSelectionHandler = (event) => {
  setType({...typeState, reviewId: event.target.value})
  props.tbCallback('reviewId', event.target.value)
  console.log('newReviewstate', event.target.value)
}
debugger

  const reviewOptions = props.deck? Object.entries(props.deck.next_review).map(([k,v]) => 
  ({id: k, label: k+' ('+v.done?.length+'/'+(v.cards?.length+v.done?.length)+')'})): []

  const DiffLevel = () => (
    <ButtonGroup>
      <Button> Easy </Button>
      <Button> Medium </Button>
      <Button> Hard </Button>
    </ButtonGroup>
  )
    
    const reviewSet = (
      <FormControl className={classes.formControl}>
    <Select
        value={typeState.reviewId}
        onChange={reviewSelectionHandler}
      >
        {reviewOptions.map(rev => (<MenuItem dense value={rev.id}>{rev.label}</MenuItem>))}
      </Select>
      </FormControl>
    )

    const contentType = (
  <FormGroup row>
  <FormControlLabel
    control={<Checkbox checked={typeState.content} onChange={contentTypeChange} name="content" />}
    label="Content"
    />
  <FormControlLabel
    control={
      <Checkbox   checked={typeState.flash}   onChange={contentTypeChange}
      name="flash"  /> } label="Flash"
    />
  </FormGroup>
    )


  return (
    <div className={classes.container}>
      {reviewSet}
      <Divider/>
      {contentType}
      <Divider/>
    <Pagination count={props.itemCount} page={page} 
        onChange={handleChange} siblingCount={2} variant="outlined" shape="rounded" />      
       
       <Box display="flex" alignItems="center">
         <Divider/>
      <Box width="50%" mr={1}>
        <LinearProgress variant="determinate" value={props.progressValue} />
      </Box>
      <Divider/>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.progressValue,
        )}%`}</Typography>
      </Box>
    </Box>
    <RightTimeline items={Object.keys(props.deck.review_history)} />

    </div>
  )
  
}
