import axios from 'axios';

const initialState = {
  accessToken: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
  isAuthenticated: null,
  isLoading: true,
  user: null,
  profile: null,
  authError: null,
}
const logout = (state) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  delete axios.defaults.headers.common['Authorization']
  return { ...state, accessToken: null, refreshToken: null, user: null,
    isAuthenticated: false, isLoading: false};
}

export const  auth = (state=initialState, action) => {
  let newState
  switch(action.type){
    case 'USER_LOADING':
      return {...state, isLoading: true};
    case 'USER_LOADED':
      const default_deck = action.data.profile.default_deck
      const user = action.data.user.first_name
      return {...state, isAuthenticated: true, isLoading: false, 
      user: user, default_deck: default_deck, profile: action.data.profile};
    case 'SIGNUP_SUCCESSFUL':
    case 'LOGIN_SUCCESSFUL':
      localStorage.setItem("accessToken", action.data.access);
      localStorage.setItem("refreshToken", action.data.refresh);
      delete axios.defaults.headers.common['Authorization']
      if (action.data.access) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + action.data.access
      }
      return { ...state, accessToken: action.data.access, authError: null,
        user: action.data.user, isAuthenticated: true, isLoading: false};
    case 'AUTHENTICATION_ERROR':
    case 'SIGNUP_FAILED':
      newState = logout(state)
      return {...newState, authError: action.data}
    case 'LOGIN_FAILED':
      console.log('updating login message')
        newState = logout(state)
        return {...newState, authError: action.data}
    case 'LOGOUT_SUCCESSFUL':
      console.log('logging out', state)
      newState = logout(state)
      return {...newState}
    default:
      return state;
  }
}
