


// const list = [{key: 'h10', type: 1},
//  {key: 'h21',type: 2} ,
// {key: 'h32', type: 3} , 
// {key: 'h33', type: 3},
// {key: 'h24', type: 2}, 
// {key: 'h25', type: 2},
// {key: 'h16', type: 1},
// {key: 'h27', type: 2}];

const groupBy  = (list, key) => list.reduce((rv, x) => ({
  ...rv,
  [x[key]]: [...(rv[x[key]] || []), x ]
}),{} );

export const getTree = (flatList) => {

  flatList = flatList.map(block => ({key: block.key, type: block.type}))
  // const minLevel = Math.min(...flatList.map(item => item.type))
  const getLevel = (item) => {

  const headings = ['header-one', 'header-two','header-three', 'header-four', 'header-five', 'header-six']
  const index = headings.indexOf(item.type) 
    if ( index > -1) return index + 1
    return index
  }

    let finalList = {}
  
  const makeTree = (parent, desList) => {
    let gl = groupBy(desList, 'type')
debugger;
    if (desList.length === 0 ) return;
    if (Object.keys(gl).length ===1){
      console.log('last one', {[parent]: desList})
      finalList[parent] = desList      // return {parent: list}
      return
    }
    else {      // mixed levels
      const minLevel = Math.min(...desList.map(item => getLevel(item)))
      if (minLevel < 0) return
      const mainLevel = desList.filter(item => parseInt(getLevel(item)) === minLevel)
      console.log('currentLevel:', mainLevel)
      finalList[parent] = mainLevel // add main level to tree 
  
      let i = 0, currentParent = mainLevel[0]?.key, block={}
      let subLists = [],  children=[], blockArray = []
      while (i < desList.length) {
        block = desList[i];
        i += 1
        if (getLevel(block) == minLevel) {// new partition has reached add all prior blocks to sublist
          if (blockArray.length > 0) subLists.push({parent: currentParent, blocks: blockArray})
          blockArray = []
          currentParent = block.key
        }
        else{
          blockArray.push(block)
        }
      }
    if (blockArray.length > 0) subLists.push({parent: currentParent, blocks: blockArray})
    
    subLists.forEach(sl => {
      makeTree(sl.parent, sl.blocks)
    })
    }
  } 
  makeTree('root', flatList)

  return finalList
}


