import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

const data = {
  id: 'root',
  name: 'Parent',
  children: [
    {
      id: '1',
      name: 'Child - 1',
    },
    {
      id: '3',
      name: 'Child - 3',
      children: [
        {
          id: '4',
          name: 'Child - 4',
        },
      ],
    },
  ],
};

const useStyles = makeStyles({
  root: {
    height: '100%',
    flexGrow: 1,
    maxWidth: 400,
  },
});

export default function RenderOutline(props) {
  const classes = useStyles();
const {tree, headerBlocks} = props.data
const getBlockText = (key) => headerBlocks.find(block => block.key===key)?.text

debugger;
if (!data) return null;
if (!tree && tree.hasOwnProperty('root')) return null
// const keys = Object.keys(tree)
// if (keys.length===0) return null;

const renderNode = (key) => {
  const items = tree[key] 
  return (
    <TreeItem  nodeId={key} key={key} label={getBlockText(key)}>
    {items?.map(item=> (renderNode(item.key)))}
  </TreeItem>
    )
}

// let items
//   const renderTreeNode = (key) => keys.map( key => {
//      items = Object.keys(data[key])
//     if (items instanceof Set) items = Array.from(items)
//     return  (
//       <TreeItem key={key} nodeId={data[key]} label={blocks[key].text}> 
//           {items?.map(val => (<TreeItem nodeId={val} label={val}/>))}
//       </TreeItem>
//   );
//     });

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['root']}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderNode('root')}
    </TreeView>
  );
}