import React, {Fragment}  from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import classes from 'components/Edit/Editor.module.css'
import Carousel from 'components/Review/Carousel'
import {DeckEditor} from '../Edit/DeckEditor';
import {ReviewEditor} from '../Review/ReviewEditor';
import {Grid, IconButton, List, ListItem,
   ListItemText, Divider, Hidden} from '@material-ui/core'

import TopNav from 'components/Nav/TopNav';
import CardTitles from 'components/Deck/CardTitleBar';
import SideDrawer from 'components/Nav/SideDrawer';
import {MyEditor, EditToolbar, populateEditor} from 'components/Edit/Editor/';
// import {Layout} from 'components/Edit/DeckLayout'
// function useToolState() {
//   const [editorState, setEditorState] = useState(EditorState.createEmpty())
//   const ref = useCallback(node => {
//     if (node != null) {
//       setEditorState(node.editorState)
//     }
//   }, []);
//   return [editorState, ref]
// }

//<div> <pre> {JSON.stringify(cardState, null, 2)}</pre></div>
// <div className={classes.DeckNames}>
//   {props.deckNames}
// </div>
const Explorer = () => {
  const decks = useSelector(state => state.myDecks)
  const history = useHistory()
  return (

    <List>
          {decks.slice(0, 100).map( (item) =>
          (<Fragment key={item.id} >
              <ListItem button dense
                onClick={()=> {history.push(`/deck/${item.id}`)}} 
                selected={item.selected}
                >
                  <ListItemText primary={item.name}
                    secondary={item.cards.length}
                    primaryTypographyProps={{
                      color: item.selected? 'primary': undefined }}
                      />
              </ListItem>
            <Divider/>
            </Fragment>
          )
          )}
      </List>
          )
  

}
export const DeckContentUI = (props) => {
  let {cards,deck, updateDeck, viewMode} = props
  let appViewMode = useSelector(state => state.appState.viewMode[deck.id])
  viewMode = viewMode || appViewMode  || 'doc'
  //let filtCards = cards.filter(card => card.text)

// const TOC = React.memo(()=> <CardTitles   deck={deck} items={cards} />)

      // <Hidden smDown>
      // <SideDrawer/>
      // </Hidden>
const TOC = () => <Explorer/>
  return(
    <React.Fragment >
      <TopNav/>
      {
        (viewMode==='doc') &&  <DeckEditor toc={TOC} {...props}/>
      }
      {
        (viewMode=='read') && <ReviewEditor {...props} toc={TOC} deck={deck} items={cards}/>
      }
    </React.Fragment>

      )

}
// {
//   (viewMode=='card') && <CardViewEditor toc={TOC} {...props} />
// }
// <Layout
    //   toolbar={<ShowToolbar/>}
    //   toc={<TOC/>}
    //   editor={<ShowEditor toolbar={<ShowToolbar/>}/>}
    //   />
