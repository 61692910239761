import React, {useState, useRef, useEffect} from 'react';


import Carousel from './Carousel';
// import CarouselSlide from 'components/UI/CarouselSlide';
import {MyEditor, EditToolbar, populateEditor} from 'components/Edit/Editor/';
import {deckActions} from 'redux/actions'
import {Layout} from 'components/Edit/DeckLayout'
import SideDrawer from 'components/Deck/CardTitleBar';
import { useDispatch } from 'react-redux';
import {ReviewControlPanel} from './ReviewControls';
import { AlternateEmail, Refresh } from '@material-ui/icons';
import { IconButton } from 'components/UI';

import {updateCardReview, addToReviews} from './reviewUtil'

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}



export const ReviewEditor = (props) => {
  const {deck} = props

  debugger;
  const dispatch = useDispatch()
  const [reviewId, setReviewId] = useState('first')
  const [revItems, setRevItems] = useState(null)
  const [contentType, setType] = useState(null);
  
  const [index, setIndex] = useState(0)
  // const [currReview, setCurrReview] = useState(defReview)
  const [saveState, setSave] = useState(false)
  const [progressValue, setProgress] = useState(0)
  
  console.log('reviewId now changing to ', reviewId)
  const [reviewInfo, setInfo] = useState(
    {next_review: deck.next_review, 
      card_review_count: deck.card_review_count, 
      review_history: deck.review_history})
      
      useEffect( ()=>{
   
    let allItems = props.items
    let allFlashes = allItems?.map(card => card.flashes).flat()
    allItems = allItems?.concat(allFlashes)     
        let nextReviews = Object.keys(props.deck.next_review)
        let defReview
        if ((nextReviews.length === 0) & (props.items?.length > 0) ) {
          debugger;
          let newReview = addToReviews(allItems, props.deck.next_review)     
          nextReviews = Object.keys(newReview)    
          setInfo({...reviewInfo, next_review: newReview})
        }
        defReview = nextReviews.sort((a, b) => a-b)[0]
        debugger
        console.log('setting review id', defReview)
        setReviewId(defReview)
      },[props.items, reviewInfo.next_review])
      
useEffect( ()=> {
let revSet = reviewInfo.next_review[reviewId]
debugger;
let revKeys = []
if (revSet?.hasOwnProperty('cards')) revKeys = revSet.cards
if (revSet?.hasOwnProperty('done')) {
    revKeys = revKeys.concat(revSet.done)
    setIndex(revSet.done.length)
  }
  let allItems = props.items
  let allFlashes = allItems?.map(card => card.flashes).flat()
  allItems = allItems?.concat(allFlashes)

  setRevItems(allItems?.filter(item => revKeys.includes(item.key)))
}, [reviewId, props.items, reviewInfo.next_review])

debugger

useEffect( ()=>{
  const finalReviewCheck = () => {
    console.log('exiting reviewId', reviewId)
      let revSet = reviewInfo.next_review[reviewId]
      if (revSet) {
        if (revSet.hasOwnProperty('cards') && revSet.cards.length===0) {
            let newReviews = {...reviewInfo.next_review}
            delete newReviews[reviewId]
          
        dispatch(deckActions.updateDeck({...deck,card_review_count: reviewInfo.card_review_count, 
          review_history: reviewInfo.review_history, next_review: newReviews}))  
        }
      }
    }
    
  return () => finalReviewCheck(); 
}, [reviewId, reviewInfo.next_review])

useInterval( ()=> {checkSaveReviews()}, 10000)

// const [deck, setDeck] = useState()
const checkSaveReviews = () => {
  if (saveState){
    saveReviews()
    setSave(false)
  }

}

const saveReviews = () => {

    dispatch(deckActions.updateDeck({...deck, card_review_count: reviewInfo.card_review_count, 
      review_history: reviewInfo.review_history,  next_review: reviewInfo.next_review}))
   }
const handlePageClick = (index) => {

  setIndex(index)
}

const markReview = (key) => {
    // const item = revItems[itemIndex]
    debugger;
    if (reviewId) {
      let currSet = reviewInfo.next_review[reviewId]
      if (!currSet.hasOwnProperty('done')) currSet = {...currSet, done: []}
      if (!currSet?.done.includes(key)) {
        let newReviewInfo = updateCardReview(key, reviewInfo) 
        setInfo(newReviewInfo)
        setProgress(Math.ceil(currSet.done.length/revItems.length * 100))
        setSave(true)
      }

    }

    console.log('reviewed', reviewInfo)
  }

  const filterContentType = (filter) => {
    if (filter.content) setType('content')
    if (filter.flashes) setType('flashes')
  }
  const selectReviews = (reviewId) => {
    debugger
    console.log('setting review id', reviewId)
    setReviewId(reviewId)
    
  }
  function toolbarCallback(type, data) {    
    switch(type) {
      case 'contentType':
        // let contentType = data;
        filterContentType(data)
        break
      case 'reviewId':
        selectReviews(data)
        break;
      case 'pageClick':
        handlePageClick(data);
        break;
      
        default: 


    }
  }

  const refreshClick = () => {

  }
  const skipNextClick = () => {
    debugger
    let sortedReviews = props.deck.next_review
    if (sortedReviews) {
      sortedReviews = Object.keys(sortedReviews).sort((a, b) => a-b)
      let index = sortedReviews.indexOf(reviewId)
      if (index+1 < sortedReviews.length) {
        setReviewId(sortedReviews[index+1])
      }
    }
  }


const TOC = props.toc

const editorAux = (<ReviewControlPanel tbCallback={toolbarCallback} 
  reviewId={reviewId}
  deck={props.deck} 
  currentReview={reviewId}
  itemCount={revItems?.length}
  progressValue={progressValue}/>

)

const toolbar =   <EditToolbar {...props} tbCallback={toolbarCallback} deck={props.deck}/>

  return (
    <Layout
      review={true}
      toolbar={toolbar}
      toc={<TOC/>}
      editorAux={editorAux}
      editWidth={8}
      >
        <Carousel items={revItems} contentType={contentType} 
    currentIndex={index} 
    skipNextClick={skipNextClick} onNextClick={markReview}/>
 
        </Layout>)

}
