//import React from 'react';
import {nanoid} from 'nanoid';
import Immutable from 'immutable';
import { EditorState, ContentState, SelectionState,
  ContentBlock, Modifier,
  convertFromRaw, convertToRaw, genKey} from 'draft-js';

export function editorBasics(editorState) {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent()
  const blockKey = selection.getStartKey()
  return {contentState, selection, blockKey}
}

export function getSelectedText(editorState) {
  const {contentState, selection, blockKey} = editorBasics(editorState)
  const startKey = selection.getStartKey()
  const endKey = selection.getEndKey()
  const startOffset = selection.getStartOffset()
  const endOffset = selection.getEndOffset()
  let selectedText 
  let contentBlock
  if (startKey === endKey) {
    contentBlock = contentState.getBlockForKey(startKey) 
    selectedText = contentBlock.getText().slice(startOffset, endOffset)
  }
  else {
    contentBlock = contentState.getBlockForKey(startKey) 
    selectedText = contentBlock.getText().slice(startOffset)
    contentBlock = contentState.getBlockForKey(endKey) 
    selectedText += contentBlock.getText().slice(0, endOffset)
  }
  return selectedText

}

export const getCursorCardPosition = (editorState, cardState) => {
  const {contentState, selection, blockKey} = editorBasics(editorState)
  const blockBefore = contentState.getKeyBefore(blockKey)
  const currcard = cardState.blockDict[blockKey]
  const newCard = cardState.blockDict[blockBefore]
  let startPos = 5
  if (newCard && currcard && (newCard !== currcard)) {
    startPos = selection.getStartOffset()
  }
  console.log('cursorposition', blockKey, startPos)
  return startPos
}


export const removeContentBlock = (contentState, blockKey) => {
  let blockMap = contentState.getBlockMap()
  let blocksBefore = blockMap.takeUntil((v, k) => k===blockKey);
  let blocksAfter = blockMap.skipUntil((v,k) => k===blockKey).rest();
  blockMap = blocksBefore.concat(blocksAfter).toOrderedMap();
  contentState = contentState.merge({blockMap: blockMap})
  return contentState
}

export function selectBlockForKey(editorState, blockKey) {
  const selection = SelectionState.createEmpty(blockKey)
  editorState = EditorState.forceSelection(editorState, selection)
  return editorState
}
export const scrollToBlock = (key) => {
  let elem = document.querySelector("[data-offset-key='"+key+"-0-0']")
  const offset = elem.offsetTop
  elem = document.querySelector("div.public-DraftEditor-content")
  elem.scrollTop = offset-100 ;
}

export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
// export const getCardText = (cardId, cardDict, content) => {
//   console.log(content, cardId, cardDict)
//   return {blocks: content.blocks.filter(block => {
//     if (cardDict[cardId].blocks.includes(block.key)) {
//       console.log('plainText', block.text.split('\n'))
//       block.type = block.type==='sepBlock' ? 'unstyled': block.type
//             return block;
//       }

//     }
//     )}
//     }
export const splitBlock = (contentState, blockKey, offset) => {
    let selection  = SelectionState.createEmpty(blockKey)
    selection = selection.set('anchorOffset', offset)
    selection = selection.set('focusOffset', offset)
    contentState  = Modifier.splitBlock(contentState, selection)
    return contentState
}

export const splitLargeBlocks = (contentState, blocks) => {
  const maxBlockLen= 500, minBlockLen = 200
  let rawContent = convertToRaw(contentState)
  const firstKey = blocks? blocks[0].key : contentState.getFirstBlock().key
  let block = contentState.getBlockForKey(firstKey)
  while (block) {
    if (block.text.length > maxBlockLen ) {
      const offset = block.text.indexOf('\n', minBlockLen)+1
      if ((offset > 0) && (block.text.length - offset > minBlockLen )) {
        console.log('split: block key Length offset', block.key, block.text.length, offset)
        contentState = splitBlock(contentState, block.key, offset)
        }
    }
    block = contentState.getBlockAfter(block.key)
  }
  return contentState
}

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const getContentStateFromCardBlocks = (content) => {
  let currentContent, contentBlocks=[]
  
  if (content){
    //let content = JSON.parse(this.props.content)&&
    if (content.hasOwnProperty('blocks')){
      if (!content.hasOwnProperty('entityMap')) {
        content = {...content, entityMap:{}}
      }
      currentContent = convertFromRaw(content)
    }
    else if( content.hasOwnProperty('newContent')){
      let text = content.newContent || ''
      debugger
      if (text) text  = text.join(' ')
      currentContent = ContentState.createFromText(text);
    }
    else {
       currentContent = ContentState.createFromText(content);
    }
  } else {
     contentBlocks.push(getNewContentBlock('newBlock'))
    currentContent = ContentState.createFromBlockArray(contentBlocks);
  }
  return currentContent

}
export const populateEditor = (content) => {
  let currentContent = getContentStateFromCardBlocks(content)
  return EditorState.createWithContent(currentContent)
}

export const getNewContentBlock = (type) => new ContentBlock({
      key: genKey(),
      type: type,
      text:"",
      characterList: Immutable.List()
    })

export const getNewBlock = (type) => ({
      key: genKey(),
      type: type,
      text:"",
    })

export const insertBlock = (fragment, editorState) => {
  const contentState = editorState.getCurrentContent()
  const targetRange = editorState.getSelection()
  const newContent = Modifier.replaceWithFragment(
    contentState,
    targetRange,
    fragment
  );
}

export const getPlainText = (content) => {
  let text = ''
   content.blocks.map(block => { text = text+block.text})
   console.log('plaintext', text)

   return text
 }

 export const inserNewBlockToContentState = (contentState) => {
    const blockMap = contentState.getBlockMap();
    const newBlock =  new ContentBlock({
        key: genKey(),
        text: '',
        type: 'unstyled',
    });
    const newBlockMap = blockMap.toSeq().concat([[newBlock.getKey(), newBlock]]).toOrderedMap();
    return contentState.merge({
        blockMap: newBlockMap
    })
    //return
}
