
import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export default function CheckBoxList(props) {
  const classes = useStyles();
  const [state, setState] = useState({})

  const items = props.items
  if (!Array.isArray(items)) return null
  const names = items.map( item => item.name)
  const labels = items.map( item => item.labels)
  const initState = items.map(item => item.checked)


  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    console.log('checkbox', items, state)
  };
  //
  const onChange  = (event) => {
    props.onChange(event.target.name, event.target.checked)
  }


  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend"> {props.formLabel} </FormLabel>
        <FormGroup>
        {
          items.map(item =>  (<FormControlLabel classes={{label: props.classes}}
                      control={ <Checkbox checked={item.state}
                      onChange={handleChange}
                      name={item.name} />
                    }
                      label={item.label}
                    />)
                  )
        }
        </FormGroup>
        <FormHelperText>{props.helper}</FormHelperText>
      </FormControl>
    </div>
  );
}
