import React, {useState, useEffect} from 'react'
import CarouselSlide from './CarouselSlide';
import {MyEditor, EditToolbar, populateEditor} from 'components/Edit/Editor/';
// import classes from './Carousel.module.css'
// import RefreshIcon from '@material-ui/icons/Refresh';
import { ArrowLeft, ArrowRight, PinDropSharp, Refresh, SkipNext } from '@material-ui/icons';
import {Slide, Box, IconButton} from '@material-ui/core';
import FlipCard from 'components/UI/FlipCard';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'assets/jss/DefTheme';


const useStyles = makeStyles((theme) => ({
    Carousel: {
        backgroundColor: '#eee',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        height: '100%',
        minHeight: '100px',
      },
      
      Slide: {
        width: '100%',
        height: '100%',
      },
      
      largeIcon: {
          fontSize: '4rem',
          width: '1em',
          height: '2em',
          display: 'flex',
          alignItems: 'center',
      backgroundColor: theme.palette.primary.dark,
      },
      
}))

const ReviewCard = props =>  {
    const {card} = props
    let content = card?.text ? JSON.parse(card.text) : null
    let editor
    let flash
    if (card?.hasOwnProperty('text')) editor=true;
    if (card?.hasOwnProperty('qtext')) flash=true;
    return ( <CarouselSlide>
      
      {editor && <MyEditor readOnly editorState={populateEditor(content)} />}
      {flash && <FlipCard style={{height: '800px', width: '100%'}} front={card.qtext} back={card.answer} />}
      {props.last &&   <React.Fragment>
          <IconButton onClick={props.refreshClick}><Refresh/> </IconButton> 
            <IconButton onClick={props.skipNextClick}> <SkipNext/> </IconButton>
      </React.Fragment> 
      }
      </CarouselSlide>
    )
}

const RefreshCard = (props) => (
  <CarouselSlide>
   <IconButton onClick={props.refreshClick}><Refresh/> </IconButton>
  </CarouselSlide>
)


function Arrow({classes, ...props}) {
    const { direction, disabled,  clickFunction } = props;
    const icon = direction === 'left' ? <ArrowLeft  fontSize="large"/> : <ArrowRight disabled={disabled} fontSize="large"/>;

    return <div className={classes.largeIcon} onClick={clickFunction}>
    <IconButton disabled={disabled} >
    {icon}
    </IconButton>
    </div>;
}

const Carousel = React.memo(( props) => {
    const classes = useStyles()
    const {items, contentType} = props
    console.log('from carousel', items)
    const [index, setIndex] = useState(props.currentIndex);
    console.log('index', index)
    const [slideIn, setSlideIn] = useState(true);
    const [slideDirection, setSlideDirection] = useState('down');
    useEffect(() => {
                const handleKeyDown = (e) => {
                    if (e.keyCode === 39) {
                        onArrowClick('right');
                    }
                    if (e.keyCode === 37) {
                        onArrowClick('left');
                    }
                };
                window.addEventListener('keydown', handleKeyDown);
                return () => {
                    window.removeEventListener('keydown', handleKeyDown);
                    console.log('unmounting')
                };
            });

    const refreshClick = () => {
        setIndex(0)
    }
    useEffect( ()=>{
        setIndex(props.currentIndex)
    }, [props.currentIndex])
    if (!items || items.length==0) return null;
    // const card = items ? items[index]: null;
    // let content = card?.text ? JSON.parse(card.text) : card.text
    const numSlides = items.length;

    const onArrowClick = (direction) => {
        const increment = direction === 'left' ? -1 : 1;
        // const newIndex = (index + increment + numSlides) % numSlides;
        let  newIndex = index + increment;
        newIndex = Math.max(0, Math.min(newIndex, numSlides))
debugger;
        if (newIndex !== index) {

            
            const oppDirection = direction === 'left' ? 'right' : 'left';
            setSlideDirection(direction);
            setSlideIn(false);
            
            setTimeout(() => {
                setIndex(newIndex);
                setSlideDirection(oppDirection);
                setSlideIn(true);
            }, 300);
            (direction === 'right')  && props.onNextClick(items[index].key)
        } 
    };

                    // <CarouselSlide content={content} />
    const fullhw  = {
      width: '100%',
      height: '100%'
    }
    return (
        <div className={classes.Carousel}>
            <Arrow classes={classes}
                direction='left' fontSize="large" disabled={index<=0}
                clickFunction={() => onArrowClick('left')}
            />
          <Slide in={slideIn} direction={slideDirection}>
                <div className={classes.Slide}>
                    <ReviewCard card={items[index]} last={index===numSlides} refreshClick={refreshClick} {...props}/>
                    {index}
                </div>
            </Slide>
            <Arrow
                direction='right' classes={classes} disabled={(index>= items.length-1)}
                clickFunction={() => onArrowClick('right')}
            />
        </div>
    );
}
)

export default Carousel;
