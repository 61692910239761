import React, { useEffect } from 'react';
import {Switch, Route, withRouter, Link} from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import {deckActions, authActions, appStateActions} from '../redux/actions';
import TopNav from '../components/Nav/TopNav';
import HomePage from '../components/Home/HomePage';
import DeckList from '../components/Deck/DeckList';
import CardsReview from '../components/Deck/CardsReview';
import DeckContent from '../components/Deck/DeckContent';
import HubExplore from 'components/Hub/Explore';
import HubDeckDetails from 'components/Hub/HubDeckDetails'
import { hubDeckDetails } from 'redux/reducers/deckhubReducer';
import SimpleSnackbar from 'components/UI/SnackBar';

export  default function MainContainer(props){

  const snack = useSelector(state => state.appState.snackBar)
  const {auth} = useSelector(state => state)
  const decks = useSelector(state => [...state.myDecks] )
  console.log('rerendering main container')
  const dispatch = useDispatch()
  useEffect(
    ()=>{
      console.log('refreshing main continaer again')
      dispatch(authActions.loadUser());
      dispatch(appStateActions.getImageList('regular'));
    }, 
    []
  )
  
  // <TopNav isAuthenticated={isAuth} user={user}/>
  return (
    <React.Fragment>
    <SimpleSnackbar msg={snack.msg} status={snack.status} />
    <Switch>
    <Route path="/decks" render={(props) => <DeckList decks={decks} {...props}/> }/>
    <Route path="/hub-explore" render={props => <HubExplore {...props} />} />
    <Route path="/deck-hub/:deckId" render={props => <HubDeckDetails {...props} deckId={props.match.params.deckId} />} />
    <Route path="/deck/:deckId" render={(props) => <DeckContent
        {...props} deckId={props.match.params.deckId}/>} />
      <Route path="/newDeck" render={(props)=> <DeckContent {...props} deckId={0} />} />
      <Route path="/review/:deckId"
        render={(props) => <DeckContent {...props} viewMode="read"
        deckId={props.match.params.deckId} />
      } />
    <Route path='/' component={()=> <HomePage decks={decks}/>}/>
    </Switch>
    </React.Fragment>
  );
}


