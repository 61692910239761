import React from 'react';
import {SvgIcon, Icon} from '@material-ui/core'


import { makeStyles } from '@material-ui/styles';
import {ReactComponent as Logo} from 'brainTree.svg';

const useStyles = makeStyles({
    imageIcon: {
      height: '100%'
    },
    iconRoot: {
      textAlign: 'center'
    }
  });

export default function NuroIcon(props) {
const classes = useStyles()

return(
    <Icon>
        <Logo/>
    </Icon>
)
// return (
// <Icon classes={{root: classes.iconRoot}}>
//   <img className={classes.imageIcon} src="/brainTree.png"/>
// </Icon>
// )

// return (
// <SvgIcon {...props}  
// viewBox="0.0 0.0 960.0 720.0" fill="none" stroke="none" stroke-linecap="square" stroke-miterlimit="10" >
//     <clipPath id="p.0">
// <path d="m0 0l960.0 0l0 720.0l-960.0 0l0 -720.0z" clip-rule="nonzero"/></clipPath><g clip-path="url(#p.0)">
// <path fill="#000000" fill-opacity="0.0" d="m0 0l960.0 0l0 720.0l-960.0 0z" fill-rule="evenodd"/>
// <path fill="#000000" fill-opacity="0.0" d="m16.0 16.0l815.0945 0l0 688.9554l-815.0945 0z" fill-rule="evenodd"/>
// <g transform="matrix(4.245283727034121 0.0 0.0 4.245328544988283 16.0 -47.03941863299594)">
// <clipPath id="p.1"><path d="m-4.440892E-16 14.849126l192.0 0l0 162.28552l-192.0 0z" clip-rule="evenodd"/></clipPath>
// </g></g>

// </SvgIcon>
// );
}
