export const newNoteStyle = theme =>  ({
  containerOuter: {
    position: "absolute",
    width: "600px",
    height: "200px",
    bottom: "10px",
    left: "30vw",
    boxShadow: "5px tomato",
    borderRadius: "100px",
    opacity: 0.9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  leftSemi: {
    height: "100%",
    width: "25%",
    borderRadius: "125px 0px 0px 125px",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 1
  },
  rightSemi: {
    height: "100%",
    width: "25%",
    borderRadius: "0px 125px 125px 0px",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
//   largeIcon: { backgroundColor: "black", 
//                     border: "1px solid blue" },
  iconRoot: { 
      transform: "scale(1.9)", 
    color: theme.palette.secondary.main },
  CardEditor: { height: "100%" },
  NewNote: {
    padding: "5px",
    overflow: "hidden",
    display: "flex",
    flexFlow: "column",
    height: "95%",
    width: "75%",
    border: "1px solid yellow",
    backgroundColor: "#dcdcdc"
  },
  captureDiv: {
    position: "absolute",
    width: "360px",
    height: "60px",
    borderRadius: "30px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    top: "80vh",
    left: "40vw",
    backgroundColor: "lightgray"
  },
  buttonCapture: {
    height: "100%",
    width: "100%",
    border: "2px solid lightblue",
    display: "flex",
    justifyContent: "flex-start",
  "$ > *": { margin: "0px 25px", fontSize: "large" },
  },
  captureIcon: { height: "40px", width: "40px" },
//   NewNoteMax: {
//     position: "absolute",
//     width: "50vw",
//     height: "50vh",
//     top: "40vh",
//     left: "25vw"
//   },
  SaveBar: {
    width: "100%",
    display: "flex",
    padding: "5px",
    height: "30px",
    justifyContent: "flex-end",
    alignContent: "center"
  },
  ChooseDeck: {
    padding: "0px 5px 0px 35px",
    backgroundColor: "ivory",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    fontSize: "0.8em",
    width: "50%"
  }
}
)