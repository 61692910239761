import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles';

// import classes from './App.module.css';


import MainContainer from './MainContainer';
import {theme} from '../assets/jss/DefTheme';

function App() {
  console.log('rerendering App', Date.now())
  return (

    <BrowserRouter>
      <ThemeProvider theme={theme}>
      <MainContainer/>
      </ThemeProvider>
      </BrowserRouter>

  );
}


export default App;
