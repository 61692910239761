export const toolbarStyle = (theme) => ({
  toolRoot: {
    height: '30px',
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'snow',
    // justifyContent: 'space-around',
    boxShadow: '0px 2px 2px 2px lightgray',
    width: '100%',
    '& > *' : {
      height: '100%',
      margin: '0px 1px 0px 1px',
    },
    marginBottom: '2px',
    // marginLeft: '-30px'
  },
  section: {

  },
  splitToolbar: {
    display: 'flex',
  },
  reviewToolbar: {
    backgroundColor: 'yellow',
    display: 'flex',
    
    // marginLeft: 'auto',
    '& > *': {
     marginRight: '15px',
     }
  },

  viewMode: {
    marginLeft: 'auto',
    marginRight: '0px',
      backgroundColor: 'lightred',
  },
 
  toolbar: {
    padding: '0px 5px 0px 5px',
    display: 'flex',
    justifyContent: 'flex-start',
    background: 'white',
    marginBottom: '5px',
    height: '100%',
    zIndex: theme.zIndex.drawer + 1,
  },

})
