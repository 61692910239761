import * as actionType from '../actionTypes'
let deck = {}, cardId;
export const noteCards = (state=[], action) => {
  switch(action.type){
    case actionType.FETCH_CARDS:
      return {...state, [action.deckId]: action.cards};
    case actionType.ADD_NOTECARD:
      deck = state[action.deckId]
      action.cards.forEach(card => deck = deck?.concat(card))

      return {...state, [action.deckId]: deck};
    case actionType.UPDATE_NOTECARD:
      debugger;
      deck = state[action.deckId].slice()
      deck = updateCards(action.cards, deck)
      return {...state, [action.deckId]:[...deck]};
    case actionType.DELETE_NOTECARD:
      console.log('deleted cards', action.cards)
      deck = state[action.deckId].slice()
      deck = deck.filter( card => !action.cards.includes(card.id))
      return {...state, [action.deckId]:[...deck]};
    case actionType.UPDATE_QUESTION:
      console.log('updating question in notecards')
      deck = state[action.deckId].slice()
      deck = updateCardQuestion(deck, action.question, false)
      return {...state, [action.deckId]:[...deck]}
    case actionType.DELETE_QUESTION:
      deck = state[action.deckId].slice()
      deck = updateCardQuestion(deck, action.question, true)
      return {...state, [action.deckId]:[...deck]}
    default:
      return state;
  }
}


const updateCardQuestion = (deck, question, deleteFlag) => {
  const qid = question.id
  const index = deck.findIndex(card => card.id === question.card)
  const card = deck[index]
  const qlist = card.flashes || []
  const qIndex = card.flashes.findIndex(q => q.id === question.id)
  if (deleteFlag) {
    qlist.splice(qIndex, 1)
  } else {

  if (qIndex > -1)   qlist.splice(qIndex, 1, question)
  if (qIndex === -1) qlist.push(question)
  }

  card.questions = [...qlist]
  deck.splice(index, 1, {...card})
  return deck;
}



const updateCards = (cards, deck) => {
  let cardId, index, card;
  cards.forEach(cardU => {
    cardId = cardU.id;
    index = deck.findIndex(card => card.id === cardId);
    card = deck[index]
    card.title = cardU.title
    card.text = cardU.text
    card.questions = cardU.questions
    deck.splice(index, 1, card)
    }
  )
return deck
}
