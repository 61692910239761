
import axios from 'axios';
import * as actionType from '../actionTypes';
import * as deckActions from './deckActions';


export const login = (data) => {
  return dispatch => {
      delete axios.defaults.headers.common['Authorization']
    axios.post('/login', data)
    .then(res => {
            console.log('back from login', res)
            return dispatch({
              type: 'LOGIN_SUCCESSFUL',
              data: res.data
            })
          }
        )
  .then(
    () => {
      console.log('logged in fetching decks now');
      dispatch(loadUser());
    }
  )
  .catch(err => {
    console.log('login error', err)
    console.log('error status', err.response)
    if (err.response){
         if (err.response.status===401){
          return dispatch({
          type: 'LOGIN_FAILED',
          data: 'Invalid email or password',
        })
      }
    } else {
      console.log('network error', err)
      dispatch(logout())
      alert('Server is not responding')
    }
   })
}
}

export const logout = () => {
  console.log('logging out')
  delete axios.defaults.headers.common['Authorization']
  return dispatch => {
    dispatch({
    type: 'LOGOUT_SUCCESSFUL'
  });
  }
}

export const signup = (data) => {
  return dispatch => {
      delete axios.defaults.headers.common['Authorization']
    axios.post('/signup', data)
    .then(res => {
    console.log('back from signup', res)
    const status = res.status
    if (status>=200 && status<=300) {
      dispatch(login(data))
      return dispatch({
        type: 'SIGNUP_SUCCESSFUL',
        data: res.data
      })
    }
    console.log('sigup error', res)
    return res
  })
  .catch((response) => {
    console.log('error response', response);
    console.log(response.status)
    if (response.status===400) {
      if (response.data.hasOwnProperty('email')){
        let emailErr = response.data.email[0]
        return dispatch({
          type: 'SIGNUP_FAILED',
          data: emailErr
        })
      }
    }
    return response;
  })
  }
}

export const loadUser = () => {
  return dispatch => {
    axios.get('/user')
    .then(res => {
      console.log('user retrieved', res.data)
      return dispatch({
        type: 'USER_LOADED',
        data: res.data
      })
    })
    .then (() => {
      dispatch(deckActions.fetchDecks());
      dispatch(deckActions.fetchHubDecks( ));
    }
  )
  .catch( err => {
        console.log(err.message);
        dispatch(logout());
      }
    )
  }
}

export const updateProfie = (profile) => {
  return dispatch => {
      const data = profile
    console.log('updating profile', data)
    axios.put('profile/'+data.id+'/', data)
    .then(response => {
      console.log('profile updated', response.data)
      dispatch({
        type:actionType.UPDATE_PROFILE,
        data: response.data
      })},
      error => {console.log(error)}
    )

  }
}