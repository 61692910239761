import axios from 'axios';
import * as actionType from '../actionTypes';
import {updateSnackBar} from './appStateActions'

export const fetchDecks = () => {
    return (dispatch, getState) => {
      axios.get('decks/')
    .then(response => {

      console.log('fetch decks response', response.data)
      return dispatch({
        type: actionType.FETCH_DECKS,
        decks: response.data.results
      })
    })
  }
}

export const fetchHubDecks = () => {
  return (dispatch) => {
    axios.get('decks-hub/')
    .then(response => {
      console.log('fetching hub decks', response.data)
      return dispatch({
        type: actionType.FETCH_DECKSHUB,
        decks: response.data.results
      })
    })
  }
}

export const fetchHubDeckDetails = (deckId) => {
  return (dispatch, getState) => {
    const data = {};
    return axios.get('deck-hub/'+deckId+'/',data)
    .then(response => {
      return dispatch({
        type:  actionType.FETCH_HUBDECK_DETAILS,
        cards: response.data.cards,
        deckId: deckId,
      })
    }, error => {console.log('error from  fetch cards', error)}
    )
  }
}

export const downloadHubDeck = (deckId) => {
  return (dispatch, getState) => {
    const data = {};
    return axios.get('deck-clone/'+deckId+'/',data)
    
    .then(response => {
      console.log('added deck', response.data)
      return dispatch({
        type: actionType.ADD_DECK,
        deck: response.data
      })
    }, error => {console.log('error from downloading Hub Deck', error)}
    )
    .then( res => {return dispatch(updateSnackBar('Deck Copy Complete', 'open'))}
    //   {
    //   return dispatch({
    //     type: actionType.UPDATE_SNACKBAR,
    //     msg: 'Deck Copy to Library compolete',
    //     status: 'open',
    //   })
    // }
    )
  }
}

export const publishDeck = (deckId) => {
  return (dispatch, getState) => {
    const data = {};
    return axios.get('deck-publish/'+deckId+'/',data)    
    .then(response => {
      console.log('publish_deck status', response.status)
      if (response.status===200) {
        return dispatch({
          type: actionType.PUBLISH_DONE
        })

      }
    }, error => {console.log('error from downloading Hub Deck', error)}
    )
    .then( res => {return dispatch(updateSnackBar('Deck Published', 'open'))})
  }
}


export const createDeck  = async (deck) => {
  let response = await axios.post('deck-create/',deck)
  debugger
     console.log('after waiting', response.data)
  return response.data
}



export const addDeck = (deck) => {
  return (dispatch) => {
      dispatch(updateSnackBar('Deck Created', 'open'))      
      return dispatch({
        type: actionType.ADD_DECK,
        deck: deck
      })
  }
}

export const deleteDeck = deckId => {
  const data = {};
  return (dispatch) => {
    return axios.delete('deck/'+deckId+'/', data)
    .then(response => {
      console.log('deck delete', response.status, response.data);
      return dispatch({
        type: actionType.DELETE_DECK,
        deckId,
      })
    })
    .then( res => {return dispatch(updateSnackBar('Deck Deleted', 'open'))})
  }
}

export const updateDeck = (deck) => {
  return (dispatch) => {
    const data = deck
    console.log('updating deck', deck)
    axios.put('deck/'+deck.id+'/', data)
    .then(response => {
      console.log('deck updated', response.data)
      dispatch({
        type:actionType.UPDATE_DECK,
        deck: deck
      })},
      error => {console.log(error)}
    )
  }
}
