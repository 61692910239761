
import * as actionType from '../actionTypes'

const initialState = {
  activeDeck: null,
  viewMode: {},
  activeCard: {},
  editPlugins: {},
  splitOptions: new Map(),
  imageList: [],
  snackBar: {}
}

export const appState = (state=initialState, action) => {
  switch (action.type) {
    case actionType.UPDATE_CARDVIEW:
      let viewMode = state.viewMode
      viewMode = {...viewMode, [action.deckId]: action.mode}
      return {...state, viewMode}
    case actionType.UPDATE_ACTIVE_CARD:
      let activeCard = state.activeCard
      activeCard = {...activeCard, [action.deckId]: action.cardKey}
      return {...state, activeCard}
    case actionType.UPDATE_SPLIT_OPTIONS:
      return {...state, splitOptions: action.data}
    case actionType.ADD_DECK:
      return {...state, newDeck: action.deck.id}
    case actionType.FETCH_IMAGE_LIST:
      return {...state, imageList: action.data.results}
    case actionType.UPDATE_ACTIVE_DECK:
      return {...state, activeDeck: action.deckId}
    case actionType.UPDATE_SNACKBAR:
      return {...state, snackBar: {msg: action.msg, status: action.status}}
    default:
      return state
  }
}
