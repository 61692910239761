
import { fade, makeStyles , withStyles} from '@material-ui/core/styles';
export const navStyle = theme => ({
  appbar:{
    backgroundColor: 'yellow',
    position: 'fixed',
    opacity: 0.8,
    width: '100%',
    height: '40px',
    // marginBottom: '40px',
    zIndex: theme.zIndex.drawer + 1,
  },
  container: {
    backgroundColor: 'white',
    padding: '1px 5px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '40px',
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    //paddingTop: "25px",
    color: 'white',
  },

  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  toolbarMargin: {
    minHeight: '40px',
  },

  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  signin:{
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  profileIcon:{
    backgroundColor: 'yellow',
    color: 'white',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    size: 'small',
 
  },
  chipRoot: {
      fontSize: '1.2 rem',
      border: '1px blue solid',
      margin: '1em',
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
      alignItems: 'center',
      justifyContent: 'center',
    },
  chipLabel: {
      fontSize: '1.1rem',
      alignItems: 'center'
    },

    primary: {
        backgroundColor: 'ivory',
      }

})

// boxShadow:
//   "0 4px 20px 0px rgba(" +
//   hexToRgb(blackColor) +
//   ", 0.14), 0 7px 12px -5px rgba(" +
//   hexToRgb(primaryColor[0]) +
//   ", 0.46)"
// info: {
//   backgroundColor: infoColor[0],
//   color: whiteColor,
//   boxShadow:
//     "0 4px 20px 0px rgba(" +
//     hexToRgb(blackColor) +
//     ", 0.14), 0 7px 12px -5px rgba(" +
//     hexToRgb(infoColor[0]) +
//     ", 0.46)"
// },
// success: {
//   backgroundColor: successColor[0],
//   color: whiteColor,
//   boxShadow:
//     "0 4px 20px 0px rgba(" +
//     hexToRgb(blackColor) +
//     ", 0.14), 0 7px 12px -5px rgba(" +
//     hexToRgb(successColor[0]) +
//     ", 0.46)"
// },
