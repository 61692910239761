import React, {useState} from 'react';

import {Profile} from './Profile'
import {
fade, makeStyles, withStyles,
AppBar, Toolbar,Badge, MenuItem, Menu, Chip,
InputBase, Button, Modal, Typography, IconButton,
MenuIcon, AddIcon, SearchIcon, AccountCircle, MailIcon,
ViewStreamIcon, NotificationsIcon, MoreIcon
} from 'assets/jss/material.js'
import { useMenuHook } from 'hooks';

export const ProfileMenu = (props) => {

  const [anchorEl, setAnchorEl] = useState(null)
  const [profileOpen, setProfile] = useState(false)
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
    setProfile(true)
  };
  const handleProfileClose = () => {
    setProfile(false)
    props.onClose()
  }
  // const [profileMenu, profileMenuOpen, profileMenuClose] = useMenuHook()
  
  return (

  <>
      <Menu
        anchorEl={props.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id='primary-search-account-menu'
        keepMounted 
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.open}
        onClose={props.onClose}
      >
        <MenuItem onClick={handleProfileClick}>Settings</MenuItem>
        <MenuItem onClick={props.logout}> Sign Out</MenuItem>
        </Menu>
       { profileOpen && <Profile open={profileOpen}
       onClose={handleProfileClose}
       />
       } 

</>
    )
  }
