export const deckMenuStyle = theme => ({
  container: {
    backgroundColor: 'white',
    opacity: 0.8,
    border: '1px solid black',
    borderRadius: '5px',
    position: 'absolute',
    width: '20vw',
    bottom: '5vh',
    [theme.breakpoints.down('sm')]: {
      width: '80vw',
    },

  },
containerRight: {
  backgroundColor: 'white',
  opacity: 0.8,
  border: '1px solid black',
  borderRadius: '5px',
  position: 'absolute',
  width: '20vw',
  bottom: '5vh',
  right: '5vw',
},

  deckListHeader:{
    padding: '10px',
    margin: '-10px 10px 0px 10px',
    backgroundColor: theme.palette.primary.main,
  },
  deckList: {
    height: '50vh',
    listStyle: 'none',
    margin: 'auto',
    padding: '10px',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
  },
  deckFilter:{
    background: 'azure',
    height: '40px',
    // border: '1px solid lightblue',
    margin: '10px 0px',
    width: '100%',
  },
  chipRoot: {
      fontSize: '1.2 rem',
      border: '1px blue solid',
      margin: '1em',
      backgroundColor: theme.palette.secondary.main,
      color: 'black'
    },
  chipLabel: {
      fontSize: '0.8rem',
      // color: 'white',
    },
    iconLabel: {
      fontSize: '1.2rem',
      // backgroundColor: 
    }

})
