
import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
}


export const AuthForm = (props) => {

  const {signUp, classes} = props
  const [state, setInput] = useState( {
    firstName: '',
    email: '',
    password: '',
    terms: false,
    nameHelper:'',
    emailHelper: '',
    passwordHelper: 'Password must be minimum 8 characters.',
    nameErr: false,
    emailErr: false,
    passwordErr: false,
  })

  useEffect( ()=> {
    if (props.authError) {
      setInput({...state, password:''})
    }
  }, [props.authError])
  const handleChange = event => {
  const { name, value } = event.target;
  setInput({...state, [name]: value});
  }

  const onSubmit = (event) => {
    event.preventDefault()
    const formValid = formCheck()
    if (formValid) props.onSubmit(state)

  }

  const termsControl = <Checkbox name="terms" onChange={handleChange}
          checked={state.terms} color="primary" />

const emailCheck = (email) => {
    const validation = {
      required: true,
      isEmail: true,
    }
    return checkValidity(email, validation )
  }

  const passwordCheck = (password) => {
  const validation = {
    required: true,
    minLength: 6,
  }
  return checkValidity(password, validation)
  }

  const formCheck = () => {

    const newState = {...state}
    const nameValid = checkValidity( state.firstName, {required:true} )
    debugger;
    const emailValid = emailCheck(state.email)
    const passwordValid = passwordCheck(state.password)

    if(signUp && (!nameValid)) {
      newState.nameErr = true;
      newState.nameHelper = 'First name is required';
    }

    if (!emailValid) {
      newState.emailErr = true;
      newState.emailHelper='Please enter a valid email'
    }
    if (!passwordValid) {
      newState.passwordErr = true;
      newState.passwordHelper='Password must be min 8 characters'
    }
    setInput({...newState})
    if (signUp && nameValid && emailValid && passwordValid) return true
    if (emailValid && passwordValid) return true
    return false
  }
return (
          <form  noValidate>
            {signUp && <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={state.firstName}
                onChange={handleChange}
                helperText={state.nameHelper}
                error={state.nameErr}
                inputProps={{maxLength:50}}
              />
            }

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              value={state.email}
              onChange={handleChange}
              label="Email Address"
              name="email"
              autoComplete="email"
              error={state.emailErr}
              helperText={state.emailHelper}
              autoFocus
              inputProps={{maxLength:100}}
            />
            <TextField variant="outlined" margin="normal" required fullWidth
              name="password"
              label="Password"
              type="password"
              value={state.password}
              onChange={handleChange}
              id="password"
              helperText={state.passwordHelper}
              autoComplete="password"
              error={state.passwordErr}
              inputProps={{maxLength:50}}
            />
          {
            signUp &&  <FormControlLabel
                        control={termsControl}
                        label="I agree to terms and conditions"
                      />
          }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSubmit}
            >
              Get Started
            </Button>
          </form>

)

}
