import React from 'react';
import * as PDFJS from "pdfjs-dist/build/pdf";
// import pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

function getPageText(pageNum, PDFDocumentInstance) {
    // Return a Promise that is solved once the text of the page is retrieven
    return new Promise(function (resolve, reject) {
        PDFDocumentInstance.getPage(pageNum).then(function (pdfPage) {
            // The main trick to obtain the text of the PDF page, use the getTextContent method
            pdfPage.getTextContent().then(function (textContent) {
                var textItems = textContent.items;
                var finalString = "";

                // Concatenate the string of the item to the final string
                for (var i = 0; i < textItems.length; i++) {
                    var item = textItems[i];

                    finalString += item.str + " ";
                }

                // Solve promise with the text retrieven from the page
                resolve(finalString);
            });
        });
    });
}


export function getPdfText(pdfUrl){
    let pdf = PDFJS.getDocument(pdfUrl);
    return pdf.promise.then(function(pdf) { // get all pages text
      let maxPages = pdf.numPages;
      let countPromises = []; // collecting all page promises
      for (var j = 1; j <= maxPages; j++) {
        let page = pdf.getPage(j);
  
        let txt = "", style;
        countPromises.push(page.then(function(page) { // add page promise
          var textContent = page.getTextContent();
          return textContent.then(function(text){ // return content 
            // debugger;
            // const sorted = text.items.sort(
            //   (a, b) => b.transform[5] - a.transform[5] || a.transform[4] - b.transform[4]
            // );
              // const toc = sorted.items.map(s => {
              // style = text.styles[s.fontName]
              // return s.str+'-'+s.transform.toString()+'-'+style
              // if (s.str.match(/^\d+.?\d?\s+\w/)) return s.str+'-'+s.transform.toString()+'-'+style
              // }).join(' '); // value page text 

              const strText = text.items.map( s => s.str+'-'+s.transform.toString()+'-'+style+'\n').join(' ')
              return text
            // return text.items.map(s => {return s.str+'----'+s.transform.toString()}).join(' '); // value page text 
          });

        }));
      }
      // Wait for all pages and join text
      
      return Promise.all(countPromises).then(function (texts) {
        // let innerHtml = ''
        return texts      
        // return texts.map((txt, i) => txt + "<br><br>Page " + (i + 1) + " contents <br> <br>")
      });
    });
  }
  