import React , {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Grid, makeStyles} from '@material-ui/core'
import {connect} from 'react-redux';

import {deckActions} from '../../redux/actions'

import {deckListStyle} from './deckListStyle'

import DeckCard from './DeckCard';
import TopNav from 'components/Nav/TopNav';

const useStyles = makeStyles(deckListStyle)
const DeckList = (props) => {
const classes = useStyles()
// let  {decks} = useSelector(state => state.myDecks)
const [deckList, setDecks] = useState(props.decks)
// const decks = props.decks
useEffect( ()=> {
  // setDecks(props.decks);
debugger;
setDecks(props.decks.sort((a, b) => (a.last_updated < b.last_updated) ? 1: -1 ))} , 
[props.decks])

return(
  <React.Fragment>
  <TopNav/>
  <div className={classes.listHeader}>
        
  </div>
      <div className={classes.container}>

    { deckList.map( (deck) => (
      <DeckCard deck={deck} key={deck.id} />
     ))
   }
  </div>
  </React.Fragment>
)
  }
export default DeckList;
