import PDFViewer from 'components/UI/PDFViewer2';
import React, {useRef, useCallback, useEffect, useState} from 'react';
import { getPdfText } from '../../utils/pdfExtract';
export default function DocView(props){
const {url} = props
const [textArr, setText] = useState([])
useEffect(
    ()=> {
        if (url) {

            getPdfText(props.url).then(function (txt) {
                setText(txt)
                console.log('pdf text', txt)
            }, 
            function (reason) {
                console.error(reason);
            });
        }
    },

    [props.url]
)
// {url &&  <PDFViewer url={url}></PDFViewer>}

return(
    <div style={{height: '80vh', width: '80vw', marginLeft: '40px',
     overflowY: 'auto', backgroundColor: 'white'}}>
              {url && textArr.map(text => <div> {text} </div>)}
    </div>
)
}