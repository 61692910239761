
import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
   Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
  getDefaultKeyBinding,
} from 'draft-js';
//import {Editor} from 'draft-js-plugins-editor'
import {
  changeDepth,
  handleNewLine,
  blockRenderMap,
  getCustomStyleMap,
  extractInlineStyle,
  getSelectedBlocksType,
} from 'draftjs-utils';
import {addImage, Toolbar} from './index'

import { myKeyBindingFn, myBlockStyleFn,
  //blockRenderMap,
  blockRendererFn,
  customStyleMap } from './editorFunctions'
import 'draft-js/dist/Draft.css'
import  './MyEditor.css'

export default  function PluginEditor(props)  {

 return (

 <Editor
onFocus={props.onFocus}
onBlur={props.onBlur}
readOnly={props.readOnly}
 editorState={props.editorState}
 onChange={props.onChange}
 customStyleMap={customStyleMap}
 blockRenderMap={blockRenderMap}
 blockRendererFn={blockRendererFn}
 blockStyleFn={myBlockStyleFn}
 keyBindingFn={myKeyBindingFn}
 handleKeyCommand={props.handleKeyCommand}
/>
 )
}
