import React from 'react';
import {convertToRaw} from 'draft-js';
import {getPlainText} from 'components/Edit/Editor'
import {getTree} from './treeUtil';

export const getCardText = (cardState, cardKey, content) => {
  return {entityMap: content.entityMap,
        blocks: content.blocks.filter(block => {
          if (cardState.cardDict[cardKey]?.includes(block.key)) {
            if ((block.type !== 'sepBlock')) return block     
      //  if ((block.type !== 'sepBlock') && (!block.type.startsWith('header'))) return block
      }
      })
 }
}

export const getCardsToUpdate = (editorState, cardState) => {
  debugger;
  let cardSeq = cardState.order
  const addCards = [], updateCards = [], deleteCards = [], deleteKeys=[];
  const rawContent = convertToRaw(editorState.getCurrentContent())
  console.log('rawContent', rawContent)
    cardState.updated.forEach((cardKey) => {
      const cardId = cardState.keyId[cardKey]
      const cardText = getCardText(cardState, cardKey, rawContent)
        console.log('onBlur: updateing card' + cardKey, cardText)
        const cardPlainText = getPlainText(cardText)
        if ((cardText.blocks.length===0) || (cardPlainText.length===0)) {
          deleteCards.push(cardId)
          deleteKeys.push(cardKey)
          let delIndex = cardSeq.indexOf(cardKey)
          if (delIndex > -1) {
            cardSeq.splice(delIndex, 1)
          }
        } else {
          const card = {id:cardId, key: cardKey,  title: 'title',
            plainText: cardPlainText,
             text: JSON.stringify(cardText)}
             if (cardState.added.has(cardKey)) {
               console.log('adding card', cardKey)
               addCards.push(card)
             }else {
              updateCards.push(card)
             }
        }
    })

    
  let headerBlocks = Array.from(rawContent.blocks.filter(block => block.type.includes('header') && block.text !== ''))
  
  headerBlocks.forEach(block => {
    let cardKey = cardState.blockDict[block.key]
    let index = cardSeq.indexOf(cardKey)
    cardSeq.splice(index, 0, 'header-'+block.key)
  }
  )
  console.log('headerblocks -saving', headerBlocks)

  const tree = getTree(headerBlocks)
  const outline = {tree, headerBlocks}

  console.log('outline -saving', outline )

  return ({addCards, updateCards, deleteCards, deleteKeys,  cardSeq, outline});

}
