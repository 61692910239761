import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {AuthForm} from './AuthForm';

import {useSelector, useDispatch} from 'react-redux';
import {authActions} from '../../redux/actions'
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://NuroNote.com/">
        NuroNotes
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'fixed',
    top: '40%',
    left: '51%',
    transform: 'translate(-51%, -51%)',
    marginTop: theme.spacing(8),
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '25px',
    border: '1px solid black'

  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "3px",
    padding: "1rem 15px",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "-50px",
    border: "0",
    width: '100%',
    marginBottom: "20px",
    display: 'flex',
    flexDirection:'column',
    alignContent: 'center'
  },

}));

export default function SignIn(props) {

  const classes = useStyles();
  const option = props.signOption
  const [signUp, setSignUp] = useState(props.signUp);
  const isAuth = useSelector(state => state.auth.isAuthenticated)

  const authError = useSelector(state => state.auth.authError)
  const dispatch = useDispatch();
  useEffect( ()=> {
    if (isAuth) props.onClose();
    setSignUp(props.signUp)
  }, [isAuth, props.signUp, props.signIn])
  const toggleSignUp = () => {
    setSignUp(!signUp)
  }

  const submitHandler = (state) => {
    const data = {first_name: state.firstName,
      email: state.email, password: state.password}
    console.log('submit handler', data)
    if (signUp) {dispatch(authActions.signup(data));}
    else {dispatch(authActions.login(data));}
    //props.onClose();
  }

const title = signUp ? 'Sign Up' : 'Sign In'
const header = (
      <div className={classes.cardHeader}>
        <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h5" variant="h6">
            {title}
          </Typography>
        </div>
        )

const signIn = (
  <div className={classes.paper}>

      {header}
      <div className={classes.form}>
        <Typography component="h6" color="error"> {authError} </Typography>
        <AuthForm authError={props.authError} signUp={signUp} onSubmit={submitHandler} classes={classes}/>
          </div>
      <Grid container>
        <Grid item >
          {!signUp && <Link href="#" variant="body2">
            Forgot password?
          </Link>}
        </Grid>
        <Grid item>
          <Link onClick={toggleSignUp} variant="body2">
            {!signUp && <span> Don't have an account? Sign Up</span>}
            {signUp && <span>Already have an account? Sign In</span>}
          </Link>
        </Grid>
      </Grid>
  </div>
)

  return (

    <Modal open={true} onClose={props.onClose}>
      {signIn}
    </Modal>

  );
}
