import React, {useState}  from 'react';
import {Grid, IconButton,  Hidden, Container, Divider} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classes from 'components/Edit/Editor.module.css'
import {DeckNameTags} from 'components/Deck/DeckNameTags';

//<div> <pre> {JSON.stringify(cardState, null, 2)}</pre></div>
// <div className={classes.DeckNames}>
//   {props.deckNames}
// </div>



export const Layout = (props) => {
  const [tocHide, setTocHide] = useState(true)
  const TocIcon = () =>  tocHide? <ChevronRightIcon/> : <ChevronLeftIcon/>
  const editorWidth = props.editWidth ? props.editWidth : 6

const toggleSide = () => {
  setTocHide(!tocHide)}

  const SideBar = () => ( <React.Fragment>
                        <Divider/>
                        <DeckNameTags deck={props.deck} />
                        <Divider/>
                      </React.Fragment>
  )
  // {<SideBar/>}
  return (
    
    <div className={classes.ContentBox} onFocus={props.onFocus} onBlur={props.onBlur}>
    <Hidden smDown>
            {props.toolbar}
    </Hidden>
        <Grid container className={classes.DeckEditorContainer}>
                <Hidden smDown>
                  <Grid item md={2} className={classes.SideDrawer}>
                       <div className={classes.tocButton}>

                        <IconButton className={classes.tocButton} 
                        onClick={toggleSide}>
                          <TocIcon />
                        </IconButton>
                          
                         </div>
                          <div className={classes.explorer}> 
                          {!tocHide && props.toc}
                          </div>
                  </Grid>
                </Hidden>
                <Grid item sm={12} md={editorWidth} className = {classes.EditorContainterOuter}>
                  <div className={classes.EditorContainer}>
                  {props.children}
                  </div>
                </Grid>
                <Hidden smDown>
                    <Grid item md={10-editorWidth} className={classes.Gist}>
                      {props.editorAux} 
                      </Grid>
                </Hidden>

                  </Grid>
  </div>

)
}

// {props.review && <React.Fragment>
//   <div className={classes.ReviewCardEditor}>
//       {props.editor}
//     </div>
//       <div className={classes.ReviewCardTitles}>
//           {props.toc}
//       </div>
//   </React.Fragment>
//       }
