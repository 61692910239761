import React from 'react';
import {nanoid} from 'nanoid';
import { card } from 'assets/jss/material-kit-pro-react';
import { addEditorCards } from 'redux/actions/editorActions';
import { constants } from 'fs';
// import fs from 'fs';


const getCardBlocksKeys = (blockKey, state) => {
  let {cardDict, blockDict} = state
  const cardKey = blockDict[blockKey]
  let cardBlocks = cardDict[cardKey]?.slice()
  let blockIndex = cardBlocks?.indexOf(blockKey)
  return {blockKey, cardKey, cardBlocks, blockIndex }
}


export const reducerOutline = (state, action) => {
  let {tree, blocks, flat} = state
  let parent, children=[];
  const headingTypes = ['heading-one', 'heading-two', 'heading-three', 'heading-four', 'heading-five', 'heading-six']

  let {blockKey, headingBlock, headingType, prior, next } = action.payload
     
  let priorLevel = prior ? headingTypes.indexOf(blocks[prior].type) + 1 : -1
  let nextLevel = next ? headingTypes.indexOf(blocks[next].type) + 1 : -1
  let blockLevel = headingType.indexOf(headingType) + 1
  // find parent, find sibling, find children to move to
  let parentPrior = prior? blocks[prior].parent : null

}


export const reducer = (state, action) => {
  console.log('fromreducer', action.type,action.payload, {...state})
  if (action.type ==='reset') {
    return {cardDict: {}, blockDict: {}, order:[], keyId:{},
                 blockUpdated: new Set(), updated: new Set(), added: new Set(), outline:{} }
  }
  let {cardDict, blockDict, order, updated, blockUpdated,  added, keyId} = {...state}
  let pl = action.payload
  let blockKey = pl?.blockKey
  let cardIndex;

  let {cardKey, cardBlocks, blockIndex} = getCardBlocksKeys(blockKey, state)
  
  switch (action.type) {
    case 'add-order':
      return {...state, order: pl.cardSeq}
    case 'after-save':
      return {...state, updated: new Set(), blockUpdated: new Set(),  added: new Set()}
    case 'block-updated':
      return {...state, blockUpdated: blockUpdated.add(blockKey)}
    case 'card-updated':
      if (cardKey) {
        return {...state, updated: updated.add(pl.cardKey) }
      }
      else {
        cardKey = nanoid(5)
        cardDict[cardKey] = [blockKey]
        blockDict[blockKey] = cardKey
        updated.add(cardKey)
        added.add(cardKey)
        order.push(cardKey)
      }
      break;
     case 'remove-block':
        if (cardBlocks) {
          cardBlocks.splice(blockIndex, 1)
          cardDict[cardKey] = cardBlocks
          delete blockDict[blockKey]
          updated.add(cardKey)
        }
        break;
      case 'add-block-after':
        const {newKey} = action.payload
        if (cardBlocks.includes(newKey)) break;
        cardBlocks.splice(blockIndex+1, 0, newKey)
        cardDict[cardKey] = cardBlocks
        blockDict[newKey] = cardKey
        break;
      case 'add-card':
        let blockKeys = []
        const {blocks, newCardKey, id} = action.payload
        keyId[newCardKey] = id
        if (blocks.length > 0) {
          blocks.map((block) => {
            blockKey = block.key ? block.key : block
            blockKeys.push(blockKey)
            blockDict[blockKey] = newCardKey;
          })
          cardDict[newCardKey] = blockKeys;
          if (!id) {
          added.add(newCardKey)
          updated.add(newCardKey)
          } 
        }
        break;
        case 'remove-card':
          delete cardDict[action.payload.cardKey]
        break;
        case 'merge-card':
          const {toKey, fromKey} = action.payload
          let toBlocks = cardDict[toKey]
          const fromBlocks = cardDict[fromKey]
          const toSepKey = toBlocks.pop()
          let mergedBlocks = toBlocks.concat(fromBlocks)
          mergedBlocks.push(toSepKey)
          if (fromBlocks?.length > 0) fromBlocks.forEach(blockKey => blockDict[blockKey]=toKey)
          cardDict[toKey] = mergedBlocks
          updated.add(fromKey).add(toKey)
          delete cardDict[fromKey]
          cardIndex = order.indexOf(fromKey)
          order.splice(cardIndex,1)
          break;
        case 'split-card':
          // const splitCardKey = nanoid()
          debugger
          const splitCardKey = pl.newCardKey ? pl.newCardKey : nanoid(5)
          if (order.includes(splitCardKey)) break;

          cardIndex = order.indexOf(cardKey)
          order.splice(cardIndex+1, 0, splitCardKey )
          added.add(splitCardKey)
          let newCardBlocks = cardBlocks.slice(blockIndex+1)
          newCardBlocks.forEach((item, i) => {
            blockDict[item] = splitCardKey
          });

          const oldCardBlocks = cardBlocks.slice(0, blockIndex+1)
          if (pl.newBlockKey) newCardBlocks.unshift(pl.newBlockKey)
          oldCardBlocks.push(pl.newSepKey)

          cardDict[cardKey] = oldCardBlocks
          cardDict[splitCardKey] = newCardBlocks
          blockDict[pl.newSepKey] = cardKey
          if (pl.newBlockKey) blockDict[pl.newBlockKey] = splitCardKey
          updated.add(cardKey).add(splitCardKey)
          break;
        default:
        return state
 }
 // fs.writeFile('cstate.json', JSON.stringify(cardDict, null, 4))
 console.log('reducer-log', action.type, cardDict)
 return {...state, cardDict, blockDict, updated, added, keyId, order}

}
