import React from 'react';
import ReactDOM from 'react-dom';
import { Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import reducer from './redux/reducers'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';


axios.defaults.baseURL = process.env.REACT_APP_API_SERVER//'http://127.0.0.1:8000/'
//axios.defaults.baseURL = 'https://nuronote.com/api' 


console.log('axios base url', process.env.REACT_APP_API_SERVER)
//const token =  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMSwidXNlcm5hbWUiOiJwb29oYmVhciIsImV4cCI6MTU5NDczOTY1NywiZW1haWwiOiJwcmFzb29ua2d1cHRhQGdtYWlsLmNvbSJ9.tA6rjOXVAlV9RiUWhgEBEfaXDJ73NfsHgc6HjQLcPAI'
//if (localStorage.getItem("token"):
//  console.log()

const store = createStore(reducer, composeWithDevTools(
  applyMiddleware(thunk),
));
const token = localStorage.getItem("accessToken");
if (token) {
  console.log('token found', token)
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

}
axios.interceptors.request.use(request => {
  console.log('axios request', request);
  return request;},
  error => {  
    console.log('something errorred out', error);
  return Promise.reject(error);
  }
)

axios.interceptors.response.use(
  response => {
    console.log('axios response', response)
    return response;},
  error =>  
   {
    const originalRequest = error.config;
     console.log('error', error)
     const refreshToken = localStorage.getItem('refreshToken');
    if (error.response?.status === 401 && error.response?.statusText === "Unauthorized" && refreshToken) {
      console.log('refresh token', refreshToken)
      return axios
          .post('/token/refresh', {refresh: refreshToken})
          .then((response) => {

              localStorage.setItem('accessToken', response.data.access);
              localStorage.setItem('refreshToken', response.data.refresh);

              axios.defaults.headers['Authorization'] = "Bearer " + response.data.access;
              originalRequest.headers['Authorization'] = "Bearer " + response.data.access;

              return axios(originalRequest);
          })
          .catch(err => {
              console.log(err)
          });
  }
    return Promise.reject(error);
  }
)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
