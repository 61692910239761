import React from 'react';
import { Card, makeStyles } from '@material-ui/core';
// import {Editor,   ContentBlock, Modifier, convertToRaw, convertFromRaw} from 'draft-js';
// import {populateEditor} from '../Edit/Editor/editUtils'

// import {MyEditor, populateEditor} from 'components/Edit/Editor/'
export default function CarouselSlide(props) {
    //const { content  } = props.content;

    const useStyles = makeStyles(() => ({
        card: {
            backgroundColor: 'white',
            borderRadius: 5,
            padding: '20px',
            margin: '0px',
            width: '100%',
            height: '100%',
            boxShadow: '20px 20px 20px lightgray',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1 rem',
            lineHeight: '1.5rem',

        },
    }));

    const classes = useStyles();
    // const editorState = populateEditor(props.content)
          // <MyEditor readOnly editorState={editorState} />
    return (
        <div className={classes.card}>
          {props.children}

        </div>
    );
}
