import React from 'react';
import {nanoid} from 'nanoid';
import {convertToRaw, Modifier} from 'draft-js';
import {getCardText} from './index.js';
import {splitBlock} from 'components/Edit/Editor'

export const getCardsToSplit = (rawContent, cardState, splitOpt) => {
  const maxLen = splitOpt.maxLen
  if (!maxLen) maxLen = 100
  //console.trace('getCardsToSplit')
  let cards = Object.keys(cardState.cardDict)
  let splitCards = [], cardBlocks={}
  if (cards.length > 0 ){
        cards.forEach((cardKey) => {
        let cardText = getCardText(cardState, cardKey, rawContent)
          const cardPlainText = cardText.blocks.reduce( (text, block) => text+block.text, '')
          if (cardPlainText.length > maxLen) {
            cardBlocks[cardKey] = cardText.blocks
          }
        })
      }
    return cardBlocks
}


export const splitBlockCard = (props) => {
  let {contentState, blockKey, offset, blockType} = props
  if (!blockType) {blockType = 'sepBlock'}
  let newBlockKey, newSepKey, newSelection
  if (offset >= 0) {
    debugger;
      let blockLength = contentState.getBlockForKey(blockKey).getLength()
      if (offset <= blockLength) {
        contentState = splitBlock(contentState, blockKey, offset)
        newBlockKey = contentState.getSelectionAfter().getStartKey();
        debugger
        // let backupKey = contentState.getSelection().getStartKey()
      }
      // now add a sep block 
      contentState = splitBlock(contentState, blockKey, offset)
      newSelection  = contentState.getSelectionAfter()
      contentState =  Modifier.setBlockType(contentState, newSelection, blockType)
      newSepKey = newSelection.getStartKey()
    }
    return [contentState, newBlockKey, newSepKey]
}

const getSplitOptions = (splitOptions) => {
  let delimOption = splitOptions.delim
  let customDelim = splitOptions.customDelim
  let maxLen = splitOptions.maxLen * 10 // convert words to letters including spaces
  let delim = '\n';
  if (!maxLen) maxLen = 2000
  switch(delimOption) {
    case 'other':
      delim = customDelim
      break;
    case 'newLine':
      delim = '\n';
      break;
    case 'doubleNewLine':
      delim = '\n\n';
      break;
    case 'semiCol':
      delim = ';'
      break;
  }
  return {maxLen, delim}

}
export const getSplits = (contentState, cardState, cardKey, splitOptions) => {
  const {maxLen, delim} = getSplitOptions(splitOptions)
  debugger
  const blockKeys  = cardState.cardDict[cardKey].slice()

  let blockText = blockKeys.map(key => [key, contentState.getBlockForKey(key).getText()+'\n'])
  // filter out any blocks that have zero length before new line character added including sep block
  blockText = blockText.filter(block => block[1].length > 1)
  const blockLen = blockText.map(val => [val[0], val[1].length])
  const cardLen = blockLen.reduce((acc, curr) => acc + curr[1], 0)
  const numSplit = Math.floor(cardLen / maxLen)
  //const cumSum = (sum => value => sum += value)(0)

  //const blockCum = new Map(blockLen.map(val => [val[0], cumSum(val[1])]))
  let cumSum = 0, blockCum = []
  for (let val of blockLen) {
    cumSum += val[1]
    blockCum.push([val[0], cumSum ])

  }
  blockCum = new Map(blockCum)

  function findRec(text) {
    let arr = [], index = -1
    while ((index = text.indexOf(delim, index+1)) >= 0 ) arr.push(index)
    if (arr[0] === text.length -1) {
      index = text.indexOf(' ', maxLen)
      arr.unshift(index)}
    return arr
  }
/// TODO: split block at offset of max length

  //text.split("").map((c,i) => if (c == delim) return i;).filter(v=> return v >=0)
  // const blockDeText = blockText.map(val => [val[0], val[1].split(delim).map(txt=> txt.length+1)])

  const blockDeText = blockText.map(val => [val[0],
    findRec(val[1]), val[1].length  ])

  const candBreakPoints = blockDeText.map( val => [val[0], val[1], blockCum.get(val[0]) - val[2]])


  const cumBreaks = candBreakPoints.map( val => val[1].map(v => [val[0], v, v+val[2] ] )).flat()
  if (cumBreaks.length===1) return []
  // let cumBreaks = candBreakPoints.map(val => [val[0], val[1], cumSum(val[1])])
  // let range = n => [...Array(n).keys()]
  let index,  splits = [], splitCtr = 1, prevVal;
  prevVal = cumBreaks[0]
  for (let val of cumBreaks) {
          prevVal = val
    if ((val[2] >= maxLen * splitCtr) && (val[2] < cardLen))  {
      splits.push(prevVal)
      splitCtr = Math.ceil((val[2] / maxLen) + 1)
      // splitCtr += 1;
    }
  }

  for (let split of splits ){
    split.push(nanoid(5))
  }
  console.table('splits', splits)
  debugger
  return splits
}
