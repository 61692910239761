import * as actionType from '../actionTypes';
import axios from 'axios';

export const getImageList = (type) => {
  return dispatch => {
    let params = {type: type}
    return axios.get('image-random/', {params})
    .then(response => {
      console.log('images retrieved', response.data)
      dispatch({
        type: actionType.FETCH_IMAGE_LIST,
        data: response.data
      })
    })
  }
}
export const updateSnackBar = (msg, status) => {
  return dispatch => {
    dispatch({
      type: actionType.UPDATE_SNACKBAR,
      msg: msg,
      status: status,
    })
  }
}

export const updateActiveDeck = (deckId) => {
  return (dispatch) => {
    dispatch({
      type: actionType.UPDATE_ACTIVE_DECK,
      deckId,
    })
  }
}
export const updateViewMode = (deckId, mode) => {
  return (dispatch) => {
    dispatch({
      type: actionType.UPDATE_CARDVIEW,
      deckId,
      mode,
    })
  }
}
export const updateActiveCard = (deckId, cardKey) => {
  return dispatch => {
    dispatch({
      type: actionType.UPDATE_ACTIVE_CARD,
      deckId,
      cardKey,
    })
  }
}

export const addEditPlugins = (plugins) => {
  return (dispatch) => {
    dispatch({
      type: actionType.ADD_EDIT_PLUGINS,
      data: plugins
    })
  }
}
export const infoLog = (data) => {
  return (dispatch) => {
    return axios.post('infolog/', data)
    .then(response => {
      console.log('updated response', response.data)
      return dispatch({
        type: 'INFO_LOGGED',
        data: response.data
      })
    })
  }

}

export const splitOptions = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionType.UPDATE_SPLIT_OPTIONS,
      data: data
    })
  }
}
