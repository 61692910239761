import React, {Fragment, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {appStateActions} from 'redux/actions'
import classes from './richButton.module.css'
import {Button, Divider, ButtonGroup, FormGroup, FormControl, MenuItem, 
  InputLabel, FormControlLabel, Select, Checkbox} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EditIcon from '@material-ui/icons/Edit';
import MultiSelect from 'components/UI/MultiSelect'

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));

export const ReviewToolbar = (props) => {


  /// NOT USED ANY MORE
  // const classes = useStyles()
  // const [typeState, setType] = useState({content: true, flash: true, reviewId: props.currentReview}) 
  // const contentTypeChange = (event) => {
  //   setType({ ...typeState, [event.target.name]: event.target.checked });
  //   props.tbCallback('contentType', typeState)
  // };

  // const reviewOptions = props.deck? props.deck.next_review.entries.map(([k,v]) => ({id: k, label: k})): []

  // const dispatch = useDispatch()
  // const DiffLevel = () => (
  //   <ButtonGroup>
  //     <Button> Easy </Button>
  //     <Button> Medium </Button>
  //     <Button> Hard </Button>
  //   </ButtonGroup>
  // )
  //   const reviewSelectionHandler = (reviewId) => {
  //     setType({...typeState, reviewId: reviewId})
  //     props.tbCallback('reviewId', typeState)
  //   }
    
  //         // <MultiSelect options={reviewOptions} label=""
  //         // value={0}  selectionHandler={reviewSelectionHandler}/>


  //   const reviewSet = (
  //     <FormControl className={classes.formControl}>
  //   <Select
  //       value={typeState.reviewId}
  //       onChange={reviewSelectionHandler}
  //     >
  //       {reviewOptions.map(rev => (<MenuItem value={rev.id}>{rev.label}</MenuItem>))}
  //     </Select>
  //     </FormControl>
  //   )

  //   const contentType = (
  // <FormGroup row>
  // <FormControlLabel
  //   control={<Checkbox checked={typeState.content} onChange={contentTypeChange} name="content" />}
  //   label="Content"
  //   />
  // <FormControlLabel
  //   control={
  //     <Checkbox   checked={typeState.flash}   onChange={contentTypeChange}
  //     name="flash"  /> } label="Flash"
  //   />
  // </FormGroup>
  //   )


  // return (
  //   <React.Fragment>
  //     {reviewSet}
  //     {contentType}
  //   </React.Fragment>
  // )
return <React.Fragment></React.Fragment>
}

{/* <DiffLevel/> */}
{/* <button
onClick={() => dispatch(appStateActions.updateViewMode(deckId, 'card'))}>
  <ViewHeadlineIcon/>
  </button> */}

export const ViewModeButton = ({deckId}) => {
  const dispatch = useDispatch()
  const viewMode = useSelector(state => state.appState.viewMode[deckId])

  return(
    <ButtonGroup>
      <Button size="small"
    onClick={() => dispatch(appStateActions.updateViewMode(deckId, 'doc'))}>
      <EditIcon/>
      </Button>
      <Button size="small"
      onClick={() => dispatch(appStateActions.updateViewMode(deckId, 'read'))}>
      <MenuBookIcon/>
      </Button>
      </ButtonGroup>


  )
}

export const ViewMode = (props) => {
  return(
    <div className={classes.Toolbar}>
      <ViewModeButton deckId={props.deck.id}/>
      </div>
  )

}
