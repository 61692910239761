import React, {useState} from 'react';
import ContentEditable from 'react-contenteditable';
import {useDispatch} from 'react-redux';
import {Collapse, IconButton, Card, Box, Grid,
   CardActionArea, CardActions,  CardMedia, CardHeader, CardContent, Divider,
   Typography, TextField, Button, FormControlLabel, Slider, Hidden,
 } from '@material-ui/core'

import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon  from '@material-ui/icons/Delete'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import classes from './DeckDetails.module.css';
import {deckActions, appStateActions} from '../../redux/actions'

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';


const useStyles = makeStyles({
  root: {
      width: '300px',
      margin: '20px',
  },
  media: {
    height: '200px',
  },
  review: {
    height: '50px',
    justifyContent: 'space-between',

  },
  footer: {
    justifyContent: 'space-around'
  }
});


const ExpandIcon = ({expanded}) =>
expanded? <ExpandLessIcon/> : <ExpandMoreIcon/>;


export default function DeckCard(props) {
const classes = useStyles();
// const deck = props.deck
const dispatch = useDispatch()
const history = useHistory()
const [deck, setDeck] = useState(props.deck)
const [expanded, setExpanded] = useState(false)

const toggleExpanded = () => {setExpanded(!expanded)}

const handleChange = (event) => {
  const {name, value } = event.target;
  setDeck({...deck, name: value})
  console.log('deck after change', deck)
}

const daysDelta = (dateString) => {
  let date = new Date(dateString)
  const currDate = new Date()
  const delta = parseInt((date?.getTime() - currDate.getTime()) / (24 * 3600 * 1000))
  if ( delta < 0 ) return -1*delta + ' days ago'
  return 'in '+delta+' days.'
}
const deleteDeck = () => {
  dispatch(deckActions.deleteDeck(deck.id))
  // history.push('/decks')
}

const publishDeck = () => {dispatch(deckActions.publishDeck(deck.id))}

const editHandler = () => {
  history.push('/deck/'+deck.id)
}

const submitHandler = () => {
  dispatch(deckActions.updateDeck(deck))
}
const reviewHandler = () => {
  dispatch(appStateActions.updateViewMode(deck.id, 'read'))
  history.push(`/deck/${deck.id}`)
}

const NextReview =() => (<TextField
               name="nextReview"
               type="date"
               size="small"
               value={deck.nextReview}
               defaultValue={deck.next_review_date}
               onChange={handleChange}
             />)

return (
  <React.Fragment>

  <Card className={classes.root}>
    <CardActionArea onClick={editHandler}>
      <CardMedia
        className={classes.media}
        image={'https://nuronote.com/images/'+deck.img_url}
        title={deck.name}
        />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
        <ContentEditable html={deck.name} name="name"
                  onChange={handleChange}/>
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
                  {deck.tags}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
                  {deck.cards.length}
        </Typography>

      </CardContent>
        <Divider/>
    </CardActionArea>
      <CardActionArea className={classes.review} onClick={reviewHandler}>
        <span> Next Review: </span> 
        <PlayCircleOutlineIcon/>    
      </CardActionArea>
        <Divider/>      

    <CardActions className={classes.footer}>
    <Button size="small" onClick={publishDeck} color="primary"> Publish </Button>         
  
     <Button size="small" onClick={deleteDeck} endIcon={<DeleteIcon/>}> Delete </Button>         
     </CardActions>
  </Card>
  <Collapse in={expanded}>

</Collapse>
</React.Fragment>
);
}
{/* <div> Last Review: {daysDelta(deck.last_review_date)} </div> */}

{/* <IconButton onClick={editHandler} >
<CreateIcon/>
</IconButton> */}
