
import { combineReducers } from 'redux';
import { myDecks} from './deckReducer';
import {hubDecks, hubDeckDetails} from './deckhubReducer';
//import { editorText, editorState, activeCard } from './editorReducer';
import { noteCards, editor} from './cardReducer';
import {deckEditor, editorCards} from './editorReducer';
//impor {reviews} from './reviewReducer';
import {auth} from './authReducer';
import {appState} from './appStateReducer';

export default combineReducers({
  myDecks: myDecks,
  hubDecks: hubDecks,
  hubDeckDetails: hubDeckDetails,
//  reviews: reviews,
//  deckDetails: deckDetails,
  noteCards: noteCards,
   auth: auth,
   deckEditor: deckEditor,
   editorCards: editorCards,
   appState: appState,
  // editorText: editorText,
  // activeCard: activeCard,
  // editorStyle: editorStyle,
})
