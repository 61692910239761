
import React, {Fragment} from 'react';
import {Route, Link } from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// import classes from './BottomNav.module.css';
import {bottomNavStyle} from './bottomNavStyle';
import NewNote from './NewNote'
import DeckMenu from './DeckMenu';// <Link to='/decks/'>
import HubDecks from './DeckHubList'
const useStyles = makeStyles(bottomNavStyle)

        //  <HubDecks/>

const BottomNav = (props) => {
  console.log('bottom nav', props)
  const classes = useStyles()
  return (
  <div className={classes.bottomNav}>
    <DeckMenu decks={props.decks}/>
      <Hidden mdDown>
         <NewNote decks={props.decks} />
      </Hidden>
    
   </div>
 );
 }
export default BottomNav;
