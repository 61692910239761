import { fade, makeStyles , withStyles} from '@material-ui/core/styles';
export const deckListStyle = theme => ({

  container: {
    boxSizing: 'border-box',
    margin: '10px',
    marginLeft: '5vw',
    padding: '10px',
    height: '80vh',
    width: '90vw',
    backgroundColor: '#eee',
    display: 'flex',
    flexFlow: 'row wrap',
    overflowY: 'auto',
    borderRadius: '2px',

  },
  
  listHeader: {
    width: '100%',
    height: '10vh',
    
    backgroundColor: 'lightsteelblue',
  }
  }
)