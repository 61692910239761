import React from 'react';

const dateFormat = (dateObj) => dateObj.toDateString().split(' ').slice(1).join('-')

export const deleteFromReviews = (cardIds, next_review) => {

  let nrds = Object.entries(next_review)
  let newNrds = nrds.map(([k,v]) => {
    v.cards = v.cards.filter( item => !cardIds.includes(item))
    return [k, v]
  } )
  return Object.fromEntries(newNrds)
  
}

export const addToReviews = (cards, next_review, type='content', daysToReview=3) => {
    const nrd = new Date()
    nrd.setDate(nrd.getDate() + daysToReview)
    const nrdKey = dateFormat(nrd)
    let nrdValues = {};
    let  cardKeys = cards.map(card => card.key)
    // let flashes = cards.map(card => card.flashes)


    if (next_review.hasOwnProperty(nrdKey)){
      nrdValues = next_review[nrdKey]
      if (nrdValues.hasOwnProperty('cards'))   cardKeys = nrdValues['cards'].concat(cardKeys)
    }
    nrdValues = {...nrdValues, cards: cardKeys}

    return {...next_review, [nrdKey]:{...nrdValues }}
    
}


export const updateCardReview = (cardKey, reviewInfo) => {
  let {next_review, card_review_count, review_history} = reviewInfo 

  const today = new Date()
  const dateKey = dateFormat(today)
  debugger;
  // get review history and add card key to the set
  const review_set = review_history.hasOwnProperty(dateKey) ? review_history[dateKey] : []
  if (!review_set.includes(cardKey)) {
            review_set.push(cardKey)
            // card review count add card if not there and add count + 1
            let newCount = card_review_count.hasOwnProperty(cardKey) ? card_review_count[cardKey]+1 : 1
            card_review_count = {...card_review_count, [cardKey]: newCount}
          
          review_history = {...review_history, [dateKey]: review_set}
          
          // next review find nearest set that has the card; move to done and create new one with 
          let nrds = Object.entries(next_review)
          let currentReviewSet = nrds.filter(([k,v]) => {
            if (v.hasOwnProperty('cards') && (v.cards.includes(cardKey))) {
              return true
            }
          } )
          debugger;
          let currReviewDt = dateKey
          if (currentReviewSet.length > 0) {
            currReviewDt = currentReviewSet[0][0]
            currentReviewSet = currentReviewSet[0][1]
            currentReviewSet.cards = currentReviewSet.cards.filter(item => item !== cardKey)
          }
          else {
            currentReviewSet = {cards:[], done:[]}
          }
          if (!currentReviewSet.hasOwnProperty('done')) currentReviewSet = {...currentReviewSet, done:[]}

          currentReviewSet.done.push(cardKey)            

          let newReviewDt = new Date()
          newReviewDt.setDate(newReviewDt.getDate() + 7)
          const newReviewKey = dateFormat(newReviewDt)

          let newReview = next_review.hasOwnProperty(newReviewKey) ? next_review[newReviewKey] : {cards:[], done:[]}
          newReview.cards.push(cardKey)

          next_review = {...next_review, [currReviewDt]: currentReviewSet, [newReviewKey]: newReview}
  }
  return {next_review, card_review_count: {...card_review_count}, review_history: {...review_history}}
}
