import { createMuiTheme } from '@material-ui/core/styles';

import amber from '@material-ui/core/colors/amber';

const primaryColor = amber;

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#649fb7',
      main: '#3e88a5', 
      dark: '#2b5f73',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f5eaf7',
      main: '#f3e5f5',
      dark: '#aaa0ab',
      contrastText: '#000',
    },
  },
});
