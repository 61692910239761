import React from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { FolderOpenRounded } from '@material-ui/icons';


const drawerWidth = 60;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: 'lightgray',
    overflowX: 'hidden',
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));


export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const history = useHistory();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{paper:classes.drawerClose}}
      >
        {[1, 2, 3, 4, 5].map(()=> <ListItem/>)}
        <Divider />
        <List>
          <ListItem button key="folder" onClick={()=> history.push('/decks')}>
            <ListItemIcon><FolderOpenRounded fontSize="large"/></ListItemIcon>
          </ListItem>
          <ListItem button key="add">
            <ListItemIcon><AddCircleOutlineIcon fontSize="large"/></ListItemIcon>
          </ListItem>
          <Divider/>
          <ListItem button key="edit">
            <ListItemIcon><EditIcon></EditIcon></ListItemIcon>
          </ListItem>

          
        </List>
        <Divider />

      </Drawer>
  );
}