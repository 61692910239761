import React, {useEffect, useState} from 'react';
import { useDispatch} from 'react-redux';
import {Snackbar, Button, IconButton} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { appStateActions } from 'redux/actions';


export default function SimpleSnackbar(props) {
  const [open, setOpen] = React.useState(true);
const dispatch = useDispatch()
  useEffect( ()=> {
      setOpen(props.status==='open')
  }, 
  [props.status])
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    dispatch(appStateActions.updateSnackBar('', 'close'))
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={props.msg}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
  );
}