
import React, {useState} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import {Hidden, Grid} from '@material-ui/core';
import Favorite from "@material-ui/icons/Favorite";
// core components

// import Header from "components/Header/Header.js";
// import Footer from "components/Footer/Footer.js";

import {GridContainer, GridItem, Button, List, ListItem} from 'components/UI';
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
// import Parallax from "components/Parallax/Parallax.js";

import landingPageStyle from "./landingPageStyle.js";
import Signin from './Signin';
import imgOne from '../../assets/img/one.jpg'
import logoImage from 'Logo-nuro.png'
import imgTwo from '../../assets/img/two.jpg'
import CreatorImg from 'assets/img/creator.png'
import ReviewImg from 'assets/img/review.png'
import HubShareImg from 'assets/img/hubshare.png'

// Sections for this page
// import SectionProduct from "./Sections/SectionProduct.js";
// import SectionTeam from "./Sections/SectionTeam.js";
// import SectionWork from "./Sections/SectionWork.js";

const useStyles = makeStyles(landingPageStyle);

const title = "Achieve Super Intelligence with Effortless Learning"
const subTitle = [`Learn Faster and Deeper using the combined power of Neuroscience 
and Artificial Intelligence.`,
`Nuronote makes learning effortless. Break your notes into smaller chunks 
and quickly summarize and generate flashcards `]

const callToAction = "Unleash your full potential now"

const sec1Text = `Master Creator - Super easy to split your notes and create flashcards. 
Bring your notes or textbooks, 
research papers, pdfs and with a few clicks easily convert them into a deck of flashcards `

const sec2Text = `Personal Coach - Nuronote review system acts as your personal 
coach to get you to 100% learning. Practice flashcards while keeping track of the challenge 
you need at the right interval of days. 
You focus on just learning and leave the rest of scorekeeping to Nuronote`


const sec3Text = `Treasure Chest - Share your notes with other learners 
or get a jumpstart by using decks already posted`

export default function LandingPage(props) {


  // const [signupModal, setSignupModal] = useState(false)
  const [signUp, setSignUp] = useState(false)
  const [signIn, setSignin] = useState(false)
    const toggleLoginModal = () => {
      setSignin(false)
      setSignUp(false)
    }


  React.useEffect(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    });

    const signinHandler = () => {
      setSignin(true)
      setSignUp(false)
    }

    const signupHandler = () => {
      setSignUp(true)
      setSignin(false)
    }

  const classes = useStyles();
  return (
<div className={classes.root}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={9} >
              <h1 className={classes.title}>{title}</h1>
              <h4 className={classes.subtitle}>
                {subTitle.map((item, i) => <p key={i}>{item}</p>)}
              <br/>
              <br/>
              {callToAction}

              </h4>
              <br />
              <div className={classes.signupButtons}>
              <Button variant="contained" color="primary" onClick={signupHandler}>
              Sign Up </Button>
              
              <span> Already a Member?  &nbsp; 
              <Button variant="contained" color="secondary" onClick={signinHandler}>Sign In</Button>
              </span>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
            <SectionCard url={CreatorImg} text={sec1Text}/>
            <SectionCard url={ReviewImg} text={sec2Text}/>
        <SectionCard url={HubShareImg} text={sec3Text}/>
        </div>
      </div>
        { (signUp || signIn) && <Signin onClose={toggleLoginModal} 
        signUp={signUp} signIn={signIn} />}
</div>
  );
}

export const SectionCard = (props) => {
  const classes = useStyles();
  return(
    <div className={classes.section}> 
    <Grid container>
      <Hidden smDown>
      <Grid item md={4}>
      <div className={classes.sectionImage}>
        <img src={props.url} height="100%" width="100%"></img>
      </div>
      </Grid>
      </Hidden>
      <Grid md={8} sm={12}>
        <div className={classes.sectionText}>
        <p>{props.text} </p>
      </div>
        </Grid>
    </Grid>
    </div>


  )
}
// <Section  Product />
// <SectionTeam />
// <SectionWork />

// <Header
//   color="transparent"
//   brand="Material Kit PRO React"
//   links={<HeaderLinks dropdownHoverColor="info" />}
//   fixed
//   changeColorOnScroll={{
//     height: 300,
//     color: "info"
//   }}
//   {...rest}
// />

// const Footer = (classes) => (
//   <div>
//     <div className={classes.left}>
//       <List className={classes.list}>
//         <ListItem className={classes.inlineBlock}>
//           <a
//             href="https://www.creative-tim.com/?ref=mkpr-landing"
//             target="_blank"
//             className={classes.block}
//           >
//             Creative Tim
//           </a>
//         </ListItem>
//         <ListItem className={classes.inlineBlock}>
//           <a
//             href="https://www.creative-tim.com/presentation?ref=mkpr-landing"
//             target="_blank"
//             className={classes.block}
//           >
//             About us
//           </a>
//         </ListItem>
//         <ListItem className={classes.inlineBlock}>
//           <a href="//blog.creative-tim.com/" className={classes.block}>
//             Blog
//           </a>
//         </ListItem>
//         <ListItem className={classes.inlineBlock}>
//           <a
//             href="https://www.creative-tim.com/license?ref=mkpr-landing"
//             target="_blank"
//             className={classes.block}
//           >
//             Licenses
//           </a>
//         </ListItem>
//       </List>
//     </div>
//     <div className={classes.right}>
//       &copy; {1900 + new Date().getYear()} , made with{" "}
//       <Favorite className={classes.icon} /> by{" "}
//       <a
//         href="https://www.creative-tim.com/?ref=mkpr-landing"
//         target="_blank"
//       >
//         Creative Tim
//       </a>{" "}
//       for a better web.
//     </div>
//   </div>

// )
