

 import React from 'react';
 import { makeStyles } from '@material-ui/core/styles';
 import Card from '@material-ui/core/Card';
 import CardActionArea from '@material-ui/core/CardActionArea';
 import CardActions from '@material-ui/core/CardActions';
 import CardContent from '@material-ui/core/CardContent';
 import CardMedia from '@material-ui/core/CardMedia';
 import Button from '@material-ui/core/Button';
 import Typography from '@material-ui/core/Typography';
 import StarIcon from '@material-ui/icons/Star';
 
 const useStyles = makeStyles({
   root: {
       width: '300px',
       margin: '20px',
   },
   media: {
     height: '200px',
   },
 });
 
 export default function HubDeckCard(props) {
   const classes = useStyles();
    const {deck} = props
   return (
     <Card className={classes.root}>
       <CardActionArea onClick={()=> props.clickHandler(deck.id)}>
         <CardMedia
           className={classes.media}
          image={'https://nuronote.com/images/'+deck.img_url}
           title={deck.name}
         />
         <CardContent>
           <Typography gutterBottom variant="h5" component="h2">
             {deck.name}
           </Typography>
           <Typography variant="body2" color="textSecondary" component="p">
                     {deck.tags}
           </Typography>
           <Typography variant="body2" color="textSecondary" component="p">
                     {deck.cards.length}
           </Typography>
         </CardContent>
       </CardActionArea>
       <CardActions>
        <Button variant="outlined" startIcon={<StarIcon/>}> {deck.stars} </Button>         
        </CardActions>
     </Card>
   );
 }

