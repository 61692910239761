
import React , {useState} from 'react';
import {Button, Menu, Popover,  Popper,ClickAwayListener } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(()=> ({
  showMenu: {
    padding: '20px',
    zIndex: 3,

    border: '1px solid white',
    borderRadius: '2px',
    boxShadow: '2.5px 5px 15px rgba(0, 0, 0, 0.25)',
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: 'white',
    '& > *' :{
      marginBottom: '10px',
    }
  }
}) )

// onClose={props.onClose}
const ShowForm = (props) => {
const classes = useStyles()
    return (
      <ClickAwayListener onClickAway={props.onClose}>
      <Popper
          anchorEl={props.anchorEl}
          open={props.open}
          disableFocusListener
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
          <div className={classes.showMenu}>
              {props.children}
          </div>
        </Popper>
        </ClickAwayListener>
          )
    }

export default ShowForm;


// const useStyles = makeStyles((theme) => ({
//   paper: {
//     border: '1px solid',
//     padding: theme.spacing(1),
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

// export default function SimplePopper() {
//   const classes = useStyles();
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(anchorEl ? null : event.currentTarget);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'simple-popper' : undefined;

//   return (
//     <div>
//       <button aria-describedby={id} type="button" onClick={handleClick}>
//         Toggle Popper
//       </button>
//       <Popper id={id} open={open} anchorEl={anchorEl}>
//         <div className={classes.paper}>The content of the Popper.</div>
//         {props.children}
//       </Popper>
//     </div>
//   );
// }