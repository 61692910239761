import React, {useState} from 'react';
import {Button, TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';
import { deckActions} from 'redux/actions'
import {useHistory} from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'fixed',
      top: '40%',
      left: '51%',
      transform: 'translate(-51%, -51%)',
      marginTop: theme.spacing(8),
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '25px',
      border: '1px solid black',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    }
}))
  
const AddDeck = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [deckName, setName] = useState(null)
    const classes = useStyles()
    

    
    const submitHandler =  () => {
       deckActions.createDeck({name: deckName}).then(newDeck => {
        dispatch(deckActions.addDeck(newDeck));
         console.log('response deck', newDeck)
         history.push({
           pathname: `/deck/${newDeck.id}`, 
           state: {newDeck: true, cardData: 'my new state data'}
          })
       })
      
    
    
    } 
    const handleChange = event => {
      const {name, value} = event.target
      setName(value)
    }
   return (  
    <div className={classes.paper}>

      <TextField name="deckName" 
      variant="outlined" required 
      fullWidth id="deckName" 
      label = "New Deck Title" 
      value={deckName}
      onChange={handleChange}
      inputProps={{maxLength:50}}
      />
    <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={submitHandler}
              >
              Create Deck
            </Button>
              </div>
          )
}

          // </form>    
export default AddDeck;