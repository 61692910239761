import React, {useState, useRef, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import ContentEditable from 'react-contenteditable';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {Divider, TextField, Typography} from '@material-ui/core';

import { deckActions} from 'redux/actions'
const useStyles = makeStyles( () => ({
  deckRoot: {
    // flex: '1, 1, 0',
    display: 'flex',
    color: 'black',
    width: '100%',
    height: '100%',
    boxShadow: '0px 2px 2px 2px lightgray',
    // marginBottom: '10px',
    // "&  *": {
    //   width: '100%',
    //   height: '100%',
    //   color: 'black',
    // },
   
    "& :focus":{
      backgroundColor: 'lightyellow',
      color: 'black',
    },
    
  },
  textName: {
    paddingLeft: '5px',
    backgroundColor: 'cream',
    textTransform: 'capitalize',
 
  }

}))

export const DeckNameTags = props => {

  const classes = useStyles();

  // const initialState = {
  //     deckName: props.deck?.name,
  //     deckTags: props.deck?.tags,
  //     modified: false,
  //   }
  const [name, setName] = useState(props.deck?.name)
  // const [input, setInput] = useState(initialState);
  const [modified, setModified] = useState(false)
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();
 
  useEffect(()=> {
    setName(props.deck?.name)
    // setInput({...input, deckName: props.deck.name})
  }, [props.deck])

  const inputRef = useRef()
  const handleNameChange = event => {
    // const { name, value } = event.target;
    let newVal = 'untitled-'+props.deck.id
    if (event.target.value.length>0)   newVal = event.target.value
      setName(newVal)
    
    setModified(true)
    }
    // console.log('event', event.target)
    // setInput({...input,  deckName: value, modified: true});
    const onFocus = (event) => {
      setEditing(true)
    }

    const onBlur = (event) => {
      if (modified) {
        const newDeck = {...props.deck, name: name}
           dispatch(deckActions.updateDeck({...newDeck}))
           setModified(false)
        // props.updateDeck(newDeck)
      }
      setEditing(false);
    }
    // <ContentEditable html={input.deckName}
    //   className="content-editable" name="deckName"
    //   onChange={handleChange} onBlur={onBlur}
    //   />

    // {!editing && <Typography variant="h6">
    //   {input.deckName}
    // </Typography>}
return(
  <div className={classes.deckRoot} ref={inputRef}
    onClick={onFocus} onBlur={onBlur}>
    <TextField className={classes.textName}  value={name} onChange={handleNameChange} name="deckName" />
    <Divider/>

</div>
)

}
// <ContentEditable html={input.deckTags} name="deckTags"
// onChange={handleChange} onBlur={onBlur}/>
