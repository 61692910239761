import React from 'react';
import { useDispatch } from 'react-redux';
import {nanoid} from 'nanoid';

const getDelim = (delimOption, customDelim) => {
    
    let delim = '\n';
    switch(delimOption) {
        case 'other':
          delim = customDelim
          break;
        case 'newLine':
          delim = '\n';
          break;
        case 'doubleNewLine':
          delim = '\n\n';
          break;
        case 'semiCol':
          delim = ';'
          break;
      }
    return delim
}


export const getFlashes = (text, splitOptions) => {
    debugger
    const {delim, customDelim, delimAns, customDelimAns} = splitOptions
    let delimOption = splitOptions.delim
    let delimAnsOption = splitOptions.delimAns

    const delimSplit = getDelim(delimOption, customDelim) 
    const delimQA = getDelim(delimAnsOption, customDelimAns)

    const splits = text.split(delimSplit)

    console.log('text to split', text, splits)
    let flashes = splits.map(gist => {
        const gsplit =  gist.split(delimQA)
        console.log('gsplit', gsplit)
        let key = nanoid(5)
        if (gsplit[0].length > 0 ) return { key: key, qtext: gsplit[0], answer: gsplit[1]}
        return null
    }).filter(g => g !== null)

    return flashes;

}