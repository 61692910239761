
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {nanoid} from 'nanoid';

import {Collapse,  Card, Box, Input, InputBase,
   CardActionArea, CardActions, CardHeader, CardContent, Divider,
   Typography, TextField, Button, FormControlLabel, Slider,
 } from '@material-ui/core'
import {AddIcon, } from 'assets/jss/material.js';
import ContentEditable from 'react-contenteditable';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon  from '@material-ui/icons/Delete'
import CheckBoxList from 'components/UI/CheckBoxList';
import Spinner from 'components/UI/Spinner';
import {deckActions, cardActions} from 'redux/actions';
import {addToReviews} from 'components/Review/reviewUtil'


const useStyles = makeStyles((theme) => ({
  addButton: {
    width: '100%',
    backgroundColor: 'lightyellow',
    border: '2px solid azure',
  },
  qList: {
    padding: '10px',
    height: '100%',
    overflowY: 'auto',
    backgroundColor:'lightgreen'
  },
  qCard: {
    padding: '5px',
    minHeight: '120px',
    backgroundColor: 'azure',
    margin: '10px',
    border: '1px solid gray'
  },
  frontOuter: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
  },
  front:{
      width: '100%',
      padding: '10px',
      // border: '1px solid gray',
      backgroundColor: 'azure',
    },
  back: {
    backgroundColor: 'cream',
    // border: '1px solid lightgray',
    width: '100%',
    padding: '10px',

    '& ::first-letter' :{
      textTransform: 'uppercase',
      padding: '10px',
    }
  },
      header: {
      // textAlign: 'center',
      height: '20px',
      backgroundColor: theme.palette.primary.light,
      display: 'flex'

      
    },
    backHeader: {
      // textAlign: 'center',
      height: '20px',
      backgroundColor: theme.palette.secondary.light,
    },
  answers: {
    backgroundColor: 'aliceblue',
    fontSize: '0.8rem',
    width: '100%'
  },
    inputMultiline: {
      backgroundColor: 'lightcyan',
      padding: '10px',
      fontSize: '0.9em',
      border: '1px solid gray',
    },
  checkbox: {
    fontSize: '0.8rem',
  },
  options: {
    marginLeft: 'auto',

  },
  bottomContainer: {
    display: 'flex'
  }
}));
export const QuestionDetail = (props) => {

// const {deck} = props
const classes = useStyles()
const dispatch = useDispatch()
const [question, setQuestion] = useState(props.item)


const prepAnswers = (choices) => choices.map((ans, idx) => (
  {name: idx+1, label: ans, checked: false}))

useEffect(()=>{
  setQuestion(props.item);
}, [props.item])

const handleChange = (event) => {
  const {name, value } = event.target;
  setQuestion({...question, [name]: value})
  console.log('question after change', question)
}

const answerChange = (choice, checked) => {
  let answers = [...question.answer_choices]
  let index = answers.findIndex(ans => ans.name===choice)
  let newAnswer = answers[index]
  newAnswer['checked'] = checked
  answers.splice(index,1, newAnswer)
  setQuestion({...question, answer_choices: answers})

}
//
const deleteHandler = (event) =>{
dispatch(cardActions.deleteQuestion(props.deck.id, question))
props.deleteHandler(question, props.qIndex)
event.preventDefault();
}
//
// <Input className={classes.qText}  onChange={handleChange}
//   name="answer_choices" value={question.answer_choices}/>

const submitHandler = () => {
props.submitHandler(question)
}

            // <CheckBoxList items={question.answer_choices}
            //   formLabel="Select all that apply" onChange={answerChange}
            //   classes={classes.checkbox} name="answer_choices" />
  return (
    <form>
        <Card onBlur={submitHandler} className={classes.qCard}>
        <div className={classes.frontOuter}>
        <CardHeader className={classes.header} subheader="Front" />
          <div className={classes.options}>
            <Button onClick={deleteHandler}> <DeleteIcon/></Button>
          </div>
          </div>
                    <InputBase value={question.qtext}
              classes={{root: classes.front}}
              name="qtext"
              multiline
              rows={3}
                  onChange={handleChange}/>
          <Divider/>
             <CardHeader className={classes.backHeader} subheader="Back" />     
          <InputBase
            classes={{root: classes.back}}
            placeholder="Answer..."
            value={question.answer}
            name="answer"
            onChange={handleChange}/>


      </Card>
    </form>

  )
}


export const QuestionList = React.forwardRef((props, ref) => {
  const [questions, setQuestions] = useState([])
  const dispatch = useDispatch()
  const classes = useStyles()
  
  useEffect( () => {
    setQuestions( props.questions)
  }, [props.questions])
  
    const newQuestion = (text) => {
      let key = nanoid(5)
      let qnew = {key: key, qtext:text, answer:"", card:props.card.id}
      setQuestions(questions.concat(qnew))
      if (text.length>0) submitHandler(qnew)
    }
  
  
  React.useImperativeHandle(ref, ()=>({
  addFlash: (text) => {
    newQuestion(text)
  }
    }))
  if (!props.card) return null;


  const deleteHandler = (question, index) =>{
    if (question.id)  {
      dispatch(cardActions.deleteQuestion(props.deck.id, question))
    }
    else {
      // remove from questions
      let newQuestions = questions.slice()
      newQuestions.splice(index,1)
      setQuestions(newQuestions)
    }
  }
  const submitHandler = (question) => {
    if (question.id) dispatch(cardActions.updateQuestion(props.deck.id, question))
    if (!question.id) 
    {
      dispatch(cardActions.createQuestion(props.deck.id, question))
       let next_review = addToReviews([question], props.deck.next_review)
       dispatch(deckActions.updateDeck({...props.deck, next_review: next_review}))
    }
  }

  return (
<React.Fragment>

  {questions && questions.map( (value, i) => (
    <QuestionDetail item={value} deck={props.deck} qIndex={i}
    submitHandler={submitHandler} deleteHandler={deleteHandler}/>
  ))}
</React.Fragment>

  )

})
//<Button classes={{root: classes.addButton}}  onClick={newQuestion}> Gist <AddIcon/> </Button>
