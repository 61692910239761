import React from 'react';
import {Route, Link, useHistory, Redirect } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
    fade, makeStyles, withStyles,
    Hidden, Grid,
    AppBar, Toolbar,Badge, MenuItem, Menu, Chip,
    InputBase, Button, Modal, Typography, IconButton,
    MenuIcon, AddIcon, SearchIcon, AccountCircle, MailIcon,
    ViewStreamIcon, NotificationsIcon, MoreIcon, 
    } from 'assets/jss/material.js'


import {deckActions} from 'redux/actions';

import {hubListStyle} from './hubListStyle';

import HubDeckCard from './HubDeckCard';
import TopNav from 'components/Nav/TopNav';
import HubNav from './HubNav'
const useStyles =  makeStyles(hubListStyle);

const HubExplore = (props) => {

const history = useHistory();
const decks = useSelector(state => state.hubDecks)

const classes = useStyles()
//   let {decks} = props

  decks.sort((a, b) => (a.last_updated < b.last_updated) ? 1: -1 )
const clickHandler = (deckId) => {
    history.push(`deck-hub/${deckId}`)
}
return(
  <React.Fragment>
  <TopNav/>
  <HubNav/>
      <div className={classes.container}>
    { decks.map( (deck) => (
      <HubDeckCard deck={deck} clickHandler={clickHandler} />
     ))
   }
  </div>
  </React.Fragment>
)
  }

export default HubExplore;
